import * as actionTypes from "../actions/actionTypes";

const initialState = {
  Amenities: [],
  loading: true,
  AmenitiesCategories: [],
  InquiryForms: [],
  Logs: [],
  periods: [],
  pearlscount: "",
  totalcount: "",
  hotelscount: "",
  totalcountsSTropez: [],
  hotelsSTropez: [],
  dates: [],
  ourcountStropez: [],
  priceLogs: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LOGS_START: {
      return {
        ...state,
        loading: true
      };
    }
    case actionTypes.GET_PRICELOGS_START: {
      return {
        ...state,
        loading: true
      };
    }
    case actionTypes.GET_LOGS_SUCCESS: {
      let ourcount = [];
      let totalcounts = [];
      let datesformated = [];
      let hotels = [];
      let totalcountsST = [];
      let hotelsST = [];
      let ourcountSt = [];
      //console.log(action);
      let newarray = action.payload;
      newarray.map(item => {
        if (
          (ourcount.length < 7 || totalcounts.length < 7) &&
          item.checkInckeckOut === "01 Aug - 08 Aug"
        ) {
          //console.log(item);
          if (item.destination === "Mykonos") {
            ourcount.push(item.pearlsCount);
            hotels.push(item.hotelsCount);
            totalcounts.push(item.bookingDotComCount);
            datesformated.push(item.dateFormatted);
          } else if (item.destination === "St.Tropez") {
            ourcountSt.push(item.pearlsCount);
            hotelsST.push(item.hotelsCount);
            totalcountsST.push(item.bookingDotComCount);
          }
        }
      });
      //  let unique = [...new Set(state.periods)];

      return {
        ...state,
        Logs: action.payload,
        loading: false,
        pearlscount: ourcount,
        totalcount: totalcounts,
        dates: datesformated,
        hotelscount: hotels,
        ourcountStropez: ourcountSt,
        hotelsSTropez: hotelsST,
        totalcountsSTropez: totalcountsST
        // periods: unique
      };
    }
    case actionTypes.GET_PRICELOGS_SUCCESS: {
      return { ...state, loading: false, priceLogs: action.payload };
    }
    case actionTypes.GET_LOGS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case actionTypes.GET_PRICELOGS_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case actionTypes.GET_AMENITIES_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.ADD_AMENITY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_AMENITIES_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actionTypes.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case actionTypes.UPDATE_AMENITIES_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case actionTypes.UPDATE_AMENITIES_FAIL:
      return {
        ...state,
        loading: false
      };
    case actionTypes.GET_AMENITIES_SUCCESS:
      action.Amenities.map(item => {
        if (item.category !== undefined) {
          state.AmenitiesCategories.push(item.category);
        }
      });
      return {
        ...state,
        Amenities: action.Amenities,
        loading: false,
        AmenitiesCategories: Array.from(new Set(state.AmenitiesCategories))
      };
    case actionTypes.GET_AMENITIES_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };

    case actionTypes.GET_INCLUDEDINPRICE_SUCCESS:
      return {
        ...state,
        IncludedinPrice: action.IncludedinPrice,
        loading: false
      };

    case actionTypes.GET_INCLUCEDINPRICE_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case actionTypes.GET_INQUIRYFORMS_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_INQUIRYFORMS_SUCCESS:
      return {
        ...state,
        InquiryForms: action.payload,
        loading: false
      };
    case actionTypes.GET_INQUIRYFORMS_FAIL:
      return {
        ...state,
        loading: false
      };

    default:
      return {
        ...state
      };
  }
};

export default reducer;
