import React from "react";
import * as actions from "../../store/actions/index";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";

const ZonesDropDown = props => {
  const [zone, setZone] = React.useState(
    props.selected.address.zone
      ? props.selected.address.zone
      : { id: 999, name: "Select Zone", areaid: props.selectedArea }
  );
  const handleChangeZone = e => {
    setZone({
      id: e.target.value,
      name: e.target.name,
      areaid: props.selectedArea
    });
  };

  React.useEffect(() => {
    props.zone(zone);
  }, [zone]);

  let selectedZones = actions.getZonesByArea(props.selectedArea, props.zones);
  return (
    <div>
      {props.zones.length > 0 ? (
        <Grid item>
          <Select
            labelid="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={zone.id}
            onChange={e => handleChangeZone(e)}
          >
            <MenuItem key={zone.id} value={999} name={"Select Zone"}>
              Select zone
            </MenuItem>
            {selectedZones.map(area => (
              <MenuItem key={area.id} value={area.id} name={area.name}>
                {area.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    zones: state.area.zones,
    selectedArea: state.area.selected
  };
};

export default connect(mapStateToProps, null)(ZonesDropDown);
