import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import Switch from "@material-ui/core/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import * as actions from "../store/actions/index";
import { connect } from "react-redux";
import update from "immutability-helper";
import axios from "axios";
import { getEnvironment, headers } from "../config/config";

class EditUser extends Component {
  state = {
    userId: null,
    user: {},
    loading: true,
    userEdited: false,
    isRoleOpen: false
  };

  componentDidMount() {
    this.setState(
      {
        userId: this.props.history.location.state.userId
      },
      () =>
        this.props
          .getUser(this.state.userId)
          .then(user => this.setState({ user, loading: false }))
    );
  }

  handleChanges = e => {
    const value = e.target.value;
    this.setState(
      update(this.state, {
        user: {
          [e.target.name]: {
            $set: value
          }
        }
      })
    );
  };

  handleSwitch = e => {
    const value = e.target.checked;
    this.setState(prevState => ({
      ...prevState,
      user: {
        ...prevState.user,
        active: value
      }
    }));
  };

  editUser = () => {
    const { id, firstName, lastName, active, roles } = this.state.user;
    axios
      .put(
        getEnvironment().apiUrl + "/Account/Update",
        {
          id,
          firstName,
          lastName,
          active,
          roles
        },
        {
          headers: headers()
        }
      )
      .then(res => {
        this.setState({ userEdited: true });
        this.props
          .getUser(this.state.userId)
          .then(user => this.setState({ user }));
        this.props.getUsers();
      })
      .catch(error => console.log(error));
  };

  handleRoles = (e, index) => {
    const value = e.target.checked;
    const name = e.target.name;
    this.setState(
      update(this.state, {
        user: {
          roles: {
            [index]: {
              role: {
                $set: name
              },
              on: {
                $set: value
              }
            }
          }
        }
      })
    );
  };

  toggleRoleSelect = () => {
    this.setState({ isRoleOpen: !this.state.isRoleOpen });
  };

  render() {
    return this.state.loading ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress disableShrink />
      </div>
    ) : this.state.userEdited === false ? (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <TextField
            placeholder="Firstname"
            label="First Name"
            onChange={this.handleChanges}
            value={this.state.user.firstName}
            name={"firstName"}
            margin="normal"
            required
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
          />
          <TextField
            placeholder="Lastname"
            label="Last Name"
            onChange={this.handleChanges}
            value={this.state.user.lastName}
            name={"lastName"}
            margin="normal"
            required
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
          />
          <TextField
            placeholder="Email"
            label="Email"
            value={this.state.user.email}
            name={"email"}
            margin="normal"
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
            disabled
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "29%",
              marginBottom: 25
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>Role: </div>
            <FormControl style={{ minWidth: 120 }}>
              <InputLabel>Roles</InputLabel>
              <Select
                value=""
                open={this.state.isRoleOpen}
                onOpen={this.toggleRoleSelect}
                onClose={this.toggleRoleSelect}
              >
                {this.state.user.roles.map((user, index) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 10
                      }}
                    >
                      {user.role}
                    </div>
                    <MenuItem key={index} value={user.role}>
                      <Switch
                        checked={user.on}
                        color="primary"
                        name={user.role}
                        onChange={e => this.handleRoles(e, index)}
                      />
                    </MenuItem>
                  </div>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "29%"
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>Active:</div>
            <Switch
              checked={this.state.user.active}
              onChange={this.handleSwitch}
              name={"active"}
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={this.editUser}
            style={{ marginTop: 50 }}
          >
            Submit
          </Button>
        </div>
      </>
    ) : (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: 300 }}
      >
        <CheckIcon color="primary" />
        <div style={{ marginLeft: 10 }}>User edited successfully</div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUsers: () => dispatch(actions.getUsers()),
    getUser: userId => dispatch(actions.getUser(userId))
  };
};

export default connect(null, mapDispatchToProps)(EditUser);
