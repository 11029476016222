import React, { Component } from "react";
import Button from "@material-ui/core/Button";

class Archive extends Component {
  sendNewOffer = () => {
    this.props.unarchiveVilla();
  };

  render() {
    return (
      <>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={this.sendNewOffer}
          >
            Send New Offer
          </Button>
        </div>
      </>
    );
  }
}

export default Archive;
