import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import marker from "../../assets/img/marker.png";
import IconButton from "@material-ui/core/IconButton";
import { DATA } from "../../config/constants";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Checkbox from "@material-ui/core/Checkbox";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import StoreIcon from "@material-ui/icons/Store";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import HomeIcon from "@material-ui/icons/Home";
import PlaceIcon from "@material-ui/icons/Place";
const Marker = props => {
  const [open, setOpen] = useState(false);
  let IconName;
  if (props.icon === "beach_club") {
    IconName = BeachAccessIcon;
  } else if (props.icon === "restaurant") {
    IconName = RestaurantIcon;
  } else if (props.icon === "beach") {
    IconName = WbSunnyIcon;
  } else if (props.icon === "club") {
    IconName = LocalBarIcon;
  } else if (props.icon === "store") {
    IconName = StoreIcon;
  } else if (props.icon === "market") {
    IconName = LocalGroceryStoreIcon;
  } else if (props.icon === "place") {
    IconName = PlaceIcon;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "80px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {open ? <h6 style={{ fontWeight: "bold" }}>{props.text}</h6> : null}

      <IconName
        // src={marker}
        style={{
          borderRadius: "50%",
          position: "absolute",
          top: 0,
          left: 0,
          transform: "translate(-50%, -50%)"
        }}
        onClick={() => setOpen(prevState => !prevState)}
      />
    </div>
  );
};

const VillaMarker = props => {
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "80px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {open ? <p style={{ fontWeight: "bold" }}>{props.text}</p> : null}
      <HomeIcon
        style={{
          borderRadius: "50%",
          position: "absolute",
          top: 0,
          left: 0,
          transform: "translate(-50%, -50%)"
        }}
        onClick={() => setOpen(prevState => !prevState)}
      />
    </div>
    // <div>
    //   <img
    //     style={{
    //       borderRadius: "50%",
    //       position: "absolute",
    //       top: "50%",
    //       left: "50%",
    //       transform: "translate(-50%, -50%)"
    //     }}
    //     src={marker}
    //     alt="imgmarker"
    //   />
    // </div>
  );
};
let defaultProps = {
  center: {
    lat: 37.4339063,
    lng: 25.3183747
  },
  zoom: 13
};
const createMapOptions = () => {
  return {
    gestureHandling: "greedy"
  };
};

const VillasMap = props => {
  const { villas, centerCoords } = props;
  const [beach_clubOpen, setBeach_clubOpen] = useState(false);
  const [restaurantOpen, setRestaurantOpen] = useState(false);
  const [club, setClub] = useState(false);
  const [beach, setBeach] = useState(false);
  const [place, setPlace] = useState(false);
  const [store, setStore] = useState(false);
  const [market, setMarket] = useState(false);

  const [villasOpen, setVillasOpen] = useState(true);

  const [thisMap, setMap] = useState({
    map: "",
    maps: "",
    mapLoaded: false
  });

  const handleApiloaded = (map, maps) => {
    const CustomSatelliteButton = (controlDiv, map) => {
      let isSAtelite = false;

      var controlUI = document.createElement("div");
      controlUI.classList.add("satellite-map");
      controlUI.classList.add("fa");
      controlUI.classList.add("fa-map-o");
      controlDiv.appendChild(controlUI);
      // Setup the click event listeners: simply set the map to Satellite.
      controlUI.addEventListener("click", function() {
        if (!isSAtelite) {
          controlUI.classList.add("fa-globe");
          controlUI.classList.remove("fa-map-o");
          map.setMapTypeId("hybrid");
          isSAtelite = true;
        } else {
          controlUI.classList.add("fa-map-o");
          controlUI.classList.remove("fa-globe");
          isSAtelite = false;
          map.setMapTypeId("roadmap");
        }
      });
    };
    setMap({
      map: map,
      maps: maps,
      mapLoaded: true
    });
    if (map) {
      let SatelliteButtonDiv = document.createElement("div");
      CustomSatelliteButton(SatelliteButtonDiv, map);

      SatelliteButtonDiv.index = 1;
      map.controls[maps.ControlPosition.TOP_LEFT].push(SatelliteButtonDiv);
    }
  };

  useEffect(() => {
    // updates only if centerCoords changes
    if (thisMap.mapLoaded) {
      thisMap.map.setCenter(centerCoords);
    }
  }, [centerCoords]);

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "600px", width: "100%" }}>
      <GoogleMapReact
        onGoogleApiLoaded={({ map, maps }) => {
          handleApiloaded(map, maps);
        }}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: "AIzaSyCTyrsUuye8zfoBd9Z6qpfnFJmQFC1-U6Q" }}
        defaultCenter={centerCoords ? centerCoords : defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {villasOpen &&
          villas.map(villa => {
            if (villa.address)
              return (
                <VillaMarker
                  lat={villa.address.latlng.lat}
                  lng={villa.address.latlng.lng}
                  text={villa.nickname}
                />
              );
          })}
        {beach_clubOpen &&
          DATA.beach_club.map(villa => {
            return (
              <Marker
                lat={villa.lat}
                lng={villa.lng}
                text={villa.name}
                icon={"beach_club"}
              />
            );
          })}
        {restaurantOpen &&
          DATA.restaurant.map(villa => {
            return (
              <Marker
                lat={villa.lat}
                lng={villa.lng}
                text={villa.name}
                icon={"restaurant"}
              />
            );
          })}
        {beach &&
          DATA.beach.map(villa => {
            return (
              <Marker
                lat={villa.lat}
                lng={villa.lng}
                text={villa.name}
                icon={"beach"}
              />
            );
          })}
        {store &&
          DATA.store.map(villa => {
            return (
              <Marker
                lat={villa.lat}
                lng={villa.lng}
                text={villa.name}
                icon={"store"}
              />
            );
          })}
        {club &&
          DATA.club.map(villa => {
            return (
              <Marker
                lat={villa.lat}
                lng={villa.lng}
                text={villa.name}
                icon={"club"}
              />
            );
          })}

        {place &&
          DATA.place.map(villa => {
            return (
              <Marker
                lat={villa.lat}
                lng={villa.lng}
                text={villa.name}
                icon={"place"}
              />
            );
          })}

        {market &&
          DATA.market.map(villa => {
            return (
              <Marker
                lat={villa.lat}
                lng={villa.lng}
                text={villa.name}
                icon={"market"}
              />
            );
          })}
      </GoogleMapReact>
      <div style={{ position: "absolute", bottom: "6%", left: "3%" }}>
        <div
          style={{
            backgroundColor: "white"
          }}
        >
          <div
            style={{
              flexDirection: "row",
              display: "flex"
            }}
          >
            <Checkbox
              checked={restaurantOpen}
              onChange={() => setRestaurantOpen(prevState => !prevState)}
              color="default"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
            <p style={{ fontSize: "10px", paddingRight: "10px" }}>
              Restaurants
            </p>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex"
            }}
          >
            <Checkbox
              checked={beach_clubOpen}
              onChange={() => setBeach_clubOpen(prevState => !prevState)}
              color="default"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
            <p style={{ fontSize: "10px", paddingRight: "10px" }}>
              Beach Clubs
            </p>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex"
            }}
          >
            <Checkbox
              checked={beach}
              onChange={() => setBeach(prevState => !prevState)}
              color="default"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
            <p style={{ fontSize: "10px", paddingRight: "10px" }}>Beach </p>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex"
            }}
          >
            <Checkbox
              checked={store}
              onChange={() => setStore(prevState => !prevState)}
              color="default"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
            <p style={{ fontSize: "10px", paddingRight: "10px" }}>Store </p>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex"
            }}
          >
            <Checkbox
              checked={place}
              onChange={() => setPlace(prevState => !prevState)}
              color="default"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
            <p style={{ fontSize: "10px", paddingRight: "10px" }}>Place </p>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex"
            }}
          >
            <Checkbox
              checked={market}
              onChange={() => setMarket(prevState => !prevState)}
              color="default"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
            <p style={{ fontSize: "10px", paddingRight: "10px" }}>Market </p>
          </div>
          <div
            style={{
              flexDirection: "row",
              display: "flex"
            }}
          >
            <Checkbox
              checked={club}
              onChange={() => setClub(prevState => !prevState)}
              color="default"
              inputProps={{ "aria-label": "checkbox with default color" }}
            />
            <p style={{ fontSize: "10px", paddingRight: "10px" }}>Club </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VillasMap;
