import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import * as actions from "../store/actions/index";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import EnvRadios from "../components/UI/EnvRadios";
import { connect } from "react-redux";

let y = window.location.href.substring(8, 16);
const styles = theme => ({
  container: {
    padding: 30,
    borderRadius: 15
    // marginTop: 150
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 250,
    marginBottom: theme.spacing(2)
  },
  progress: {
    //  margin: theme.spacing(2),
    color: "white"
  },
  typography: {
    padding: theme.spacing(2),
    color: "red"
  },
  typography2: {
    padding: theme.spacing(2),
    fontSize: 30
    // marginBottom: 50
  }
});

class Login extends Component {
  state = {
    name: "",
    password: "",
    disableFields: false,
    envSelected: false
  };

  handleChange = name => event => {
    this.setState({ errors: false, [name]: event.target.value });
  };

  manageAccess = async () => {
    await this.props.authLogin(this.state.name, this.state.password);

    this.setState({ disableFields: false });
  };
  submit = () => {
    this.setState({ loading: true, disableFields: true }, () => {
      this.manageAccess();
    });
  };

  checkforenter = event => {
    if (event.key === "Enter") {
      this.submit();
    }
  };

  componentDidMount() {
    let userauth = localStorage.getItem("auth");
    if (userauth) {
      this.props.history.push("/admin");
    }
  }

  componentDidUpdate() {
    if (this.props.auth !== "") {
      window.location.reload();
    }
  }

  selectEnv = () => {
    this.setState({ envSelected: true });
  };

  render() {
    const { classes } = this.props;
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          height: "100vh",
          background:
            "url(https://images.unsplash.com/photo-1417632993443-302f4897cf67?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80)"
        }}
      >
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex"
          }}
        >
          <Card>
            <CardContent>
              <div className={classes.container} noValidate autoComplete="off">
                <Typography className={classes.typography2}>
                  Dashboard Login
                </Typography>
                <TextField
                  className={classes.textField}
                  id="standard-name"
                  label="Name"
                  value={this.state.name}
                  onChange={this.handleChange("name")}
                  margin="normal"
                  disabled={this.state.disableFields}
                />
                <div>
                  <TextField
                    className={classes.textField}
                    label="Password"
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                    margin="normal"
                    onKeyPress={this.checkforenter}
                    type="password"
                    disabled={this.state.disableFields}
                  />
                </div>
                {this.props.loading ? (
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "100%" }}
                  >
                    <CircularProgress className={classes.progress} size={24} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.submit}
                    style={{ width: "100%" }}
                    disabled={
                      y === "admindev" || y === "ocalhost"
                        ? !this.state.envSelected
                        : false
                    }
                  >
                    Login
                  </Button>
                )}
                {this.props.error ? (
                  <Typography
                    style={{
                      justifySelf: "center",
                      alignSelf: "center",
                      paddingTop: 15,
                      color: "red"
                    }}
                  >
                    {this.props.error}
                  </Typography>
                ) : null}
              </div>
              {y === "admindev" || y === "ocalhost" ? (
                <EnvRadios selectEnv={this.selectEnv} />
              ) : null}
              <div
                style={{
                  cursor: "pointer",
                  color: "blue",
                  display: "flex",
                  justifyContent: "flex-end"
                }}
                onClick={() =>
                  this.props.history.push("/forgot-password", {
                    env: "dev"
                  })
                }
              >
                Forgot password
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth.auth,
    loading: state.auth.loading,
    error: state.auth.error ? state.auth.error.title : state.auth.error
  };
};

const mapDispatchToStore = dispatch => {
  return {
    authLogin: (name, password) => dispatch(actions.authLogin(name, password))
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToStore)(Login)
);
