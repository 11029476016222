import * as actionTypes from "./actionTypes";
import axios from "axios";
import { headers } from "../../config/config";
import { getEnvironment } from "../../config/config";

export const getpoisStart = () => {
  return {
    type: actionTypes.GET_POIS_START
  };
};

export const getpoisSuccess = pois => {
  return {
    type: actionTypes.GET_POIS_SUCCESS,
    pois
  };
};

export const getpoitypesSuccess = types => {
  return {
    type: actionTypes.GET_POISTYPES_SUCCESS,
    types
  };
};
export const getpoisFail = error => {
  return {
    type: actionTypes.GET_POIS_FAIL,
    error
  };
};
export const getpoitypeFail = error => {
  return {
    type: actionTypes.GET_POISTYPES_FAIL,
    error
  };
};

export const getPoiTypes = () => {
  return dispatch => {
    axios
      .get(getEnvironment().apiUrl + `/Settings/KnownLocationTypes`, {
        headers: headers()
      })
      .then(response => {
        dispatch(getpoitypesSuccess(response.data));
      })
      .catch(error => {
        dispatch(getpoitypeFail(error.response));
      });
  };
};

export const getPois = areaid => {
  return dispatch => {
    dispatch(getpoisStart());
    axios
      .get(
        getEnvironment().apiUrl +
          `/Settings/KnownLocationsByAreaId/Area/${areaid}`,
        {
          headers: headers()
        }
      )
      .then(response => {
        dispatch(getpoisSuccess(response.data));
      })
      .catch(error => {
        dispatch(getpoisFail(error.response));
      });
  };
};

export const addPoi = (name, type, id, lat, lng, poiID) => {
  return async dispatch => {
    try {
      const res = await axios.post(
        getEnvironment().apiUrl + `/Settings/KnownLocationsAddUpdate`,
        {
          id: poiID ? poiID : null,
          attractionType: type,
          name: name,
          address: {
            areaId: id,
            latlng: {
              lat: lat,
              lng: lng
            }
          }
        },
        {
          headers: headers()
        }
      );
      return res.data;
    } catch (error) {
      dispatch(getpoisFail(error.response));
      return error;
    }
  };
};
