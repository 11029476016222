import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import GuestyIcon from "../assets/img/guesty.png";
import ThePearlsIcon from "../assets/img/thepearls_aa.png";
import update from "immutability-helper";
import axios from "axios";
import { getEnvironment, headers } from "../config/config";
import Thumbnails from "./ImageThumbnails";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import * as actions from "../store/actions/index";
import { connect } from "react-redux";

let multimedia = [];
let profMultimedia = [];

class Multimedia extends Component {
  state = {
    form: {},
    loading: true,
    villaId: null,
    fileSelected: false,
    fileName: "",
    fileType: null,
    fileExtension: null,
    image: null,
    imageScope: "",
    requiresPhotoshooting: false,
    isOpen: false,
    ref: React.createRef()
  };

  componentDidMount() {
    let newform = this.props.form;
    newform.id = this.props.villaId;
    if (newform.onBoardingExtraData) {
      if (newform.onBoardingExtraData.multimedia !== null) {
        multimedia = newform.onBoardingExtraData.multimedia;
      }
    }
    if (newform.pictures.length > 0) {
      profMultimedia = newform.pictures;
    }
    this.setState({
      form: newform,
      loading: false,
      villaId: this.props.villaId
    });
  }

  componentWillUnmount() {
    this.setState({ form: {} });
  }

  handleMultimedia = path => {
    let innerMultimedia = {
      noteType: this.state.fileType,
      noteText: this.state.fileName,
      contentUrl: path
    };
    multimedia.push(innerMultimedia);
    this.setState(
      update(this.state, {
        form: {
          onBoardingExtraData: {
            multimedia: {
              $set: multimedia
            }
          }
        }
      }),
      () => this.props.addForm(this.state.form)
    );
  };

  handleProfMultimedia = path => {
    let innerProfMultimedia = {
      large: path,
      selectedForPms: false,
      selectedForSite: false
    };
    profMultimedia.push(innerProfMultimedia);
    this.setState(
      update(this.state, {
        form: {
          pictures: {
            $set: profMultimedia
          }
        }
      }),
      () => {
        this.uploadProfImages(path);
      }
    );
  };

  uploadProfImages = path => {
    axios
      .put(
        getEnvironment().apiUrl +
          `/Property/AddPropertyImage/${this.state.villaId}`,
        {
          large: path,
          selectedForPms: false,
          selectedForSite: false
        },
        {
          headers: headers()
        }
      )
      .then(() => {
        this.setState(
          update(this.state, {
            form: {
              pictures: {
                $set: profMultimedia
              }
            }
          }),
          () => this.props.addForm(this.state.form)
        );
      })
      .catch(error => console.log(error));
  };

  fileChangedHandler = (e, scope) => {
    this.setState({ imageScope: scope });
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file.type.includes("image")) {
      this.setState({ fileType: "image" });
    } else if (file.type.includes("application")) {
      if (file.type.includes("pdf")) {
        this.setState({ fileType: "document" });
      } else {
        this.setState({ fileType: "binary" });
      }
    } else if (file.type.includes("video")) {
      this.setState({ fileType: "video" });
    } else if (file.type.includes("audio")) {
      this.setState({ fileType: "audio" });
    } else if (file.type.includes("text")) {
      this.setState({ fileType: "text" });
    } else if (file.type === "") {
      this.setState({ fileType: "document" });
    }
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        file,
        ImagePreviewUrl: reader.result,
        fileSelected: true
      });
    };
  };

  handleUploadButton = scope => {
    if (this.state.fileName === "" && scope !== "pro") {
      this.props.openAlert("", "Please type a name for the file");
    } else {
      this.handleUpload(this.state.file, scope);
    }
  };

  handleUpload = (data, scope) => {
    this.setState({ loading: true });
    let url;
    if (this.state.fileType !== "image") {
      url = "/Web/UploadPrivateFile";
    } else {
      url = "/Web/UploadFile";
    }
    let formData = new FormData();
    formData.append("formFile", data);
    const config = {
      headers: headers()
    };
    axios
      .post(getEnvironment().apiUrl + url, formData, config)
      .then(response => {
        if (scope === "non-pro") {
          this.handleMultimedia(response.data);
        } else {
          this.handleProfMultimedia(response.data);
        }
        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.openAlert("", error);
      });
  };

  findFileType = path => {
    let fp = path.split("");
    let fp2 = [];
    for (let i = 36; i < fp.length; i++) {
      fp2.push(fp[i]);
    }
    let fileType = fp2.join("");
    this.setState({ fileExtension: fileType });
  };

  downloadFile = path => {
    this.findFileType(path);
    axios
      .get(getEnvironment().apiUrl + `/Web/DownloadFile/${path}`, {
        headers: headers(),
        responseType: "arraybuffer"
      })
      .then(response => {
        let blob = new Blob([response.data], {
          "content-type": "application/pdf"
        });
        let ref = this.state.ref;
        ref.current.href = URL.createObjectURL(blob);
        ref.current.download = "file" + this.state.fileExtension;
        ref.current.click();
      })
      .catch(error => console.log(error));
  };

  deleteFile = file => {
    multimedia = multimedia.filter(item => item !== file);
    this.setState(
      update(this.state, {
        form: {
          onBoardingExtraData: {
            multimedia: {
              $set: multimedia
            }
          }
        }
      }),
      () => this.props.addForm(this.state.form)
    );
  };

  deleteProfImages = file => {
    profMultimedia = profMultimedia.filter(item => item !== file);
    this.setState(
      update(this.state, {
        form: {
          pictures: {
            $set: profMultimedia
          }
        }
      })
    );
    axios
      .delete(
        getEnvironment().apiUrl +
          `/Property/DeletePropertyImage/${this.state.villaId}/${file.id}`,
        {
          headers: headers()
        }
      )
      .then(res => console.log(res.data))
      .catch(error => console.log(error));
  };

  handleFileName = e => {
    this.setState({ fileName: e.target.value });
  };

  handleSwitchChange = e => {
    const value = e.target.checked;
    this.setState(
      update(this.state, {
        form: {
          requiresPhotoShooting: {
            $set: value
          }
        },
        isOpen: {
          $set: value
        }
      }),
      () => this.props.addForm(this.state.form)
    );
  };

  selectDate = e => {
    const value = moment(e).format();
    this.setState(
      update(this.state, {
        form: {
          photoShootingDateTime: {
            $set: value
          }
        }
      }),
      () => this.props.addForm(this.state.form)
    );
  };

  checkForPms = (image, index) => {
    let value = image.selectedForPms;
    this.setState(
      update(this.state, {
        form: {
          pictures: {
            [index]: {
              selectedForPms: {
                $set: !value
              }
            }
          }
        }
      }),
      () => this.props.addForm(this.state.form)
    );
  };

  checkForSite = (image, index) => {
    let value = image.selectedForSite;
    this.setState(
      update(this.state, {
        form: {
          pictures: {
            [index]: {
              selectedForSite: {
                $set: !value
              }
            }
          }
        }
      }),
      () => this.props.addForm(this.state.form)
    );
  };

  render() {
    return !this.state.loading ? (
      <>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            Requires Photo Shooting:
          </div>
          <Switch
            checked={this.state.form.requiresPhotoShooting}
            onChange={this.handleSwitchChange}
            // value="checkedB"
            name={"requiresPhotoshooting"}
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              autoOk={true}
              label="Photo Shooting Date"
              value={
                this.state.form.photoShootingDateTime
                  ? moment(this.state.form.photoShootingDateTime)
                  : null
              }
              onChange={this.selectDate}
              style={{
                display:
                  this.state.isOpen || this.state.form.requiresPhotoShooting
                    ? "block"
                    : "none"
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <div style={{ marginTop: 20 }}>On Boarding photos</div>
          <form
            onSubmit={() => this.handleUploadButton("non-pro")}
            style={{ display: "flex" }}
          >
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <input
                type="file"
                name="file"
                onChange={e => this.fileChangedHandler(e, "non-pro")}
              />
              <TextField
                label="Enter file name"
                margin="none"
                onChange={this.handleFileName}
              />
            </div>
            <Button
              style={{
                display:
                  this.state.fileSelected && this.state.imageScope === "non-pro"
                    ? "block"
                    : "none",
                alignSelf: "flex-end",
                padding: "0 20px"
              }}
              onClick={() => this.handleUploadButton("non-pro")}
            >
              Upload File
            </Button>
          </form>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {multimedia.length > 0
            ? multimedia.map(item => (
                <div key={item.id}>
                  <Card
                    style={{
                      width: "100px",
                      height: "140px",
                      display: "flex",
                      flexDirection: "column",
                      margin: "20px 20px 0 10px"
                    }}
                  >
                    <CardActionArea
                      style={{ height: "80%", textAlign: "center" }}
                    >
                      {item.noteType === "image" ? (
                        <Thumbnails data={item.contentUrl} key={item.id} />
                      ) : (
                        <span>{item.noteText}</span>
                      )}
                    </CardActionArea>
                    <CardActions
                      style={{
                        height: "20%",
                        padding: "0px",
                        justifyContent: "center"
                      }}
                    >
                      <IconButton onClick={() => this.deleteFile(item)}>
                        <DeleteIcon />
                      </IconButton>
                      {item.noteType !== "image" ? (
                        <div>
                          <a
                            onClick={() => console.log("anchor clicked")}
                            style={{ display: "none" }}
                            href="empty"
                            ref={this.state.ref}
                          >
                            ref
                          </a>
                          <IconButton
                            onClick={() => this.downloadFile(item.contentUrl)}
                          >
                            <CloudDownloadOutlinedIcon color="primary" />
                          </IconButton>
                        </div>
                      ) : null}
                    </CardActions>
                  </Card>
                </div>
              ))
            : null}
        </div>
        <div
          style={{ width: "100%", border: "1px solid black", marginTop: 50 }}
        ></div>
        {!this.props.addVilla ? (
          <div>
            <div style={{ margin: "20px 0px" }}>Professional photos</div>
            <form
              onSubmit={() => this.handleUploadButton("pro")}
              style={{ display: "flex" }}
            >
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <input
                  type="file"
                  name="file"
                  onChange={e => this.fileChangedHandler(e, "pro")}
                />
              </div>
              <Button
                style={{
                  display:
                    this.state.fileSelected && this.state.imageScope === "pro"
                      ? "block"
                      : "none",
                  alignSelf: "flex-end",
                  padding: "0 20px"
                }}
                onClick={() => this.handleUploadButton("pro")}
              >
                Upload File
              </Button>
            </form>
          </div>
        ) : null}
        {!this.props.addVilla ? (
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {this.state.form.pictures
              ? this.state.form.pictures.map((item, index) => (
                  <div key={item.id}>
                    <Card
                      style={{
                        width: "250px",
                        height: "300px",
                        display: "flex",
                        flexDirection: "column",
                        margin: "20px 20px 0 10px"
                      }}
                    >
                      <CardActionArea
                        style={{ height: "80%", textAlign: "center" }}
                      >
                        <Thumbnails data={item.large} key={item.id} />
                      </CardActionArea>
                      <CardActions
                        style={{
                          height: "20%",
                          padding: "0px",
                          justifyContent: "space-evenly"
                        }}
                      >
                        <IconButton
                          style={
                            item.selectedForSite
                              ? {
                                  backgroundColor: "lightblue",
                                  marginBottom: 10
                                }
                              : null
                          }
                          onClick={() => this.checkForSite(item, index)}
                        >
                          <img src={ThePearlsIcon} alt="thepearls" />
                        </IconButton>
                        {/* <IconButton onClick={() => this.deleteProfImages(item)}>
                        <DeleteIcon />
                      </IconButton> */}
                        <IconButton
                          style={
                            item.selectedForPms
                              ? {
                                  backgroundColor: "lightblue",
                                  marginBottom: 10
                                }
                              : null
                          }
                          onClick={() => this.checkForPms(item, index)}
                        >
                          <img src={GuestyIcon} alt="guesty" />
                        </IconButton>
                      </CardActions>
                    </Card>
                  </div>
                ))
              : null}
          </div>
        ) : null}
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 150
        }}
      >
        <CircularProgress disableShrink />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToStore = dispatch => {
  return {
    openAlert: (title, text) => dispatch(actions.openAlert(title, text))
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(Multimedia);
