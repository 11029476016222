import React, { Component } from "react";
import Scheduler, { SchedulerData, ViewTypes } from "react-big-scheduler";
import "react-big-scheduler/lib/css/style.css";
import withDragDropContext from "./withDnDContext";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";

import moment from "moment";

const DemoData = {
  resources: [
    {
      id: "r4",
      name: "Resource4"
    },
    {
      id: "r5",
      name: "Resource5"
    },
    {
      id: "r6",
      name: "Resource6"
    }
  ],
  events: [
    {
      id: 1,
      start: "2017-12-18 09:30:00",
      end: "2017-12-19 23:30:00",
      resourceId: "r4",
      title: "I am finished",
      bgColor: "#D9D9D9",
      showPopover: false
    },
    {
      id: 2,
      start: "2017-12-18 12:30:00",
      end: "2017-12-26 23:30:00",
      resourceId: "r2",
      title: "I am not resizable",
      resizable: false
    },
    {
      id: 3,
      start: "2017-12-19 12:30:00",
      end: "2017-12-20 23:30:00",
      resourceId: "r3",
      title: "I am not movable",
      movable: false
    },
    {
      id: 4,
      start: "2017-12-19 14:30:00",
      end: "2017-12-20 23:30:00",
      resourceId: "r4",
      title: "I am not start-resizable",
      startResizable: false
    },
    {
      id: 5,
      start: "2017-12-19 15:30:00",
      end: "2017-12-20 23:30:00",
      resourceId: "r5",
      title: "I am not end-resizable",
      endResizable: false
    },
    {
      id: 6,
      start: "2017-12-19 15:35:00",
      end: "2017-12-19 23:30:00",
      resourceId: "r6",
      title: "I am normal"
    },
    {
      id: 7,
      start: "2017-12-19 15:40:00",
      end: "2017-12-20 23:30:00",
      resourceId: "r7",
      title: "I am exceptional",
      bgColor: "#FA9E95"
    },
    {
      id: 8,
      start: "2017-12-19 15:50:00",
      end: "2017-12-19 23:30:00",
      resourceId: "r1",
      title: "I am locked",
      movable: false,
      resizable: false,
      bgColor: "red"
    },
    {
      id: 9,
      start: "2017-12-19 16:30:00",
      end: "2017-12-27 23:30:00",
      resourceId: "r1",
      title: "R1 has many tasks 1"
    },
    {
      id: 10,
      start: "2017-12-19 17:30:00",
      end: "2017-12-19 23:30:00",
      resourceId: "r1",
      title: "R1 has recurring tasks every week on Tuesday, Friday",
      rrule: "FREQ=WEEKLY;DTSTART=20171219T013000Z;BYDAY=TU,FR",
      bgColor: "#f759ab"
    },
    {
      id: 11,
      start: "2017-12-19 18:30:00",
      end: "2017-12-20 23:30:00",
      resourceId: "r1",
      title: "R1 has many tasks 3"
    },
    {
      id: 12,
      start: "2017-12-20 18:30:00",
      end: "2017-12-20 23:30:00",
      resourceId: "r1",
      title: "R1 has many tasks 4"
    },
    {
      id: 13,
      start: "2017-12-21 18:30:00",
      end: "2017-12-24 23:30:00",
      resourceId: "r1",
      title: "R1 has many tasks 5"
    },
    {
      id: 14,
      start: "2017-12-23 18:30:00",
      end: "2017-12-27 23:30:00",
      resourceId: "r1",
      title: "R1 has many tasks 6"
    }
  ]
  // eventsForTaskView: [
  //   {
  //     id: 1,
  //     start: "2017-12-18 09:30:00",
  //     end: "2017-12-18 23:30:00",
  //     resourceId: "r1",
  //     title: "I am finished",
  //     bgColor: "#D9D9D9",
  //     groupId: 1,
  //     groupName: "Task1"
  //   },
  //   {
  //     id: 2,
  //     start: "2017-12-18 12:30:00",
  //     end: "2017-12-26 23:30:00",
  //     resourceId: "r2",
  //     title: "I am not resizable",
  //     resizable: false,
  //     groupId: 2,
  //     groupName: "Task2"
  //   },
  //   {
  //     id: 3,
  //     start: "2017-12-19 12:30:00",
  //     end: "2017-12-20 23:30:00",
  //     resourceId: "r3",
  //     title: "I am not movable",
  //     movable: false,
  //     groupId: 3,
  //     groupName: "Task3"
  //   },
  //   {
  //     id: 7,
  //     start: "2017-12-19 15:40:00",
  //     end: "2017-12-20 23:30:00",
  //     resourceId: "r7",
  //     title: "I am exceptional",
  //     bgColor: "#FA9E95",
  //     groupId: 4,
  //     groupName: "Task4"
  //   },
  //   {
  //     id: 4,
  //     start: "2017-12-20 14:30:00",
  //     end: "2017-12-21 23:30:00",
  //     resourceId: "r4",
  //     title: "I am not start-resizable",
  //     startResizable: false,
  //     groupId: 1,
  //     groupName: "Task1"
  //   },
  //   {
  //     id: 5,
  //     start: "2017-12-21 15:30:00",
  //     end: "2017-12-22 23:30:00",
  //     resourceId: "r5",
  //     title: "I am not end-resizable",
  //     endResizable: false,
  //     groupId: 3,
  //     groupName: "Task3"
  //   },
  //   {
  //     id: 9,
  //     start: "2017-12-21 16:30:00",
  //     end: "2017-12-21 23:30:00",
  //     resourceId: "r1",
  //     title: "R1 has many tasks",
  //     groupId: 4,
  //     groupName: "Task4"
  //   },
  //   {
  //     id: 6,
  //     start: "2017-12-22 15:35:00",
  //     end: "2017-12-23 23:30:00",
  //     resourceId: "r6",
  //     title: "I am normal",
  //     groupId: 1,
  //     groupName: "Task1"
  //   },
  //   {
  //     id: 8,
  //     start: "2017-12-25 15:50:00",
  //     end: "2017-12-26 23:30:00",
  //     resourceId: "r1",
  //     title: "I am locked",
  //     movable: false,
  //     resizable: false,
  //     bgColor: "red",
  //     groupId: 1,
  //     groupName: "Task1"
  //   },
  //   {
  //     id: 10,
  //     start: "2017-12-26 18:30:00",
  //     end: "2017-12-26 23:30:00",
  //     resourceId: "r2",
  //     title: "R2 has many tasks",
  //     groupId: 4,
  //     groupName: "Task4"
  //   },
  //   {
  //     id: 11,
  //     start: "2017-12-27 18:30:00",
  //     end: "2017-12-27 23:30:00",
  //     resourceId: "r14",
  //     title: "R4 has many tasks",
  //     groupId: 4,
  //     groupName: "Task4"
  //   },
  //   {
  //     id: 12,
  //     start: "2017-12-28 18:30:00",
  //     end: "2017-12-28 23:30:00",
  //     resourceId: "r6",
  //     title: "R6 has many tasks",
  //     groupId: 3,
  //     groupName: "Task3"
  //   }
  // ]
  // eventsForCustomEventStyle: [
  //   {
  //     id: 1,
  //     start: "2017-12-18 09:30:00",
  //     end: "2017-12-19 23:30:00",
  //     resourceId: "r1",
  //     title: "I am finished",
  //     bgColor: "#D9D9D9",
  //     type: 1
  //   },
  //   {
  //     id: 2,
  //     start: "2017-12-18 12:30:00",
  //     end: "2017-12-26 23:30:00",
  //     resourceId: "r2",
  //     title: "I am not resizable",
  //     resizable: false,
  //     type: 2
  //   },
  //   {
  //     id: 3,
  //     start: "2017-12-19 12:30:00",
  //     end: "2017-12-20 23:30:00",
  //     resourceId: "r3",
  //     title: "I am not movable",
  //     movable: false,
  //     type: 3
  //   },
  //   {
  //     id: 4,
  //     start: "2017-12-19 14:30:00",
  //     end: "2017-12-20 23:30:00",
  //     resourceId: "r4",
  //     title: "I am not start-resizable",
  //     startResizable: false,
  //     type: 1
  //   },
  //   {
  //     id: 5,
  //     start: "2017-12-19 15:30:00",
  //     end: "2017-12-20 23:30:00",
  //     resourceId: "r5",
  //     title: "I am not end-resizable",
  //     endResizable: false,
  //     type: 2
  //   },
  //   {
  //     id: 6,
  //     start: "2017-12-19 15:35:00",
  //     end: "2017-12-19 23:30:00",
  //     resourceId: "r6",
  //     title: "I am normal",
  //     type: 3
  //   },
  //   {
  //     id: 7,
  //     start: "2017-12-19 15:40:00",
  //     end: "2017-12-20 23:30:00",
  //     resourceId: "r7",
  //     title: "I am exceptional",
  //     bgColor: "#FA9E95",
  //     type: 1
  //   },
  //   {
  //     id: 8,
  //     start: "2017-12-19 15:50:00",
  //     end: "2017-12-19 23:30:00",
  //     resourceId: "r1",
  //     title: "I am locked",
  //     movable: false,
  //     resizable: false,
  //     bgColor: "red",
  //     type: 2
  //   },
  //   {
  //     id: 9,
  //     start: "2017-12-19 16:30:00",
  //     end: "2017-12-27 23:30:00",
  //     resourceId: "r1",
  //     title: "R1 has many tasks 1",
  //     type: 3
  //   },
  //   {
  //     id: 10,
  //     start: "2017-12-20 18:30:00",
  //     end: "2017-12-20 23:30:00",
  //     resourceId: "r1",
  //     title: "R1 has many tasks 2",
  //     type: 1
  //   },
  //   {
  //     id: 11,
  //     start: "2017-12-21 18:30:00",
  //     end: "2017-12-22 23:30:00",
  //     resourceId: "r1",
  //     title: "R1 has many tasks 3",
  //     type: 2
  //   },
  //   {
  //     id: 12,
  //     start: "2017-12-23 18:30:00",
  //     end: "2017-12-27 23:30:00",
  //     resourceId: "r1",
  //     title: "R1 has many tasks 4",
  //     type: 3
  //   }
  // ]
};

let date = moment().format("YYYY-MM-D");
class Basic extends Component {
  constructor(props) {
    super(props);

    //let schedulerData = new SchedulerData(new moment("2017-12-18").format(DATE_FORMAT), ViewTypes.Week);

    let schedulerData = new SchedulerData(date, ViewTypes.Week);
    schedulerData.localeMoment.locale("en");
    schedulerData.setResources(DemoData.resources);
    schedulerData.setEvents(DemoData.events);
    this.state = {
      viewModel: schedulerData,
      dataSet: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.eventsLoaded) {
      if (this.props.calendarEvents !== prevProps.calendarEvents) {
        // console.log("DATA", this.props.data);
        this.setState(
          {
            dataSet: false
          },
          () => {
            this.setData(this.props.villasInPage, this.props.calendarEvents);
          }
        );
      }
    }
  }
  setData = (data, events) => {
    let dataList = [];

    data.map(d => {
      return dataList.push({ id: d.id, name: d.nickname });
    });
    let EventList = [];

    events.map((event, i) => {
      let pluseven = moment(event.date).add(1, "d");

      // console.log(event);
      EventList.push({
        id: i,
        start: moment(event.date).format("YYYY-MM-D"),
        end: moment(pluseven).format("YYYY-MM-D"),
        resourceId: event.propertyId,
        title: event.note,
        bgColor:
          event.calendarEventType === "internalevent" ? "#009688" : "#f03776"
      });

      // id: 1,
      // start: "2017-12-18 09:30:00",
      // end: "2017-12-19 23:30:00",
      // resourceId: "r4",
      // title: "I am finished",
      // bgColor: "#D9D9D9",
      // showPopover: false
    });

    let schedulerData = new SchedulerData(date, ViewTypes.Week);

    DemoData.resources = dataList;
    DemoData.events = EventList;
    schedulerData.setResources(DemoData.resources);
    schedulerData.setEvents(DemoData.events);

    this.setState(
      {
        viewModel: schedulerData,
        dataSet: true
      },
      () => {}
    );
    // return list;
  };

  render() {
    const { viewModel } = this.state;
    return (
      <div>
        <div>
          {!this.props.eventsLoaded ? (
            "Loading Data ...."
          ) : (
            <>
              <h4 style={{ textAlign: "center" }}>Scheduler Events</h4>
              {this.state.dataSet && (
                <div className={"Scheduler-container"}>
                  <Scheduler
                    schedulerData={viewModel}
                    prevClick={this.prevClick}
                    nextClick={this.nextClick}
                    onSelectDate={this.onSelectDate}
                    onViewChange={this.onViewChange}
                    // eventItemClick={this.eventClicked}
                    // viewEventClick={this.ops1}
                    viewEventText="Ops 1"
                    viewEvent2Text="Ops 2"
                    // viewEvent2Click={this.ops2}
                    updateEventStart={this.updateEventStart}
                    updateEventEnd={this.updateEventEnd}
                    // moveEvent={this.moveEvent}
                    // newEvent={this.newEvent}
                    onScrollLeft={this.onScrollLeft}
                    onScrollRight={this.onScrollRight}
                    onScrollTop={this.onScrollTop}
                    onScrollBottom={this.onScrollBottom}
                    toggleExpandFunc={this.toggleExpandFunc}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }

  prevClick = schedulerData => {
    schedulerData.prev();
    schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData
    });
  };

  nextClick = schedulerData => {
    schedulerData.next();
    schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData
    });
  };

  onViewChange = (schedulerData, view) => {
    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData
    });
  };

  onSelectDate = (schedulerData, date) => {
    alert(date, schedulerData);
    schedulerData.setDate(date);
    schedulerData.setEvents(DemoData.events);
    this.setState({
      viewModel: schedulerData
    });
  };

  eventClicked = (schedulerData, event) => {
    alert(
      `You just clicked an event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  ops1 = (schedulerData, event) => {
    alert(
      `You just executed ops1 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  ops2 = (schedulerData, event) => {
    alert(
      `You just executed ops2 to event: {id: ${event.id}, title: ${event.title}}`
    );
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    if (
      window.confirm(
        `Do you want to create a new event? {slotId: ${slotId}, slotName: ${slotName}, start: ${start}, end: ${end}, type: ${type}, item: ${item}}`
      )
    ) {
      let newFreshId = 0;
      schedulerData.events.forEach(item => {
        if (item.id >= newFreshId) newFreshId = item.id + 1;
      });
      let newEvent = {
        id: newFreshId,
        title: "New event you just created",
        start: start,
        end: end,
        resourceId: slotId,
        bgColor: "purple"
      };
      schedulerData.addEvent(newEvent);
      this.setState({
        viewModel: schedulerData
      });
    }
  };

  updateEventStart = (schedulerData, event, newStart) => {
    if (
      window.confirm(
        `Do you want to adjust the start of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newStart: ${newStart}}`
      )
    ) {
      schedulerData.updateEventStart(event, newStart);
    }
    this.setState({
      viewModel: schedulerData
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    if (
      window.confirm(
        `Do you want to adjust the end of the event? {eventId: ${event.id}, eventTitle: ${event.title}, newEnd: ${newEnd}}`
      )
    ) {
      schedulerData.updateEventEnd(event, newEnd);
    }
    this.setState({
      viewModel: schedulerData
    });
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    if (
      window.confirm(
        `Do you want to move the event? {eventId: ${event.id}, eventTitle: ${event.title}, newSlotId: ${slotId}, newSlotName: ${slotName}, newStart: ${start}, newEnd: ${end}`
      )
    ) {
      schedulerData.moveEvent(event, slotId, slotName, start, end);
      this.setState({
        viewModel: schedulerData
      });
    }
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(DemoData.events);
      this.setState({
        viewModel: schedulerData
      });

      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(DemoData.events);
      this.setState({
        viewModel: schedulerData
      });

      schedulerContent.scrollLeft = 10;
    }
  };

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    // console.log("onScrollTop");
  };

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    // console.log("onScrollBottom");
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData
    });
  };
}

const mapStateToProps = state => {
  return {
    villasInPage: state.villas.vilasInPage,
    calendarEvents: state.villas.calendarEvents,
    eventsLoaded: state.villas.eventsLoaded
  };
};

const mapDispatchToStore = dispatch => {
  return {
    getVillasEvents: (area, from, to, event, villas) =>
      dispatch(actions.getVillasEvents(area, from, to, event, villas))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToStore
)(withDragDropContext(Basic));
