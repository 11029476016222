export const headers = () => {
  let token = localStorage.getItem("auth");
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };
};

const dev = {
  apiUrl: "https://backenddev.thepearls.com/api",
  authUrl: "https://backenddev.thepearls.com",
  signalUrl: "",
  apikey: "AIzaSyDFK3KXhb-VNCoymIMkyfwWKlsrcARvCHo",
  firebaseurl: ""
};

const prod = {
  apiUrl: "https://api.thepearls.com/api",
  authUrl: "https://api.thepearls.com",
  signalUrl: "",
  apikey: "AIzaSyDFK3KXhb-VNCoymIMkyfwWKlsrcARvCHo",
  firebaseurl: ""
};

export const getEnvironment = () => {
  let env = localStorage.getItem("environment");

  let y = window.location.href.substring(8, 16);
  if (y === "admindev" || y === "ocalhost") {
    //let env = "prod";
    switch (env) {
      case "dev":
        return dev;
      case "prod":
        return prod;
      default:
        break;
    }
  } else {
    return prod;
  }
};
