import * as actionTypes from "../actions/actionTypes";

const initialState = {
  pois: [],
  types: [],
  loading: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_POIS_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_POIS_SUCCESS:
      return {
        ...state,
        pois: action.pois,
        loading: false
      };
    case actionTypes.GET_POIS_FAIL:
      return {
        ...state,
        error: "Error",
        loading: false
      };
    case actionTypes.GET_POISTYPES_SUCCESS:
      let typeArray = action.types;
      typeArray.sort(function(a, b) {
        if (a.category < b.category) {
          return -1;
        }
        if (a.category > b.category) {
          return 1;
        }
        return 0;
      });
      //console.log(typeArray);
      return {
        ...state,
        types: typeArray,
        loading: false
      };
    case actionTypes.GET_POISTYPES_FAIL:
      return {
        ...state,
        error: "Error",
        loading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default reducer;
