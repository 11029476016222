import * as actionTypes from "./actionTypes";
import axios from "axios";
import { getEnvironment, headers } from "../../config/config";

const addUserStart = () => {
  return {
    type: actionTypes.ADD_USER_START
  };
};

const addUserSuccess = user => {
  return {
    type: actionTypes.ADD_USER_SUCCESS,
    user
  };
};

const addUserFail = error => {
  return {
    type: actionTypes.ADD_USER_FAIL,
    error
  };
};

export const addUser = (email, password, firstName, lastName, roles) => {
  return async dispatch => {
    dispatch(addUserStart());
    try {
      const res = await axios.post(
        getEnvironment().apiUrl + `/Account/Create`,
        {
          email,
          password,
          firstName,
          lastName,
          roles
        },
        {
          headers: headers()
        }
      );
      dispatch(addUserSuccess(res.data));
      return res.data;
    } catch (error) {
      dispatch(addUserFail(error.response.data.Message));
      return error;
    }
  };
};

const inviteUserStart = () => {
  return {
    type: actionTypes.INVITE_USER_START
  };
};

const inviteUserSuccess = user => {
  return {
    type: actionTypes.INVITE_USER_SUCCESS,
    user
  };
};

const inviteUserFail = error => {
  return {
    type: actionTypes.INVITE_USER_FAIL,
    error
  };
};

export const inviteUser = (firstName, lastName, email, roles, callBackUrl) => {
  return async dispatch => {
    dispatch(inviteUserStart());
    try {
      const res = await axios.post(
        getEnvironment().apiUrl + "/Account/InviteUser",
        {
          firstName,
          lastName,
          email,
          roles,
          callBackUrl
        },
        {
          headers: headers()
        }
      );
      dispatch(inviteUserSuccess(res.data));
      return res.data;
    } catch (error) {
      dispatch(inviteUserFail(error));
      return error;
    }
  };
};

const getUsersStart = () => {
  return {
    type: actionTypes.GET_USERS_START
  };
};

const getUsersSuccess = users => {
  return {
    type: actionTypes.GET_USERS_SUCCESS,
    users
  };
};

const getUsersFail = error => {
  return {
    type: actionTypes.GET_USERS_FAIL,
    error
  };
};

export const getUsers = () => {
  return dispatch => {
    dispatch(getUsersStart());
    axios
      .get(getEnvironment().apiUrl + "/Account/All", {
        headers: headers()
      })
      .then(res => dispatch(getUsersSuccess(res.data)))
      .catch(error => dispatch(getUsersFail(error)));
  };
};

const getUserStart = () => {
  return {
    type: actionTypes.GET_USER_START
  };
};

const getUserSuccess = user => {
  return {
    type: actionTypes.GET_USER_SUCCESS,
    user
  };
};

const getUserFail = error => {
  return {
    type: actionTypes.GET_USER_FAIL,
    error
  };
};

export const getUser = userId => {
  return async dispatch => {
    dispatch(getUserStart());
    try {
      const res = await axios.get(
        getEnvironment().apiUrl + `/Account/User/${userId}`,
        {
          headers: headers()
        }
      );
      dispatch(getUserSuccess(res.data));
      return res.data;
    } catch (error) {
      dispatch(getUserFail(error));
      return error;
    }
  };
};

const getRolesStart = () => {
  return {
    type: actionTypes.GET_ROLES_START
  };
};

const getRolesSuccess = roles => {
  return {
    type: actionTypes.GET_ROLES_SUCCESS,
    roles
  };
};

const getRolesFail = error => {
  return {
    type: actionTypes.GET_ROLES_FAIL,
    error
  };
};

export const getRoles = () => {
  return async dispatch => {
    dispatch(getRolesStart());
    try {
      const res = await axios.get(
        getEnvironment().apiUrl + "/Account/GetRoles",
        {
          headers: headers()
        }
      );
      dispatch(getRolesSuccess(res.data));
      return res.data;
    } catch (error) {
      dispatch(getRolesFail(error));
      return error;
    }
  };
};
