import React, { Component } from "react";
import Admin from "./layouts/Admin";
import AddVilla from "./layouts/AddVilla";
import Login from "./layouts/Login.js";
import ForgotPassword from "./layouts/ForgotPassword";
import ResetPassword from "./layouts/ResetPassword";
import { Route, Switch, Redirect } from "react-router-dom";
import * as actions from "./store/actions/index";
import { connect } from "react-redux";
import moment from "moment";
import AlertDialog from "./components/UI/Alert";
import "./App.css";
import OwnerAdd from "./layouts/Owner/OwnerAdd";
import Owner from "./layouts/Owner/Owner";

let users = localStorage.getItem("user");
let user = JSON.parse(users);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      user ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

const OwnerRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      user ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

class App extends Component {
  async componentDidMount() {
    if (user) {
      await user.roles.map(role => {
        if (role.role === "Owner" && role.on) {
          this.props.setHighestRole("Owner");
        } else if (role.role === "Admin" && role.on) {
          this.props.setHighestRole("Admin");
        }
      });
      if (
        //   user.tokeExpiration < moment().format() &&
        this.props.Role === "Admin"
      ) {
        // await this.props.authRefresh();
        //  setTimeout(() => {
        this.props.getLogs(
          moment().format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
          "Mykonos",
          "SUMMER",
          7
        );
        this.props.getAreas();
        this.props.getZones();
        this.props.getVillas();
        this.props.getAmenities();
        this.props.GetIncludedinPrice();
        this.props.getStatuses();
        this.props.getUsers();
        this.props.getCoupons(1);
        this.props.GetExtraDataOnBoarding();
        this.props.getPoiTypes();
        this.props.getPois(1);
        this.props.getPriceLogs(
          moment().format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
          "",
          "Mykonos",
          "Summer",
          7
        );
        //    }, 3000);
      } else {
        // this.props.getLogs(
        //   moment()
        //     .subtract(2, "d")
        //     .format("YYYY-MM-DD"),
        //   moment().format("YYYY-MM-DD"),
        //   ""
        // );
        this.props.getAreas();
        this.props.getZones();
        // this.props.getAmenities();
        this.props.getVillas();
        //this.props.GetIncludedinPrice();
        this.props.getStatuses();
        // this.props.getUsers();
        this.props.GetExtraDataOnBoarding();
      }
    }
  }

  render() {
    if (!this.props.Role) {
      return (
        <>
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <PrivateRoute path="/admin" component={Admin} />
            <PrivateRoute path="/admin/AddVilla" component={AddVilla} />
            <Redirect from="/" to="/admin" />
          </Switch>
          <AlertDialog />
        </>
      );
    }
    return (
      <>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          {this.props.Role === "Admin" ? (
            <PrivateRoute path="/admin" component={Admin} />
          ) : (
            <OwnerRoute path="/owner" component={Owner} />
          )}
          {this.props.Role === "Admin" ? (
            <PrivateRoute path="/admin/AddVilla" component={AddVilla} />
          ) : (
            <OwnerRoute path="/owner/AddVilla" component={OwnerAdd} />
          )}
          {this.props.Role === "Admin" ? (
            <Redirect from="/" to="/admin" />
          ) : (
            <Redirect from="/" to="/owner" />
          )}
        </Switch>
        <AlertDialog />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    state,
    allLoaded: !state.area.loading && !state.villas.loading,
    Role: state.auth.Role
  };
};

const mapDispatchToStore = dispatch => {
  return {
    getLogs: (from, to, area, season, duration) =>
      dispatch(actions.getLogs(from, to, area, season, duration)),
    setHighestRole: Role => dispatch(actions.setHighestRole(Role)),
    getAreas: () => dispatch(actions.getAreas()),
    getZones: () => dispatch(actions.getZones()),
    getClients: () => dispatch(actions.getClients()),
    getVillas: () => dispatch(actions.getVillas()),
    getStatuses: () => dispatch(actions.getStatuses()),
    authRefresh: () => dispatch(actions.authRefreshToken()),
    getAmenities: () => dispatch(actions.getAmenities()),
    GetIncludedinPrice: () => dispatch(actions.GetIncludedinPrice()),
    GetExtraDataOnBoarding: () => dispatch(actions.extraDataOnBoarding()),
    getUsers: () => dispatch(actions.getUsers()),
    getCoupons: id => dispatch(actions.getCoupons(id)),
    getPoiTypes: () => dispatch(actions.getPoiTypes()),
    getPois: id => dispatch(actions.getPois(id)),
    getPriceLogs: (from, to, area, destination, season, duration) =>
      dispatch(
        actions.getPriceLogs(from, to, area, destination, season, duration)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(App);
