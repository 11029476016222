import * as actionTypes from "./actionTypes";
import axios from "axios";
import { headers } from "../../config/config";
import { getEnvironment } from "../../config/config";

export const getAreasStart = () => {
  return {
    type: actionTypes.GET_AREAS_START
  };
};

export const getAreasSuccess = areas => {
  return {
    type: actionTypes.GET_AREAS_SUCCESS,
    areas
  };
};

export const getAreasFail = error => {
  return {
    type: actionTypes.GET_AREAS_FAIL,
    error
  };
};

export const getZonesStart = () => {
  return {
    type: actionTypes.GET_ZONES_START
  };
};

export const getZonesSuccess = zones => {
  return {
    type: actionTypes.GET_ZONES_SUCCESS,
    zones
  };
};

export const getZonesFail = error => {
  return {
    type: actionTypes.GET_ZONES_FAIL,
    error
  };
};

export const setArea = (id, name, area) => {
  area = area[0];
  let areaSelected = { id, name, area };
  return {
    type: actionTypes.SET_AREA,
    areaSelected: areaSelected
  };
};

// gets a list of available areas
export const getAreas = () => {
  return dispatch => {
    dispatch(getAreasStart());
    axios
      .get(getEnvironment().apiUrl + `/Settings/Area`, {
        headers: headers()
      })
      .then(response => {
        dispatch(getAreasSuccess(response.data));
        const name = "Mykonos";
        let area = response.data.filter(area => area.id === 1);
        dispatch(setArea(1, name, area));
      })
      .catch(error => {
        dispatch(getAreasFail(error.response));
      });
  };
};

// gets a list of available Zones
export const getZones = () => {
  return dispatch => {
    dispatch(getZonesStart());
    axios
      .get(getEnvironment().apiUrl + `/Settings/Zone`, {
        headers: headers()
      })
      .then(response => {
        // console.log("ZONES", response.data);
        dispatch(getZonesSuccess(response.data));
      })
      .catch(error => {
        dispatch(getZonesFail(error.response));
      });
  };
};

export const addZones = data => {
  return dispatch => {
    axios
      .post(getEnvironment().apiUrl + `/Settings/AreaZone`, data, {
        headers: headers()
      })
      .then(response => {
        dispatch(getZones());
      })
      .catch(error => {
        dispatch(getZonesFail(error.response));
      });
  };
};

export const deleteZones = data => {
  return dispatch => {
    axios
      .delete(getEnvironment().apiUrl + `/Settings/AreaZone/${data}`, {
        headers: headers()
      })
      .then(response => {
        dispatch(getZones());
      })
      .catch(error => {
        console.log("DELETE", error);
        // dispatch(getZonesFail(error.response));
      });
  };
};

export const getZonesByArea = (selectdedArea, zones) => {
  return zones.filter(zone => {
    return zone.areaid === selectdedArea.id;
  });
};
