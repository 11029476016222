import React from "react";
import Image from "react-image-enlarger";

export default function ImageThumbnails(props) {
  const [zoomed, setZoomed] = React.useState(false);

  return (
    <div style={{ margin: "0.25rem" }}>
      <Image
        style={{ width: "200px", maxHeight: "130px" }}
        zoomed={zoomed}
        src={props.data}
        onClick={() => setZoomed(true)}
        onRequestClose={() => setZoomed(false)}
      />
    </div>
  );
}
