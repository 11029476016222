import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";

import Switch from "@material-ui/core/Switch";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import HomeIcon from "@material-ui/icons/Home";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
class EditPromo extends Component {
  state = {
    prefix: "",
    active: true,
    areaId: this.props.areaId ? this.props.areaId : 1,
    numberOfNewCoupon: 0,
    //numberOfNewCoupon set to 0 for edit
    maxUsages: null,
    expireAt: null,
    discountPercentage: "",
    discountFlat: "",
    promoAdded: false,
    loading: true
  };

  componentDidMount() {
    if (this.props.history.location.state.Coupon) {
      const Promo = this.props.history.location.state.Coupon;
      this.setState({
        prefix: Promo.prefix,
        discountPercentage: Promo.discountPercentage
          ? Promo.discountPercentage
          : "",
        discountFlat: Promo.discountFlat ? Promo.discountFlat : "",
        active: Promo.active,
        usages: Promo.usages,
        serialNumber: Promo.serialNumber,
        expireAt: Promo.expireAt,
        owner: Promo.owner,
        partner: Promo.partner,
        maxUsages: Promo.maxUsages ? Promo.maxUsages : null,
        loading: false
      });
    }
  }

  handleChanges = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleActive = () => {
    this.setState({ active: !this.state.active });
  };

  checkValidation = () => {
    this.setState({ areaId: this.props.areaId });

    if (this.state.discountFlat && this.state.discountPercentage) {
      this.setState({
        errors: true,
        errormsg: "only one discount at a time allowed"
      });
      return false;
    }
    if (this.state.discountFlat && this.state.discountFlat < 0) {
      this.setState({ errors: true, errormsg: "no Negative numbers" });
      return false;
    }
    if (this.state.discountPercentage && this.state.discountPercentage < 0) {
      this.setState({ errors: true, errormsg: "no Negative numbers" });
      return false;
    }
    if (!this.state.discountFlat && !this.state.discountPercentage) {
      this.setState({
        errors: true,
        errormsg: "You need one discount enabled"
      });
      return false;
    }
    this.setState({ errors: false });

    this.props.addCoupon(this.state).then(res => {
      this.props.getCoupons(this.props.areaId);
      this.setState(
        res[0].id ? { promoAdded: true } : { promoAdded: false },
        () =>
          !this.state.promoAdded
            ? this.props.openAlert("", "Error in adding coupon")
            : null
      );
    });
  };

  selectDate = e => {
    const value = moment(e).format();
    this.setState({
      expireAt: value
    });
  };
  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 150
          }}
        >
          <CircularProgress disableShrink />
        </div>
      );
    }
    return !this.state.promoAdded ? (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <TextField
            placeholder="Type your prefix"
            label="prefix"
            onChange={this.handleChanges}
            value={this.state.prefix}
            name={"prefix"}
            margin="normal"
            disabled
            required
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
          />
          <TextField
            placeholder="owner"
            label="owner"
            onChange={this.handleChanges}
            value={this.state.owner}
            disabled
            name={"owner"}
            margin="normal"
            required
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
          />
          <TextField
            placeholder="partner"
            label="partner"
            onChange={this.handleChanges}
            value={this.state.partner}
            disabled
            name={"partner"}
            margin="normal"
            required
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
          />
          <TextField
            placeholder="serial Number"
            onChange={this.handleChanges}
            value={this.state.serialNumber}
            name={"prefix"}
            label="serial Number"
            margin="normal"
            disabled
            required
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
          />

          <>
            <TextField
              placeholder="discount  %"
              label={this.state.errors ? this.state.errormsg : "discount %"}
              onChange={this.handleChanges}
              value={this.state.discountPercentage}
              name={"discountPercentage"}
              margin="normal"
              variant="outlined"
              type="number"
              inputProps={{ min: "0" }}
              style={{
                width: "30%",
                backgroundColor: "white"
              }}
              error={this.state.errors}
            />
            <TextField
              placeholder="discount Flat"
              label={this.state.errors ? this.state.errormsg : "discount Flat"}
              onChange={this.handleChanges}
              value={this.state.discountFlat}
              name={"discountFlat"}
              inputProps={{ min: "0" }}
              margin="normal"
              variant="outlined"
              type="number"
              style={{
                width: "30%",
                backgroundColor: "white"
              }}
              error={this.state.errors}
            />
            <TextField
              label="maxUsages"
              onChange={this.handleChanges}
              value={this.state.maxUsages}
              name={"maxUsages"}
              type="number"
              margin="normal"
              variant="outlined"
              style={{ width: "30%", backgroundColor: "white" }}
            />

            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                autoOk={true}
                placeholder="Expiration date"
                value={this.state.expireAt}
                onChange={this.selectDate}
                margin="normal"
                variant="outlined"
                disablePast
              />
            </MuiPickersUtilsProvider>
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 20
              }}
            >
              <div className="p">Active</div>
              <Switch
                checked={this.state.active}
                color="primary"
                name={"active"}
                onChange={this.handleActive}
              />
            </div>
          </>

          <div
            style={{
              marginTop: 50
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={this.checkValidation}
            >
              Save Coupon
            </Button>
          </div>
        </div>
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 300,
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <CheckIcon color="primary" />
          <div style={{ marginLeft: 10, marginBottom: 20 }}>
            Coupon saved successfully
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={e => {
              e.preventDefault();

              this.props.history.push("/admin/promocodes");
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div>Go back </div>
              <div
                style={{
                  paddingTop: 2,
                  marginLeft: 5
                }}
              >
                <HomeIcon />
              </div>
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    areaId: state.area.selected.id
  };
};

const mapDispatchToStore = dispatch => {
  return {
    getRoles: () => dispatch(actions.getRoles()),
    addCoupon: data => dispatch(actions.addCoupon(data)),
    getCoupons: id => dispatch(actions.getCoupons(id)),
    openAlert: (title, text) => dispatch(actions.openAlert(title, text))
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(EditPromo);
