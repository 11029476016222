import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components

import Button from "@material-ui/core/Button";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import * as actions from "../../store/actions";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import moment from "moment";
import ExcelExport from "../../components/UI/ExcelExport/ExcelExport";
import ReactExport from "react-data-export";

const useStyles = makeStyles({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    },
    cursor: "pointer"
  },
  filterbar: {
    position: "absolute",
    right: 0
  },
  CardHeader: {
    display: "flex",
    alignItems: "center"
  },
  IqButton: {
    maxHeight: 30,
    margin: "0 1rem"
  },
  IqContainer: {
    alignItems: "center",
    justifyContent: "space-evenly",
    maxWidth: 900
  }
});
// {
//   "reservationid": "string",
//   "numberOfGuests": 0,
//   "emailAddress": "string",
//   "name": "string",
//   "surname": "string",
//   "phoneCountryCode": "string",
//   "phoneNumber": "string",
//   "purposeOfStay": "string",
//   "nationality": "string",
//   "age": 0,
//   "groupComposition": "string",
//   "flightNumberOrBoatDetails": "string",
//   "estimatedTimeOfArrival": "string",
//   "additionalServices": [
//     "string"
//   ],
//   "captchaResponseCode": "string"
// }

const Inquryform = props => {
  const classes = useStyles();
  const { getInquiryForms, InquiryForms } = props;

  const [state] = React.useState({
    columns: [
      { title: "Name", field: "name" },
      { title: "Surname", field: "surname" },
      { title: "Email Address", field: "emailAddress" },
      { title: "Reservation", field: "reservationid" }
    ],
    data: InquiryForms
  });
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const excelcols = [
    <ExcelColumn label="Name" value="name" />,
    <ExcelColumn label="Surname" value="surname" />,
    <ExcelColumn label="Email Address" value="emailAddress" />,
    <ExcelColumn label="Reservation id" value="reservationid" />,
    <ExcelColumn label="Number Of Guests" value="numberOfGuests" />,
    <ExcelColumn label="Phone Country Code" value="phoneCountryCode" />,
    <ExcelColumn label="Phone number" value="phoneNumber" />,
    <ExcelColumn label="Purpose of stay" value="purposeOfStay" />,
    <ExcelColumn label="Nationality" value="nationality" />,
    <ExcelColumn label="Age" value="age" />,
    <ExcelColumn label="Group Composition" value="groupComposition" />,
    <ExcelColumn
      label="Flight number or board details"
      value="flightNumberOrBoatDetails"
    />,
    <ExcelColumn
      label="Estimate time of arrival"
      value="estimatedTimeOfArrival"
    />,
    <ExcelColumn label="Additional services" value="additionalServices" />,
    <ExcelColumn label="Deleted" value="deleted" />,
    <ExcelColumn label="Created" value="created" />
  ];
  // The first commit of Material-UI
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState(null);

  const handleDateChangeFrom = date => {
    setSelectedDateFrom(date);
  };
  const handleDateChangeTo = date => {
    setSelectedDateTo(date);
  };

  const handleGetData = () => {
    if (selectedDateFrom && selectedDateTo) {
      getInquiryForms(
        moment(selectedDateFrom).format("YYYY-MM-D"),
        moment(selectedDateTo).format("YYYY-MM-D")
      );
    } else {
      props.openAlert("", "Add date [from - date to]");
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader className={classes.CardHeader} color="primary">
            <h4 className={classes.cardTitleWhite}>Inquiry</h4>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                container
                className={[classes.IqContainer, "ingqury-header"]}
              >
                <DatePicker
                  autoOk={true}
                  margin="normal"
                  id="date-picker-dialog"
                  label="Start date"
                  format="MM/dd/yyyy"
                  value={selectedDateFrom}
                  onChange={handleDateChangeFrom}
                />
                <DatePicker
                  autoOk={true}
                  margin="normal"
                  id="date-picker-dialog"
                  label="End date"
                  format="MM/dd/yyyy"
                  value={selectedDateTo}
                  onChange={handleDateChangeTo}
                />
                <div>
                  <Button
                    className={classes.IqButton}
                    variant="contained"
                    color="primary"
                    href="#contained-buttons"
                    onClick={handleGetData}
                  >
                    Get data
                  </Button>
                  {InquiryForms.length > 0 ? (
                    <Button
                      className={classes.IqButton}
                      variant="contained"
                      color="primary"
                      href="#contained-buttons"
                    >
                      <ExcelExport
                        filename={"inquiry_data"}
                        data={InquiryForms}
                        name={"inquiry_data"}
                        children={excelcols}
                      />
                    </Button>
                  ) : null}
                </div>
              </Grid>
            </MuiPickersUtilsProvider>
          </CardHeader>
          <CardBody>
            <MaterialTable
              title={`Inquiry Data`}
              columns={state.columns}
              data={InquiryForms}
              editable={
                {
                  // onRowAdd: newData =>
                  //   new Promise(resolve => {
                  //     setTimeout(() => {
                  //       resolve();
                  //       // setState(prevState => {
                  //       //   const data = [...prevState.data];
                  //       //   data.push(newData);
                  //       //   return { ...prevState, data };
                  //       // });
                  //       newData = {
                  //         id: null,
                  //         areaid: props.selectedArea.id,
                  //         name: newData.name
                  //       };
                  //       props.addZones(newData);
                  //     }, 600);
                  //   })
                  // onRowDelete: oldData =>
                  //   new Promise(resolve => {
                  //     setTimeout(() => {
                  //       resolve();
                  //       props.deleteZones(oldData.id);
                  //     }, 600);
                  //   })
                }
              }
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    InquiryForms: state.settings.InquiryForms
  };
};

const mapDispatchToStore = dispatch => {
  return {
    getInquiryForms: (from, to) => dispatch(actions.getInquiryForms(from, to)),
    openAlert: (title, text) => dispatch(actions.openAlert(title, text))
    // deleteZones: data => dispatch(actions.deleteZones(data))
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(Inquryform);
