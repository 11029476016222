import * as actionTypes from "../actions/actionTypes";
const initialState = {
  villas: [],
  villaDetails: [],
  published: [],
  villaStatus: [],
  loading: true,
  statuses: [],
  vilasInAreas: [],
  siteIds: [],
  vilasInPage: [],
  calendarEvents: [],
  eventsLoaded: false,
  onBoardingMoreExtras: [],
  onBoardingMoreExtrasOptimized: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STATUSES_START:
      return {
        ...state
      };
    case actionTypes.GET_STATUSES_SUCCESS:
      return {
        ...state,
        statuses: action.statuses
      };
    case actionTypes.GET_STATUSES_FAIL:
      return {
        ...state,
        error: action.error
      };

    case actionTypes.GET_VILLAS_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_VILLAS_SUCCESS:
      let newvillaspublish = [];
      let newvillasunbpublish = [];
      let siteIds = [];
      action.villas.map(villa => {
        if (villa.isPmsListed) {
          newvillasunbpublish.push(villa);
        } else {
          newvillaspublish.push(villa);
        }

        if (villa.siteId) {
          const villaData = {
            name: villa.nickname,
            siteId: villa.siteId
          };
          siteIds.push(villaData);
        }
      });
      return {
        ...state,
        countpublished: newvillaspublish.length,
        loading: false,
        count: siteIds.length,
        villas: newvillasunbpublish,
        published: newvillaspublish,
        villasInAreas: action.villasInAreas,
        siteIds
      };
    case actionTypes.GET_VILLAS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case actionTypes.GET_VILLAS_DETAILS_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_VILLAS_DETAILS_SUCCESS:
      return {
        ...state,
        villaDetails: action.villaDetails,
        loading: false
      };
    case actionTypes.GET_VILLAS_DETAILS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case actionTypes.ADD_VILLAS_SUCCESS:
      return {
        ...state,
        //villas: state.villas.push(action.villa),
        loading: false
      };
    case actionTypes.ADD_VILLAS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case actionTypes.EDIT_VILLA_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.EDIT_VILLA_SUCCESS:
      return {
        ...state,
        loading: false,
        villaDetails: action.villa
      };
    case actionTypes.EDIT_VILLA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.UPDATE_VILLA_STATUS_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.UPDATE_VILLA_STATUS_SUCCESS:
      return {
        ...state,
        villaStatus: action.status,
        loading: false
      };
    case actionTypes.UPDATE_VILLA_STATUS_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    case actionTypes.SET_VILAS_IN_PAGE:
      return {
        ...state,
        vilasInPage: action.payload,
        eventsLoaded: false
      };
    case actionTypes.GET_EVENTS_SUCCESS:
      return {
        ...state,
        calendarEvents: action.payload,
        eventsLoaded: true
      };
    case actionTypes.GET_EVENTS_FAIL:
      return {
        ...state,
        calendarEvents: [],
        eventsLoaded: false
      };
    case actionTypes.GET_ONBOARDINGMORE_EXTRAS_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_ONBOARDINGMORE_EXTRAS_SUCCESS:
      return {
        ...state,
        onBoardingMoreExtras: action.payload[1],
        onBoardingMoreExtrasOptimized: action.payload[0],

        loading: false
      };
    case actionTypes.GET_ONBOARDINGMORE_EXTRAS_FAIL:
      return {
        ...state,
        onBoardingMoreExtras: [],
        loading: true
      };
    default:
      return {
        ...state
      };
  }
};

export default reducer;
