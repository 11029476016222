import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AddCircle from "@material-ui/icons/AddCircle";
import LocationOn from "@material-ui/icons/LocationOn";
import Settings from "@material-ui/icons/Settings";
import DashboardPage from "./views/Dashboard/Dashboard.js";
import UserList from "./layouts/UserList";
import TableList from "./views/TableList/TableList.js";
import Maps from "./views/Maps/Maps.js";
import SettingsPage from "./views/Settings/Settings.js";
import AddVilla from "./layouts/AddVilla";
import EditVilla from "./layouts/EditVilla";
import AddUser from "./layouts/AddUser";
import EditUser from "./layouts/EditUser";
import Profile from "./layouts/Profile";
import Logs from "./views/Logs/Logs";
import PriceLogs from "./views/Logs/PriceLogs";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import PromoCodes from "./views/PromoCodes/PromoCodes";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import AddPromo from "./views/PromoCodes/AddPromo";
import EditCoupon from "./views/PromoCodes/EditPromo";
import AddPOI from "./views/POIs/AddPOI";
import EditPoi from "./views/POIs/EditPoi";
import POIs from "./views/POIs/POIs";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/AddVilla",
    name: "Add Property",
    icon: AddCircle,
    component: AddVilla,
    layout: "/admin",
    hidden: true
  },

  {
    path: "/EditVilla/:id",
    name: "Edit Villa",
    icon: "edit_villa",
    component: EditVilla,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/table",
    name: "Property List",
    icon: "content_paste",
    component: TableList,
    layout: "/admin"
  },
  {
    path: "/addUser",
    name: "Add User",
    icon: AddCircle,
    component: AddUser,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/addCoupon",
    name: "Add Coupon",
    icon: AddCircle,
    component: AddPromo,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/addPoi",
    name: "Add POI",
    icon: AddCircle,
    component: AddPOI,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/editUser/:id",
    name: "Edit User",
    icon: "edit_user",
    component: EditUser,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/editCoupon/:id",
    name: "Edit Coupon",
    icon: "edit_user",
    component: EditCoupon,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/editPoi/:id",
    name: "Edit Poi",
    icon: "edit_user",
    component: EditPoi,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/user",
    name: "User List",
    icon: Person,
    component: UserList,
    layout: "/admin"
  },
  {
    path: "/maps",
    name: "Livemap",
    icon: LocationOn,
    component: Maps,
    layout: "/admin",
    hidden: true
  },
  {
    path: "/settings",
    name: "Settings",
    icon: Settings,
    component: SettingsPage,
    layout: "/admin"
  },
  {
    path: "/logs",
    name: "Logs",
    icon: SpeakerNotesIcon,
    component: Logs,
    layout: "/admin"
  },
  {
    path: "/pricelogs",
    name: "PriceLogs",
    icon: LocalOfferIcon,
    component: PriceLogs,
    layout: "/admin"
  },
  {
    path: "/promocodes",
    name: "PromoCodes",
    icon: MonetizationOnIcon,
    component: PromoCodes,
    layout: "/admin"
  },
  {
    path: "/pois",
    name: "POIS",
    icon: LocationOn,
    component: POIs,
    layout: "/admin"
  },
  {
    path: "/profile",
    name: "Profile",
    icon: "profile",
    component: Profile,
    layout: "/admin",
    hidden: true
  }
];

export default dashboardRoutes;
