import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { timingSafeEqual } from "crypto";

export default class OnBoardingExtraData extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    text: ""
  };

  handleChangeExtra = e => {
    e.preventDefault();
    this.setState({ text: e.target.value });
  };

  componentDidMount() {
    this.setState({ text: this.props.form });
    let inputEl = this.myRef.current.children[1].children[0];

    inputEl.addEventListener("blur", event => {
      this.props.editForm(this.state.text, this.props.index);
    });
  }

  componentWillUnmount() {
    let inputEl = this.myRef.current.children[1].children[0];

    inputEl.removeEventListener("blur", event => {});
  }

  render() {
    const { data } = this.props;
    // console.log("Here",this.props)
    return (
      <TextField
        ref={this.myRef}
        placeholder={data}
        label={data}
        onChange={this.handleChangeExtra}
        name={data}
        value={this.state.text}
        rows={3}
        margin="normal"
        variant="outlined"
        style={{ width: "40%", backgroundColor: "white" }}
      />
    );
  }
}
