import React, { Component } from "react";
import MaterialTable from "material-table";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import moment from "moment";
import * as actions from "../../store/actions";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import Modal from "./Modal";

class Logs extends Component {
  state = {
    open: false,
    bot: false,
    modalOpen: false,
    open2: false,
    open3: false,
    duration: 7,
    season: "SUMMER",
    contentGuesty: "",
    contentBooking: "",
    contentAirbnb: "",
    destination: "Mykonos",
    destinationBot: "Booking",
    columns: [
      {
        title: "period",
        field: "checkInckeckOut"
        //  defaultGroupOrder: 0

        // render: rowData => (
        //   <h6>
        //     {moment(rowData.checkIn).format("MMM-D")}-
        //     {moment(rowData.checkOut).format("MMM-D")}
        //   </h6>
        //  )
      },
      {
        title: "Area",
        field: "destination"
        //  defaultGroupOrder: 1
      },

      {
        title: "Recorded",
        field: "dateFormatted"
        // render: rowData => moment(rowData.date).format("DD MMM")
        // defaultGroupOrder: 1
      },
      { title: "Villas", field: "bookingDotComCount", grouping: false },
      {
        title: "Booking/Guesty",
        field: "pearlsCount",
        render: rowData => (
          <p>
            {rowData.pearlsCountOnBookingDotCom}/{rowData.pearlsCount}
          </p>
        )
      },
      {
        title: "Hotels",
        field: "hotelsCount"
      },
      {
        title: "3-bed",
        field: "bookingDotCom3BedsCount"
      },
      { title: "4-bed", field: "bookingDotCom4BedsCount" },
      { title: "5-bed", field: "bookingDotCom5BedsCount" },
      { title: "6-bed", field: "bookingDotCom6BedsCount" }
    ],
    columnsAirbnb: [
      {
        title: "period",
        field: "checkInckeckOut"
        // defaultGroupOrder: 0
      },
      {
        title: "Area",
        field: "destination"
        //defaultGroupOrder: 1
      },
      {
        title: "Recorded",
        field: "dateFormatted"
        // render: rowData => moment(rowData.date).format("DD MMM")
        // defaultGroupOrder: 1
      },
      { title: "Villas", field: "airBnBCount", grouping: false },
      {
        title: "Airbnb/Guesty",
        field: "pearlsCount",
        render: rowData => (
          <p>
            {rowData.pearlsCountOnAirBnb}/{rowData.pearlsCount}
          </p>
        )
      },
      {
        title: "Hotels",
        field: "hotelsCount"
      },
      {
        title: "3-bed",
        field: "airBnB3BedsCount"
      },
      { title: "4-bed", field: "airBnB4BedsCount" },
      { title: "5-bed", field: "airBnB5BedsCount" },
      { title: "6-bed", field: "airBnB6BedsCount" }
    ],
    data: [],
    selectedDateFrom: moment().format("YYYY-MM-D"),
    selectedDateTo: moment().format("YYYY-MM-D")
  };

  handleDateChangeFrom = date => {
    this.setState({ selectedDateFrom: date });
  };
  handleDateChangeTo = date => {
    this.setState({ selectedDateTo: date });
  };

  handletoggleBot = () => {
    this.setState({ bot: !this.state.bot });
  };

  handletoggle2 = () => {
    this.setState({ open2: !this.state.open2 });
  };
  handlechangeSeason = e => {
    this.setState({ season: e.target.value });
  };
  handletoggle3 = () => {
    this.setState({ open3: !this.state.open3 });
  };
  handlechangeDuration = e => {
    this.setState({ duration: e.target.value });
  };
  handleGetData = () => {
    let areaselect =
      this.state.destination === "All" ? "" : this.state.destination;
    let seasonSelect = this.state.season === "All" ? "" : this.state.season;
    let durationSelect =
      this.state.duration === "All" ? "" : this.state.duration;

    this.props.getLogs(
      moment(this.state.selectedDateFrom).format("YYYY-MM-D"),
      moment(this.state.selectedDateTo).format("YYYY-MM-D"),
      areaselect,
      seasonSelect,
      durationSelect
    );
  };

  handletoggle = () => {
    this.setState({ open: !this.state.open });
  };
  handlechangeDestination = e => {
    this.setState({ destination: e.target.value });
  };
  handlechangeDestinationBot = e => {
    this.setState({ destinationBot: e.target.value });
  };
  componentDidMount() {
    //this.setState({ loading: false });
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  render() {
    if (this.props.loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1
          }}
        >
          <CircularProgress style={{ color: "hotpink", marginTop: 200 }} />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center"
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk={true}
              margin="normal"
              label="Start date"
              format="MM/dd/yyyy"
              value={this.state.selectedDateFrom}
              onChange={this.handleDateChangeFrom}
            />
            <DatePicker
              autoOk={true}
              margin="normal"
              label="End date"
              format="MM/dd/yyyy"
              value={this.state.selectedDateTo}
              onChange={this.handleDateChangeTo}
            />
            <FormControl style={{ marginTop: 10 }}>
              <InputLabel id="demo-controlled-open-select-label">
                Area
              </InputLabel>
              <Select
                id="demo-controlled-open-select"
                open={this.state.open}
                onClose={this.handletoggle}
                onOpen={this.handletoggle}
                value={this.state.destination}
                onChange={this.handlechangeDestination}
              >
                <MenuItem value={"Mykonos"}>Mykonos</MenuItem>
                <MenuItem value={"St.Tropez"}>St.Tropez</MenuItem>
                <MenuItem value={"Ibiza"}>Ibiza</MenuItem>
                <MenuItem value={"Marbella"}>Marbella</MenuItem>
                <MenuItem value={"Bali"}>Bali</MenuItem>
                <MenuItem value={"Tulum"}>Tulum</MenuItem>
                <MenuItem value={"SaintBarthelemy"}>SaintBarthelemy</MenuItem>
                <MenuItem value={"Megeve"}>Megeve</MenuItem>
                <MenuItem value={"Courchevel"}>Courchevel</MenuItem>
                <MenuItem value={"Gstaad"}>Gstaad</MenuItem>
                <MenuItem value={"StMoritz"}>StMoritz</MenuItem>
                <MenuItem value={"ValDisere"}>ValDisere</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ marginTop: 10 }}>
              <InputLabel id="demo-controlled-open-select-label">
                Season
              </InputLabel>
              <Select
                id="demo-controlled-open-select"
                open={this.state.open2}
                onClose={this.handletoggle2}
                onOpen={this.handletoggle2}
                value={this.state.season}
                onChange={this.handlechangeSeason}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"WINTER"}>WINTER</MenuItem>
                <MenuItem value={"SPRING"}>SPRING</MenuItem>
                <MenuItem value={"SUMMER"}>SUMMER</MenuItem>
                <MenuItem value={"AUTUMN"}>AUTUMN</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ marginTop: 10 }}>
              <InputLabel id="demo-controlled-open-select-label">
                Duration
              </InputLabel>
              <Select
                id="demo-controlled-open-select"
                open={this.state.open3}
                onClose={this.handletoggle3}
                onOpen={this.handletoggle3}
                value={this.state.duration}
                onChange={this.handlechangeDuration}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={3}>3 days</MenuItem>
                <MenuItem value={5}>5 days</MenuItem>
                <MenuItem value={7}>7 days</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              href="#contained-buttons"
              onClick={this.handleGetData}
              style={{ width: 80, height: 35, marginTop: 22 }}
            >
              Submit
            </Button>
          </MuiPickersUtilsProvider>
        </div>
        <MaterialTable
          title={
            <div style={{}}>
              <FormControl>
                <InputLabel id="demo-controlled-open-select-label">
                  From
                </InputLabel>
                <Select
                  id="demo-controlled-open-select"
                  open={this.state.bot}
                  onClose={this.handletoggleBot}
                  onOpen={this.handletoggleBot}
                  value={this.state.destinationBot}
                  onChange={this.handlechangeDestinationBot}
                >
                  <MenuItem value={"Booking"}>Booking</MenuItem>
                  <MenuItem value={"Airbnb"}>Airbnb</MenuItem>
                </Select>
              </FormControl>
            </div>
          }
          data={this.props.logs}
          actions={[
            this.state.destinationBot === "Booking"
              ? rowData => ({
                  icon: "list",
                  tooltip: "Show List",
                  iconProps: { color: "secondary" },
                  onClick: (event, rowData) =>
                    this.setState({
                      contentBooking: JSON.stringify(
                        rowData.missingFromBooking
                      ),
                      contentGuesty: JSON.stringify(rowData.missingFromGuesty),
                      modalOpen: !this.state.modalOpen
                    }),

                  hidden: rowData.missingFromBooking
                    ? rowData.missingFromBooking !== "" ||
                      rowData.missingFromGuesty !== ""
                      ? false
                      : true
                    : true
                })
              : rowData => ({
                  icon: "list",
                  tooltip: "Show List",
                  iconProps: { color: "secondary" },
                  onClick: (event, rowData) =>
                    this.setState({
                      contentAirbnb: JSON.stringify(rowData.missingFromAirBnb),
                      contentGuesty: JSON.stringify(
                        rowData.missingFromGuestyPresentAirBnb
                      ),
                      modalOpen: !this.state.modalOpen
                    }),

                  hidden: rowData.missingFromAirBnb
                    ? rowData.missingFromAirBnb !== "" ||
                      rowData.missingFromGuestyPresentAirBnb !== ""
                      ? false
                      : true
                    : true
                })
          ]}
          columns={
            this.state.destinationBot === "Booking"
              ? this.state.columns
              : this.state.columnsAirbnb
          }
          //  parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
          options={{
            pageSize: 10,
            grouping: true,
            pageSizeOptions: [5, 10, 20, 40],
            exportButton: true,
            rowStyle: {
              backgroundColor: "#EEE"
            }
            // exportCsv: (columns, data) => {
            //   // console.log(columns, "cols");
            //   console.log(data, "data");
            // }
          }}
        />
        <Modal
          bot={this.state.destinationBot}
          contentBooking={this.state.contentBooking}
          contentAirbnb={this.state.contentAirbnb}
          contentGuesty={this.state.contentGuesty}
          toggleModal={this.toggleModal}
          open={this.state.modalOpen}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    logs: state.settings.Logs,
    loading: state.settings.loading
  };
};
const mapDispatchToStore = dispatch => {
  return {
    getLogs: (from, to, area, season, duration) =>
      dispatch(actions.getLogs(from, to, area, season, duration))
  };
};
export default connect(mapStateToProps, mapDispatchToStore)(Logs);
