import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import IconButton from "@material-ui/core/IconButton";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import axios from "axios";
import update from "immutability-helper";
import { getEnvironment, headers } from "../config/config";
import { connect } from "react-redux";
import Thumbnails from "./ImageThumbnails";
import * as actions from "../store/actions/index";

let multimedia = [];

class Step6 extends Component {
  state = {
    form: {},
    pearlsOffer: 0,
    fileSelected: false,
    fileType: null,
    fileExtension: null,
    multimedia: [],
    loading: false,
    ref: React.createRef(),
    sendNewOfferClicked: false,
    extraNotes: ""
  };

  componentDidMount() {
    this.setState({ form: this.props.form });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.villaStatus !== this.props.villaStatus) {
      if (this.props.villaStatus === "contractSent") {
        this.setState({ filePath: null, fileType: null, extraNotes: "" });
      }
    }
  }

  handleMultimedia = path => {
    let innerMultimedia = {
      noteType: this.state.fileType,
      noteText: this.state.extraNotes,
      contentUrl: path
    };
    multimedia.push(innerMultimedia);
    this.setState({ multimedia });
  };

  deleteFile = file => {
    multimedia = multimedia.filter(item => item !== file);
    this.setState({
      multimedia
    });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value }, () =>
      this.props.addAmountForOffer(this.state.pearlsOffer)
    );
  };

  handleNotes = event => {
    this.setState({ extraNotes: event.target.value }, () =>
      this.props.addNotes(this.state.extraNotes, "text")
    );
  };

  handleFileName = e => {
    this.setState({ extraNotes: e.target.value });
  };

  handleUploadButton = () => {
    this.handleUpload(this.state.file);
  };

  handleUpload = data => {
    this.setState({ loading: true });
    let url;
    if (this.state.fileType !== "image") {
      url = "/Web/UploadPrivateFile";
    } else {
      url = "/Web/UploadFile";
    }
    let formData = new FormData();
    formData.append("formFile", data);
    const config = {
      headers: headers()
    };
    axios
      .post(getEnvironment().apiUrl + url, formData, config)
      .then(response => {
        this.setState(
          {
            filePath: response.data,
            loading: false,
            fileSelected: false,
            fileType: null,
            fileExtension: null
          },
          () =>
            this.props.addFilePath(
              response.data,
              this.state.fileType,
              this.state.extraNotes
            )
        );
        this.handleMultimedia(response.data);
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.openAlert("", error);
      });
  };

  fileChangedHandler = e => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (file.type.includes("image")) {
      this.setState({ fileType: "image" });
    } else if (file.type.includes("application")) {
      if (file.type.includes("pdf")) {
        this.setState({ fileType: "document" });
      } else {
        this.setState({ fileType: "binary" });
      }
    } else if (file.type.includes("video")) {
      this.setState({ fileType: "video" });
    } else if (file.type.includes("audio")) {
      this.setState({ fileType: "audio" });
    } else if (file.type.includes("text")) {
      this.setState({ fileType: "text" });
    } else if (file.type === "") {
      this.setState({ fileType: "document" });
    }
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        file,
        fileSelected: true
      });
    };
  };

  findFileType = path => {
    let fp = path.split("");
    let fp2 = [];
    for (let i = 36; i < fp.length; i++) {
      fp2.push(fp[i]);
    }
    let fileType = fp2.join("");
    this.setState({ fileExtension: fileType });
  };

  downloadFile = path => {
    this.findFileType(path);
    axios
      .get(getEnvironment().apiUrl + `/Web/DownloadFile/${path}`, {
        headers: headers(),
        responseType: "arraybuffer"
      })
      .then(response => {
        let blob = new Blob([response.data], {
          "content-type": "application/pdf"
        });
        let ref = this.state.ref;
        ref.current.href = URL.createObjectURL(blob);
        ref.current.download = "file" + this.state.fileExtension;
        ref.current.click();
      })
      .catch(error => console.log(error));
  };

  acceptContract = () => {
    this.props
      .updateStatus(
        this.props.villaId,
        "contractAccepted",
        this.state.pearlsOffer,
        this.state.fileType,
        this.state.extraNotes,
        this.state.filePath
      )
      .then(() => {
        this.props.getVillaDetails(this.props.villaId).then(res =>
          this.setState(
            update(this.state, {
              form: {
                $set: res
              }
            }),
            () => this.props.addForm(this.state.form)
          )
        );
        this.setState({ extraNotes: "", fileType: null, filePath: null });
        this.props.clearFiles();
      });
    this.props.handleNext();
  };

  negotiateContract = () => {
    this.props
      .updateStatus(
        this.props.villaId,
        "contractNegotiation",
        null,
        this.state.fileType,
        this.state.extraNotes,
        this.state.filePath
      )
      .then(() => {
        this.props.getVillaDetails(this.props.villaId).then(res =>
          this.setState(
            update(this.state, {
              form: {
                $set: res
              }
            }),
            () => this.props.addForm(this.state.form)
          )
        );
        setTimeout(() => {
          this.setState({
            extraNotes: "",
            fileType: null,
            filePath: null
          });
        }, 400);
      });
  };

  archiveVilla = () => {
    this.setState({ sendNewOfferClicked: false });
    this.props
      .updateStatus(
        this.props.villaId,
        "archive",
        null,
        this.state.fileType,
        this.state.extraNotes,
        this.state.filePath
      )
      .then(() => {
        this.props.getVillaDetails(this.props.villaId).then(res =>
          this.setState(
            update(this.state, {
              form: {
                $set: res
              }
            }),
            () => this.props.addForm(this.state.form)
          )
        );
        setTimeout(() => {
          this.setState({
            extraNotes: "",
            fileType: null,
            filePath: null
          });
          this.props.archiveVilla();
        }, 400);
      });
  };

  rejectContract = () => {
    this.props
      .updateStatus(
        this.props.villaId,
        "contractRejected",
        null,
        this.state.fileType,
        this.state.extraNotes,
        this.state.filePath
      )
      .then(() => {
        this.props.getVillaDetails(this.props.villaId).then(res =>
          this.setState(
            update(this.state, {
              form: {
                $set: res
              }
            }),
            () => this.props.addForm(this.state.form)
          )
        );
        setTimeout(() => {
          this.setState({
            extraNotes: "",
            fileType: null,
            filePath: null
          });
        }, 400);
      });
  };

  render() {
    const { form } = this.state;
    return !this.state.loading ? (
      <>
        {this.props.villaStatus === "contractSent" ||
        this.props.villaStatus === "contractSigned" ||
        this.props.villaStatus === "archive" ||
        this.props.villaStatus === "contractRejected" ||
        this.props.villaStatus === "published" ? (
          <>
            <div
              style={{
                marginTop: "50px",
                display: "flex",
                justifyContent: "space-evenly"
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={this.acceptContract}
                disabled={
                  this.props.villaStatus === "contractSigned" ||
                  this.props.villaStatus === "archive" ||
                  this.props.villaStatus === "published"
                }
                style={{
                  display:
                    this.props.villaStatus === "contractRejected"
                      ? "none"
                      : "block"
                }}
              >
                Contract Accepted
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  this.setState({ pearlsOffer: 0 });
                  this.rejectContract();
                }}
                disabled={
                  this.props.villaStatus === "contractSigned" ||
                  this.props.villaStatus === "archive" ||
                  this.props.villaStatus === "published"
                }
                style={{
                  display:
                    this.props.villaStatus === "contractRejected"
                      ? "none"
                      : "block"
                }}
              >
                Contract Rejected
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.negotiateContract}
                style={{
                  display:
                    this.props.villaStatus === "contractRejected"
                      ? "block"
                      : "none"
                }}
              >
                Negotiate
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.archiveVilla}
                style={{
                  display:
                    this.props.villaStatus === "contractRejected"
                      ? "block"
                      : "none"
                }}
              >
                Archive
              </Button>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <TextField
                placeholder="Pearls Offer"
                label="Pearls Offer"
                onChange={this.handleChange}
                name={"pearlsOffer"}
                value={this.state.pearlsOffer}
                margin="normal"
                variant="outlined"
                style={{ width: "40%", backgroundColor: "white" }}
                type="number"
                required
              />
            </div>
          </>
        )}
        <form
          onSubmit={e => this.handleUploadButton(e)}
          style={{ display: "flex", flexDirection: "column", marginTop: 30 }}
        >
          <TextareaAutosize
            placeholder="Enter notes"
            label="Enter notes"
            onChange={this.handleNotes}
            name={"extraNotes"}
            value={this.state.extraNotes}
            rows={6}
            style={{ width: "20%" }}
          />
          <div>
            <div style={{ marginTop: 10 }}>Upload a file</div>
            <input
              type="file"
              name="file"
              onChange={e => this.fileChangedHandler(e)}
              style={{ marginTop: 20 }}
            />
          </div>
          <Button
            style={{
              display: this.state.fileSelected ? "block" : "none",
              alignSelf: "flex-start",
              padding: 0,
              marginTop: 20
            }}
            onClick={e => this.handleUploadButton(e)}
          >
            Upload
          </Button>
        </form>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {this.state.multimedia.length > 0
            ? this.state.multimedia.map(item => (
                <div key={item.id}>
                  <Card
                    style={{
                      width: "100px",
                      height: "120px",
                      display: "flex",
                      flexDirection: "column",
                      margin: "20px 20px 0 10px"
                    }}
                  >
                    <CardActionArea
                      style={{ height: "80%", textAlign: "center" }}
                    >
                      {item.noteType === "image" ? (
                        <Thumbnails data={item.contentUrl} key={item.id} />
                      ) : (
                        <span>{item.noteText}</span>
                      )}
                    </CardActionArea>
                    <CardActions
                      style={{
                        height: "20%",
                        padding: "0px",
                        justifyContent: "center"
                      }}
                    >
                      <IconButton onClick={() => this.deleteFile(item)}>
                        <DeleteIcon />
                      </IconButton>
                      {item.noteType !== "image" ? (
                        <div>
                          <a
                            onClick={() => console.log("anchor clicked")}
                            style={{ display: "none" }}
                            href="empty"
                            ref={this.state.ref}
                          >
                            ref
                          </a>
                          <IconButton
                            onClick={() => this.downloadFile(item.contentUrl)}
                          >
                            <CloudDownloadOutlinedIcon color="primary" />
                          </IconButton>
                        </div>
                      ) : null}
                    </CardActions>
                  </Card>
                </div>
              ))
            : null}
        </div>
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 150
        }}
      >
        <CircularProgress disableShrink />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    villaStatus: state.villas.villaDetails.propertyState
  };
};

const mapDispatchToStore = dispatch => {
  return {
    openAlert: (title, text) => dispatch(actions.openAlert(title, text)),

    getVillaDetails: id => dispatch(actions.getVillaDetails(id)),
    updateStatus: (id, newStatus, amount, noteType, noteText, contentUrl) =>
      dispatch(
        actions.updateVillaStatus(
          id,
          newStatus,
          amount,
          noteType,
          noteText,
          contentUrl
        )
      )
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(Step6);
