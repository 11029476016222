import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import update from "immutability-helper";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ExpansionPanel from "../components/UI/ExpansionPanel/ExpansionPanel";
import OnBoardingExtraData from "../components/UI/OnBoardingExtraData/OnBoardingExtraData";
import CustomInput from "../components/UI/CustomInput/CustomInput";

import { connect } from "react-redux";

import moment from "moment";

class Step3 extends Component {
  state = {
    form: {},
    activeStep: 2,
    loading: true,
    villaId: null
  };

  componentDidMount() {
    const onBoardingExtraData = this.props.form.onBoardingExtraData;
    let newForm;
    if (
      this.props.editVilla &&
      this.props.form.onBoardingExtraData !== undefined
    ) {
      newForm = { ...this.props.form };
    } else {
      newForm = { ...this.props.form, onBoardingExtraData };
    }

    this.setState(
      {
        form: newForm,
        loading: false,
        villaId: this.props.villaId,
        defaultDate: new Date()
      },
      () => {
        if (this.state.form.onBoardingExtraData) {
          if (
            this.state.form.onBoardingExtraData.onBoardingLayoutsAndFeatures
              .length == 0
          ) {
            this.addOnBoardingMoreExtras();
          }
        }
        this.addBlurListener();
      }
    );
  }

  addBlurListener = () => {
    let inputEl = document.querySelectorAll("input.MuiInputBase-input");
    //let inputEl = this.textInput.current.children[1].children[0];
    inputEl.forEach(el => {
      el.addEventListener("blur", event => {
        this.props.addForm(this.state.form);
      });
    });
  };

  addOnBoardingMoreExtras = () => {
    this.setState(
      state => ({
        ...state,
        form: {
          ...state.form,
          onBoardingMoreExtras: this.props.onBoardingMoreExtras,
          onBoardingExtraData: {
            ...state.form.onBoardingExtraData,
            onBoardingLayoutsAndFeatures: this.props
              .onBoardingMoreExtrasOptimized
          }
        },
        extraLoaded: true
      }),
      () => {
        if (this.state.extraLoaded) {
          this.props.addForm(this.state.form);
        }
      }
    );
  };

  getIndexAndValue = (category, subcategory) => {
    let list = this.state.form.onBoardingExtraData.onBoardingLayoutsAndFeatures;
    let data = null;
    list.map((l, i) => {
      if (l.category === category && l.subcategory === subcategory) {
        return (data = [i, l.value, category, subcategory]);
      }
    });

    return data;
  };

  editForm = (text, index) => {
    if (index > -1) {
      this.setState(
        update(this.state, {
          form: {
            onBoardingExtraData: {
              onBoardingLayoutsAndFeatures: {
                [index]: {
                  value: {
                    $set: text
                  }
                }
              }
            }
          }
        }),
        () => {
          // console.log("gets");
          this.props.addForm(this.state.form);
        }
      );
    }
  };
  handleChange = event => {
    let value = event.target.value;
    this.setState(
      update(this.state, {
        form: {
          onBoardingExtraData: {
            [event.target.name]: {
              $set: value
            }
          }
        }
      })
      // () => this.props.addForm(this.state.form)
    );
  };
  handleChangeCustom = (text, name) => {
    this.setState(
      update(this.state, {
        form: {
          onBoardingExtraData: {
            [name]: {
              $set: text
            }
          }
        }
      }),
      () => {
        this.props.addForm(this.state.form);
      }
    );
  };

  handleChangeDate = event => {
    console.log(event);
    let value = moment(event).format("YYYY-MM-D");
    this.setState(
      update(this.state, {
        form: {
          onBoardingExtraData: {
            nextMeeting: {
              $set: value
            }
          }
        }
      }),
      () => this.props.addForm(this.state.form)
    );
  };

  render() {
    const { form } = this.state;
    if (this.state.loading) {
      return <h3>loading..</h3>;
    }
    return (
      <div id="onBoarding">
        <ExpansionPanel title={"Indoor/Outdoor features"} id={"exp1"}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <CustomInput
              editForm={this.handleChangeCustom}
              data={"Indoor features"}
              name={"indoorFeauturesText"}
              form={
                form.onBoardingExtraData
                  ? form.onBoardingExtraData.indoorFeauturesText
                  : ""
              }
            />
            {/* <TextField
              placeholder="Indoor features"
              label="Indoor features"
              onChange={this.handleChange}
              name={"indoorFeauturesText"}
              value={
                form.onBoardingExtraData
                  ? form.onBoardingExtraData.indoorFeauturesText
                  : ""
              }
              margin="normal"
              style={{ width: "40%", backgroundColor: "white" }}
            /> */}
            <CustomInput
              editForm={this.handleChangeCustom}
              data={"Outdoor features"}
              name={"outdoorFeauturesText"}
              form={
                form.onBoardingExtraData
                  ? form.onBoardingExtraData.outdoorFeauturesText
                  : ""
              }
            />
          </div>
        </ExpansionPanel>

        <ExpansionPanel title={"Performance"} id={"exp2"}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <div style={{ width: "40%", backgroundColor: "white" }}>
              <TextField
                placeholder="Days Occupied"
                type="number"
                label="Days Occupied"
                onChange={this.handleChange}
                name={"daysOccupied"}
                value={
                  form.onBoardingExtraData
                    ? form.onBoardingExtraData.daysOccupied
                    : ""
                }
                margin="normal"
                variant="outlined"
                style={{ width: "100%", backgroundColor: "white" }}
              />
              <br />
              <TextField
                placeholder="Average Daily Rate"
                type="number"
                label="Average Daily Rate"
                onChange={this.handleChange}
                name={"averageDailyRate"}
                value={
                  form.onBoardingExtraData
                    ? form.onBoardingExtraData.averageDailyRate
                    : ""
                }
                margin="normal"
                variant="outlined"
                style={{ width: "100%", backgroundColor: "white" }}
              />
            </div>

            <TextField
              placeholder="Expected return"
              type="number"
              label="Expected return"
              onChange={this.handleChange}
              name={"expectedReturn"}
              value={
                form.onBoardingExtraData
                  ? form.onBoardingExtraData.expectedReturn
                  : ""
              }
              margin="normal"
              variant="outlined"
              style={{ width: "40%", backgroundColor: "white" }}
            />
          </div>
        </ExpansionPanel>

        <ExpansionPanel title={"Follow up"} id={"exp3"}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <div style={{ width: "40%" }}>
              <TextField
                placeholder="Owner demand"
                type="number"
                label="Owner demand"
                onChange={this.handleChange}
                name={"ownerDemand"}
                value={
                  form.onBoardingExtraData
                    ? form.onBoardingExtraData.ownerDemand
                    : ""
                }
                margin="normal"
                variant="outlined"
                style={{ width: "100%", backgroundColor: "white" }}
              />
              <br />
              {/* <TextField
                placeholder="Final notes"
                multiline
                label="Final notes"
                onChange={this.handleChange}
                name={"finalNotes"}
                value={
                  form.onBoardingExtraData
                    ? form.onBoardingExtraData.finalNotes
                    : ""
                }
                rows={3}
                margin="normal"
                variant="outlined"
                style={{ width: "100%", backgroundColor: "white" }}
              /> */}

              <CustomInput
                multiline={true}
                editForm={this.handleChangeCustom}
                data={"Final notes"}
                name={"finalNotes"}
                form={
                  form.onBoardingExtraData
                    ? form.onBoardingExtraData.finalNotes
                    : ""
                }
              />
            </div>
            <div style={{ width: "60%" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <DatePicker
                    autoOk={true}
                    margin="normal"
                    id="date-picker-dialog"
                    label="Next metting:"
                    format="dd-MM-yyyy"
                    value={
                      form.onBoardingExtraData
                        ? form.onBoardingExtraData.nextMeeting == null
                          ? null
                          : moment(form.onBoardingExtraData.nextMeeting)
                        : null
                    }
                    onChange={this.handleChangeDate}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </ExpansionPanel>
        <ExpansionPanel title={"Notes"} id={"exp4"}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            {/* <TextField
              placeholder="Notes"
              label="Notes"
              multiline
              onChange={this.handleChange}
              name={"notes"}
              value={
                form.onBoardingExtraData ? form.onBoardingExtraData.notes : ""
              }
              rows={3}
              margin="normal"
              variant="outlined"
              style={{ width: "40%", backgroundColor: "white" }}
            /> */}
            <CustomInput
              multiline={true}
              editForm={this.handleChangeCustom}
              data={"Notes"}
              name={"notes"}
              form={
                form.onBoardingExtraData ? form.onBoardingExtraData.notes : ""
              }
            />
          </div>
        </ExpansionPanel>
        {!this.state.loading && this.props.onBoardingMoreExtras.length > 0
          ? this.props.onBoardingMoreExtras.map((d, i) => {
              return (
                <ExpansionPanel
                  key={d + i}
                  title={d.category}
                  id={`expExtr` + i}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      flexWrap: "wrap"
                    }}
                  >
                    {d.subcategory.map((t, j) => {
                      if (this.state.form.onBoardingExtraData) {
                        let val = this.getIndexAndValue(d.category, t);
                        if (val) {
                          if (val[0]) {
                            let indexNum = val[0];
                            return (
                              <OnBoardingExtraData
                                key={t + j}
                                data={t}
                                category={d.category}
                                editForm={this.editForm}
                                index={indexNum}
                                form={
                                  this.state.form.onBoardingExtraData
                                    .onBoardingLayoutsAndFeatures[indexNum]
                                    .value
                                }
                              />
                            );
                          }
                        }
                      }
                    })}
                  </div>
                </ExpansionPanel>
              );
            })
          : null}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    onBoardingMoreExtras: state.villas.onBoardingMoreExtras,
    onBoardingMoreExtrasOptimized: state.villas.onBoardingMoreExtrasOptimized
  };
};
const mapDispatchToStore = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToStore)(Step3);
