import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

class EnvRadios extends Component {
  handleEnvironment = e => {
    localStorage.setItem("environment", e.target.value);
    this.props.selectEnv();
  };

  render() {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Environment</FormLabel>
        <RadioGroup name="environment" onChange={this.handleEnvironment}>
          <FormControlLabel
            value="dev"
            control={<Radio />}
            label="Development"
          />
          <FormControlLabel
            value="prod"
            control={<Radio />}
            label="Production"
          />
        </RadioGroup>
      </FormControl>
    );
  }
}

export default EnvRadios;
