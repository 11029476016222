import * as actionTypes from "./actionTypes";
import axios from "axios";
import { headers } from "../../config/config";
import { getEnvironment } from "../../config/config";
import store from "../store";
import moment from "moment";

export const getVillasStart = () => {
  return {
    type: actionTypes.GET_VILLAS_START
  };
};

export const getVillasSuccess = villas => {
  let areas = store.getState().area.areas;
  let selectedArea = store.getState().area.selected;
  let villasInAreas = addVilasInAreas(areas, villas, selectedArea);
  return {
    type: actionTypes.GET_VILLAS_SUCCESS,
    villas,
    villasInAreas
  };
};

export const getVillasFail = error => {
  return {
    type: actionTypes.GET_VILLAS_FAIL,
    error
  };
};
export const getStatusesStart = () => {
  return {
    type: actionTypes.GET_STATUSES_START
  };
};

export const getStatusesSuccess = statuses => {
  return {
    type: actionTypes.GET_STATUSES_SUCCESS,
    statuses
  };
};

export const getStatusesFail = error => {
  return {
    type: actionTypes.GET_STATUSES_FAIL,
    error
  };
};

export const getVillas = isPmsListed => {
  return dispatch => {
    dispatch(getVillasStart());
    axios
      .get(getEnvironment().apiUrl + `/Property/All`, {
        headers: headers()
      })
      .then(response => {
        dispatch(getVillasSuccess(response.data));
      })
      .catch(error => {
        dispatch(getVillasFail(error.response));
      });
  };
};
export const getStatuses = () => {
  return dispatch => {
    dispatch(getStatusesStart());
    axios
      .get(getEnvironment().apiUrl + `/Property/Statuses`, {
        headers: headers()
      })
      .then(response => {
        dispatch(getStatusesSuccess(response.data));
      })
      .catch(error => {
        dispatch(getStatusesFail(error.response));
      });
  };
};

export const getVillaDetailsStart = () => {
  return {
    type: actionTypes.GET_VILLAS_DETAILS_START
  };
};

export const getVillaDetailsSuccess = villaDetails => {
  return {
    type: actionTypes.GET_VILLAS_DETAILS_SUCCESS,
    villaDetails
  };
};

export const getVillaDetailsFail = error => {
  return {
    type: actionTypes.GET_VILLAS_DETAILS_FAIL,
    error
  };
};

export const getVillaDetails = id => {
  return async dispatch => {
    dispatch(getVillaDetailsStart());
    try {
      const res = await axios.get(getEnvironment().apiUrl + `/Property/${id}`, {
        headers: headers()
      });
      dispatch(getVillaDetailsSuccess(res.data));
      return res.data;
    } catch (error) {
      return dispatch(getVillaDetailsFail(error));
    }
  };
};

export const AddVillasSuccess = villa => {
  return {
    type: actionTypes.ADD_VILLAS_SUCCESS,
    villa
  };
};

export const AddVillasFail = error => {
  return {
    type: actionTypes.ADD_VILLAS_FAIL,
    error
  };
};

export const AddVilla = form => {
  return async dispatch => {
    try {
      const response = await axios.post(
        getEnvironment().apiUrl + `/Property/Add`,
        form,
        {
          headers: headers()
        }
      );
      dispatch(AddVillasSuccess(response.data));
      return response.data;
    } catch (error) {
      dispatch(AddVillasFail(error.response));
      return error.response;
    }
  };
};

export const editVillaStart = () => {
  return {
    type: actionTypes.EDIT_VILLA_START
  };
};

export const editVillaSuccess = villa => {
  return {
    type: actionTypes.EDIT_VILLA_SUCCESS,
    villa
  };
};

export const editVillaFail = error => {
  return {
    type: actionTypes.EDIT_VILLA_FAIL,
    error
  };
};

export const editVilla = (form, id) => {
  return async dispatch => {
    dispatch(editVillaStart());
    try {
      const res = await axios.put(
        getEnvironment().apiUrl + `/Property/Update/${id}`,
        form,
        {
          headers: headers()
        }
      );
      dispatch(editVillaSuccess(res.data));
      return res.data;
    } catch (err) {
      return dispatch(editVillaFail(err.response));
    }
  };
};

export const updateVillaStatusStart = () => {
  return {
    type: actionTypes.UPDATE_VILLA_STATUS_SUCCESS
  };
};

export const updateVillaStatusSuccess = status => {
  return {
    type: actionTypes.UPDATE_VILLA_STATUS_SUCCESS,
    status
  };
};

export const updateVillaStatusFail = error => {
  return {
    type: actionTypes.UPDATE_VILLA_STATUS_FAIL,
    error
  };
};

export const setVilasInPage = vilas => {
  return {
    type: actionTypes.SET_VILAS_IN_PAGE,
    payload: vilas
  };
};

export const updateVillaStatus = (
  id,
  newPropertyState,
  amount,
  noteType,
  noteText,
  contentUrl
) => {
  return async dispatch => {
    dispatch(updateVillaStatusStart());
    try {
      const response = await axios.put(
        getEnvironment().apiUrl + `/Property/ChangeState/${id}`,
        {
          application: "dashboard",
          newPropertyState,
          amount,
          noteType,
          noteText,
          contentUrl
        },
        {
          headers: headers()
        }
      );
      dispatch(updateVillaStatusSuccess(response.data));
      return response.data;
    } catch (error) {
      return dispatch(updateVillaStatusFail(error));
    }
  };
};

export const addVilasInAreas = (areas, villas, selectedArea) => {
  let arealist = [];
  areas.map(area => {
    let filteredVillas = villas.filter(villa => {
      if (villa.address.area) {
        return villa.address.area.id === area.id;
      }
    });
    let item = { [area.city]: filteredVillas };
    arealist.push(item);
  });
  return arealist;
};

export const setVillasInSelectedArea = (villas, area) => {
  return villas.filter(areaitem => {
    return Object.keys(areaitem)[0] === area.name;
  });
};

const setPropertyImagesStart = () => {
  return {
    type: actionTypes.SET_PROPERTY_IMAGES_START
  };
};

const setPropertyImagesSuccess = images => {
  return {
    type: actionTypes.SET_PROPERTY_IMAGES_SUCCESS,
    images
  };
};

const setPropertyImagesFail = error => {
  return {
    type: actionTypes.SET_PROPERTY_IMAGES_FAIL,
    error
  };
};

export const setPropertyImages = (image, id) => {
  return dispatch => {
    dispatch(setPropertyImagesStart());
    axios
      .put(
        getEnvironment().apiUrl + `/Property/SetPropertyImages/${id}`,
        image,
        {
          headers: headers()
        }
      )
      .then(res => dispatch(setPropertyImagesSuccess(res.data)))
      .catch(error => dispatch(setPropertyImagesFail(error)));
  };
};

const updateSiteIdStart = () => {
  return {
    type: actionTypes.UPDATE_SITE_ID_START
  };
};

const updateSiteIdSuccess = siteId => {
  return {
    type: actionTypes.UPDATE_SITE_ID_SUCCESS,
    siteId
  };
};

const updateSiteIdFail = error => {
  return {
    type: actionTypes.UPDATE_SITE_ID_FAIL,
    error
  };
};

export const updateSiteId = (id, siteId) => {
  return async dispatch => {
    dispatch(updateSiteIdStart());
    try {
      const res = await axios.put(
        getEnvironment().apiUrl + `/Property/UpdateSiteId/${id}`,
        { siteId },
        {
          headers: headers()
        }
      );
      dispatch(updateSiteIdSuccess(res.data));
      return res.data;
    } catch (error) {
      dispatch(updateSiteIdFail(error));
      return error;
    }
  };
};

const getEventStart = () => {
  return {
    type: actionTypes.GET_EVENTS_START
  };
};

const getEventsSuccess = data => {
  return {
    type: actionTypes.GET_EVENTS_SUCCESS,
    payload: data
  };
};

const getEventsFail = error => {
  return {
    type: actionTypes.GET_EVENTS_FAIL,
    error
  };
};

const getOnBoardingExtraStart = () => {
  return {
    type: actionTypes.GET_ONBOARDINGMORE_EXTRAS_START
  };
};

const getOnBoardingExtraSuccess = (data, list) => {
  return {
    type: actionTypes.GET_ONBOARDINGMORE_EXTRAS_SUCCESS,
    payload: [data, list]
  };
};

const getEOnBoardingExtraFail = error => {
  return {
    type: actionTypes.GET_ONBOARDINGMORE_EXTRAS_FAIL,
    error
  };
};

let plusOneMonth = moment().add(1, "years");
let calendarEventType1 = "photoshooting ";
// let calendarEventType2 = "propertyCalendar ";
let calendarEventType3 = "internalevent ";

export const getVillasEvents = (
  area = 1,
  from = moment().format("YYYY-MM-D"),
  to = plusOneMonth,
  calendarEventType = "photoshooting ",
  villas = []
) => {
  let text = "";
  let i;
  for (i = 0; i < villas.length; i++) {
    text += "&ids=" + villas[i].id;
  }

  return async dispatch => {
    dispatch(getEventStart());
    try {
      const res = await axios.get(
        getEnvironment().apiUrl +
          `/Property/Calendar?area=${area}&from=${from}&to=${to}&calendarEventTypes=${calendarEventType1}&calendarEventTypes=${calendarEventType3}${text}`,
        {
          headers: headers()
        }
      );
      dispatch(getEventsSuccess(res.data));
      return res.data;
    } catch (error) {
      return dispatch(getEventsFail(error));
    }
  };
};

export const extraDataOnBoarding = () => {
  return async dispatch => {
    dispatch(getOnBoardingExtraStart());
    try {
      const res = await axios.get(
        getEnvironment().apiUrl +
          `/Property/OnBoardingFeaturesAndLayoutCategories`,
        // "https://backenddev.thepearls.com/api/Property/OnBoardingFeaturesAndLayoutCategories",
        {
          headers: headers()
        }
      );

      let myData = optimizeData(res.data);
      dispatch(getOnBoardingExtraSuccess(myData, res.data));
      return res.data;
    } catch (error) {
      return dispatch(getEOnBoardingExtraFail(error));
    }
  };
};

const optimizeData = data => {
  let list = [];

  data.map(d => {
    d.subcategory.map(t => {
      list.push({
        category: d.category,
        subcategory: t,
        images: [],
        value: ""
      });
    });
  });
  return list;
};
