import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class ExcelExport extends React.Component {
  render() {
    let namefile = this.props.filename;
    return (
      <ExcelFile
        filename={namefile}
        element={
          <button id="excel" style={{ cursor: "pointer" }}>
            Download Data
          </button>
        }
      >
        <ExcelSheet
          data={this.props.data}
          name={this.props.name}
          children={this.props.children}
        >
          {/* <ExcelColumn label="Id" value="Id" />
                    <ExcelColumn label="Name" value="Name" />
                    <ExcelColumn label="Rating" value="Rating" />
                    <ExcelColumn label="Trips" value="Trips" />
                    <ExcelColumn label="Earnings" value="Earnings" />
                    <ExcelColumn label="Cash" value="Cash" />
                    <ExcelColumn label="Time Online" value="TimeOnline" />
                    <ExcelColumn label="EPH" value="EarningsPerHour" />
                    <ExcelColumn label="TPH" value="TripsPerHour" />
                    <ExcelColumn label="Accepted" value="Accepted" />
                    <ExcelColumn label="Ignored" value="Ignored" />
                    <ExcelColumn label="Acc Rate" value="AcceptanceRate" /> */}
        </ExcelSheet>
      </ExcelFile>
    );
  }
}

export default ExcelExport;
