import { combineReducers } from "redux";
import clientsReducer from "./clients.reducer";
import villasReducer from "./villas.reducer";
import authReducer from "./auth.reducer";
import settingsReducer from "./settings.reducer";
import areaReducer from "./areas.reducer";
import usersReducer from "./users.reducer";
import alertReducer from "./alert.reducer";
import CouponReducer from "./coupons.reducer";
import PoiReducer from "./pois.reducer";

export default combineReducers({
  area: areaReducer,
  clients: clientsReducer,
  villas: villasReducer,
  auth: authReducer,
  settings: settingsReducer,
  users: usersReducer,
  coupons: CouponReducer,
  alert: alertReducer,
  poi: PoiReducer
});
