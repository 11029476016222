import * as actionTypes from "./actionTypes";
import axios from "axios";
import { headers } from "../../config/config";
import { getEnvironment } from "../../config/config";

//amenities
export const getAmenitiesStart = () => {
  return {
    type: actionTypes.GET_AMENITIES_START
  };
};

export const getAmenitiesSuccess = Amenities => {
  return {
    type: actionTypes.GET_AMENITIES_SUCCESS,
    Amenities
  };
};

export const getAmenitiesFail = error => {
  return {
    type: actionTypes.GET_AMENITIES_FAIL,
    error
  };
};

export const updateAmenitiesSuccess = () => {
  return {
    type: actionTypes.UPDATE_AMENITIES_SUCCESS
  };
};

export const updateAmenitiesFail = error => {
  return {
    type: actionTypes.UPDATE_AMENITIES_FAIL,
    error
  };
};
export const DeleteAmenitiesSuccess = () => {
  return {
    type: actionTypes.DELETE_AMENITIES_SUCCESS
  };
};

export const DeleteCategorySuccess = () => {
  return {
    type: actionTypes.DELETE_CATEGORY_SUCCESS
  };
};

export const AddAmenityCategorySucess = () => {
  return {
    type: actionTypes.ADD_AMENITY_CATEGORY_SUCCESS
  };
};

export const getInquiryFormsStart = () => {
  return {
    type: actionTypes.GET_INQUIRYFORMS_START
  };
};

export const getInquiryFormsSuccess = InquiryForms => {
  return {
    type: actionTypes.GET_INQUIRYFORMS_SUCCESS,
    payload: InquiryForms
  };
};

export const getInquiryFormsFail = error => {
  return {
    type: actionTypes.GET_INQUIRYFORMS_FAIL,
    error
  };
};
export const getLogsStart = () => {
  return {
    type: actionTypes.GET_LOGS_START
  };
};
export const getLogsFormsSuccess = logs => {
  return {
    type: actionTypes.GET_LOGS_SUCCESS,
    payload: logs
  };
};

export const getLogsFormsFail = error => {
  return {
    type: actionTypes.GET_LOGS_FAIL,
    error
  };
};

//prices
export const getPriceLogsStart = () => {
  return {
    type: actionTypes.GET_PRICELOGS_START
  };
};
export const getPriceLogsFormsSuccess = logs => {
  return {
    type: actionTypes.GET_PRICELOGS_SUCCESS,
    payload: logs
  };
};

export const getPriceLogsFormsFail = error => {
  return {
    type: actionTypes.GET_PRICELOGS_FAIL,
    error
  };
};
export const getAmenities = () => {
  return dispatch => {
    dispatch(getAmenitiesStart());
    axios
      .get(getEnvironment().apiUrl + `/Settings/Amenity`, {
        headers: headers()
      })
      .then(response => {
        dispatch(getAmenitiesSuccess(response.data));
      })
      .catch(error => {
        dispatch(getAmenitiesFail(error.response));
      });
  };
};
export const DeleteAmenities = data => {
  return dispatch => {
    axios
      .delete(getEnvironment().apiUrl + `/Settings/Amenities`, {
        headers: headers(),
        data: {
          ids: data
        }
      })
      .then(response => {
        dispatch(DeleteAmenitiesSuccess());
      })
      .catch(error => {});
  };
};

export const deleteCategory = id => {
  return dispatch => {
    axios
      .delete(
        getEnvironment().apiUrl + `/Settings/Amenities/${id}`,

        {
          headers: headers()
        }
      )
      .then(response => {
        dispatch(DeleteCategorySuccess());
      })
      .catch(error => {});
  };
};

export const AddAmenityCategory = (name, category) => {
  return dispatch => {
    axios
      .post(
        getEnvironment().apiUrl + `/Settings/Amenity`,
        {
          name,
          category
        },
        {
          headers: headers()
        }
      )
      .then(response => {
        dispatch(AddAmenityCategorySucess());
      })
      .catch(error => {});
  };
};

export const UpdateAmenities = (name, category) => {
  return dispatch => {
    axios
      .post(
        getEnvironment().apiUrl + `/Settings/Amenity`,
        {
          name,
          category
        },
        {
          headers: headers()
        }
      )
      .then(response => {
        dispatch(getAmenitiesSuccess());
      })
      .catch(error => {
        dispatch(getAmenitiesFail(error.response));
      });
  };
};

//Included In Price

export const GetIncludedinPriceSuccess = IncludedinPrice => {
  return {
    type: actionTypes.GET_INCLUDEDINPRICE_SUCCESS,
    IncludedinPrice
  };
};

export const GetIncludedinPriceFail = error => {
  return {
    type: actionTypes.GET_INCLUCEDINPRICE_FAIL,
    error
  };
};
export const GetIncludedinPrice = () => {
  return dispatch => {
    axios
      .get(
        getEnvironment().apiUrl + `/Settings/IncludedInPriceItems`,

        {
          headers: headers()
        }
      )
      .then(response => {
        dispatch(GetIncludedinPriceSuccess(response.data));
      })
      .catch(error => {
        dispatch(GetIncludedinPriceFail(error.response));
      });
  };
};

export const getInquiryForms = (from, to) => {
  return dispatch => {
    dispatch(getInquiryFormsStart());
    axios
      .get(
        getEnvironment().apiUrl +
          `/web/Inquiryforms?fromDate=${from}&toDate=${to}`,
        {
          headers: headers()
        }
      )
      .then(response => {
        // console.log("Inquiryform", response);
        dispatch(getInquiryFormsSuccess(response.data));
      })
      .catch(error => {
        dispatch(getInquiryFormsFail(error.response));
      });
  };
};

export const getLogs = (from, to, area, season, duration) => {
  return dispatch => {
    dispatch(getLogsStart());
    axios
      .get(
        getEnvironment().apiUrl +
          `/Web/DataScrapingStats?from=${from}&to=${to}&destination=${area}&season=${season}&duration=${duration}`,
        {
          headers: headers()
        }
      )
      .then(response => {
        // console.log("logs", response);
        dispatch(getLogsFormsSuccess(response.data));
      })
      .catch(error => {
        dispatch(getLogsFormsFail(error.response));
      });
  };
};

export const getPriceLogs = (from, to, area, destination, season, duration) => {
  return dispatch => {
    dispatch(getPriceLogsStart());
    axios
      .get(
        getEnvironment().apiUrl +
          `/Web/DataScrapingPricingStats?from=${from}&to=${to}&area=${area}&destination=${destination}&season=${season}&duration=${duration}`,
        {
          headers: headers()
        }
      )
      .then(response => {
        // console.log("logs", response);
        dispatch(getPriceLogsFormsSuccess(response.data));
      })
      .catch(error => {
        dispatch(getPriceLogsFormsFail(error.response));
      });
  };
};
