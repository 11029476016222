import React, { Component } from "react";
import MaterialTable from "material-table";
import CircularProgress from "@material-ui/core/CircularProgress";
import PersonAddOutlinedIcon from "@material-ui/icons/PersonAddOutlined";
import { connect } from "react-redux";

class UserList extends Component {
  state = {
    columns: [
      {
        title: "Name",
        field: "firstName",
        render: rowData => (
          <span>
            {rowData.firstName} {rowData.lastName}
          </span>
        )
      },
      { title: "Email", field: "email" },
      {
        title: "Is Active",
        field: "active",
        render: rowData => (rowData.active ? <span>Yes</span> : <span>No</span>)
      },
      {
        title: "Role",
        field: "roles",
        render: rowData => {
          const user = rowData.roles.filter(user => user.on);
          if (user.length) {
            return <span>{user[0].role}</span>;
          }
        }
      }
    ]
  };

  render() {
    if (this.props.loading) {
      return <CircularProgress disableShrink />;
    }
    if (this.props.Role !== "Admin") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <h2>Your Role has no Access for this Page!</h2>
        </div>
      );
    }
    return (
      <>
        <MaterialTable
          id="Users"
          options={{
            padding: "default",
            pageSize: 10,
            search: true,
            headerStyle: {
              backgroundColor: "#01579b",
              color: "#FFF"
            },
            rowStyle: {
              backgroundColor: "#EEE"
            }
          }}
          title={"User List"}
          columns={this.state.columns}
          data={this.props.users}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit User",
              onClick: (event, rowData) =>
                this.props.history.push(`/admin/editUser/${rowData.id}`, {
                  userId: rowData.id
                })
            }
          ]}
          components={{
            Toolbar: () => (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    fontSize: 19,
                    fontWeight: "bold",
                    margin: "20px 0px 0px 20px"
                  }}
                >
                  User List
                </div>
                <div style={{ margin: "20px 20px 0px 0px" }}>
                  <PersonAddOutlinedIcon
                    color="primary"
                    onClick={() => this.props.history.push("/admin/addUser")}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            )
          }}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.users,
    Role: state.auth.Role
  };
};

export default connect(mapStateToProps, null)(UserList);
