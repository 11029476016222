import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";

export class Step1 extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.textInput = React.createRef();

    this.setTextInputRef = element => {
      this.textInput = element;
    };
  }
  state = {
    form: {},
    activeStep: 2,
    loading: true,
    mailValid: true
  };

  handleOnChange = value => {
    this.setState({ phone: value }, () => {});
  };

  handleChange = name => event => {
    let x = event.target.value;
    let isValidMali = this.checkEmail(
      this.myRef.current.children[1].firstChild.value
    );
    let isValidPhone = false;
    if (name === "ownerContactPhone" && x.length < 13) {
      isValidPhone = this.checkPhone(x);
    }
    // if (event.target.name === "ownerContactEmail") {
    //   isValidMali = this.checkEmail(this.myRef.value);
    // }

    if (isValidPhone) {
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          [name]: x
        }
      }));
    } else {
      if (name !== "ownerContactPhone") {
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            [name]: x,
            isValid: isValidMali
          }
        }));
      }
    }
  };

  componentDidMount() {
    let newform = this.props.form;
    this.setState({ form: newform, loading: false }, () => {
      let inputEl = document.querySelectorAll("input.MuiInputBase-input");
      //let inputEl = this.textInput.current.children[1].children[0];
      inputEl.forEach(el => {
        el.addEventListener("blur", event => {
          //console.log("STATE", this.state.form);
          this.props.addForm(this.state.form);
        });
      });
    });
    //Ref input
    //let inputEl = this.myRefInput.current.children[1].children[0];
  }

  componentWillUnmount() {
    let inputEl = document.querySelectorAll("input.MuiInputBase-input");
    //let inputEl = this.textInput.current.children[1].children[0];

    inputEl.forEach(el => {
      el.removeEventListener("blur", event => {});
    });
  }

  checkPhone = value => {
    const re = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;

    // if value is not blank, then test the regex
    if (value.length < 13) {
      if (re.test(value)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  checkEmail = e => {
    let valid = true;
    let str = e;
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(str)) {
      //this.setState({ mailValid: false }, () => {
      valid = false;
      // });
    } else {
      //  this.setState({ mailValid: true }, () => {
      valid = true;
      //   });
    }
    if (str === "") {
      valid = true;
      this.setState({ mailValid: true });
    }
    return valid;
  };

  render() {
    const { form } = this.state;
    if (this.state.loading) {
      return <h3>loading..</h3>;
    }
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <TextField
            placeholder="Enter Owner Firstname"
            label="Owner Firstname"
            onChange={this.handleChange("ownerName")}
            value={form.ownerName}
            margin="normal"
            required
            variant="outlined"
            style={{ width: "40%", backgroundColor: "white" }}
          />

          <TextField
            placeholder="Enter Owner Lastname"
            label="Owner Lastname"
            onChange={this.handleChange("ownerLastName")}
            value={form.ownerLastName}
            margin="normal"
            variant="outlined"
            style={{ width: "40%", backgroundColor: "white" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <TextField
            ref={this.myRef}
            placeholder="name@mail.com"
            label="Owner Email"
            onChange={this.handleChange("ownerContactEmail")}
            name={"ownerContactEmail"}
            value={form.ownerContactEmail}
            margin="normal"
            variant="outlined"
            error={
              form.ownerContactEmail
                ? !this.checkEmail(form.ownerContactEmail)
                : false
            }
            style={{
              width: "40%",
              backgroundColor: "white"
            }}
          />

          <TextField
            placeholder="Enter Owner Phone"
            label="Owner Phone"
            onChange={this.handleChange("ownerContactPhone")}
            value={form.ownerContactPhone}
            margin="normal"
            variant="outlined"
            style={{ width: "40%", backgroundColor: "white" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <TextField
            placeholder="Enter title"
            label="Title"
            onChange={this.handleChange("title")}
            value={form.title}
            margin="normal"
            required
            variant="outlined"
            style={{ width: "40%", backgroundColor: "white" }}
          />
          <TextField
            placeholder="Enter nickname"
            label="Nickname"
            variant="outlined"
            onChange={this.handleChange("nickname")}
            value={form.nickname}
            margin="normal"
            style={{ width: "40%", backgroundColor: "white" }}
            required
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <TextField
            placeholder="Enter accommodates"
            label="Accommodates"
            onChange={this.handleChange("accommodates")}
            value={form.accommodates}
            margin="normal"
            variant="outlined"
            type="number"
            style={{ width: "40%", backgroundColor: "white" }}
          />
          <TextField
            placeholder="Enter bedrooms"
            label="Bedrooms"
            onChange={this.handleChange("bedrooms")}
            value={form.bedrooms}
            margin="normal"
            variant="outlined"
            type="number"
            style={{ width: "40%", backgroundColor: "white" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <TextField
            placeholder="Enter beds"
            label="Beds"
            onChange={this.handleChange("beds")}
            value={form.beds}
            margin="normal"
            variant="outlined"
            type="number"
            style={{ width: "40%", backgroundColor: "white" }}
          />
          <TextField
            placeholder="Enter bathrooms"
            label="Bathrooms"
            onChange={this.handleChange("bathrooms")}
            value={form.bathrooms}
            margin="normal"
            required
            variant="outlined"
            type="number"
            style={{ width: "40%", backgroundColor: "white" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <TextField
            placeholder="Enter property type "
            label="Property Type"
            onChange={this.handleChange("propertyType")}
            value={form.propertyType}
            margin="normal"
            variant="outlined"
            style={{ width: "40%", backgroundColor: "white" }}
          />
          <TextField
            required
            placeholder="Enter room type"
            label="Room type"
            variant="outlined"
            onChange={this.handleChange("roomType")}
            value={form.roomType}
            margin="normal"
            style={{ width: "40%", backgroundColor: "white" }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}
        >
          <TextField
            placeholder="Enter area square feet "
            label="Square feet"
            onChange={this.handleChange("dimensions")}
            value={form.dimensions}
            margin="normal"
            type="number"
            variant="outlined"
            style={{ width: "40%", backgroundColor: "white" }}
          />
          <TextField
            placeholder="Enter description "
            label="Description "
            variant="outlined"
            onChange={this.handleChange("description")}
            value={form.description}
            margin="normal"
            style={{ width: "40%", backgroundColor: "white" }}
          />
        </div>
        {/* <Button color="primary" variant="contained" onClick={this.continue}>
          Add Property
        </Button> */}
      </div>
    );
  }
}

export default Step1;
