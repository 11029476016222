import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "90% !important",
    overflowY: "scroll"
  }
}));

const SimpleModal = props => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const propsData =
    props.bot === "Booking"
      ? props.contentBooking.slice(0, -1)
      : props.contentAirbnb.slice(0, -1);
  const propsSlicedData = propsData.slice(1);
  const newData = propsSlicedData.split(",");
  const propsData2 = props.contentGuesty.slice(0, -1);
  const propsSlicedData2 = propsData2.slice(1);
  const newData2 = propsSlicedData2.split(",");

  const handleClose = () => {
    props.toggleModal();
    // setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          {props.bot === "Booking" ? (
            <h2 id="simple-modal-title">Missing From Booking</h2>
          ) : (
            <h2 id="simple-modal-title">Missing From Airbnb</h2>
          )}
          {newData.map(item => {
            return (
              <p id="simple-modal-description" key={item}>
                {item}
              </p>
            );
          })}
          <h2 id="simple-modal-title">Missing From Guesty</h2>
          {newData2.map(item => {
            return (
              <p id="simple-modal-description" key={item}>
                {item}
              </p>
            );
          })}

          <Button variant="contained" color="primary" onClick={handleClose}>
            Ok
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default SimpleModal;
