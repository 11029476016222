import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Step1 from "./Step1-Info";
// import Step2 fro./Step - AmenitiesFeaturesep2";
import Step2 from "./Step2-Location";
import Step3 from "./Step3-Boarding";
import Step4 from "./Step4-Multimeda";
import Step5 from "./Step5-Offering";
import Step6 from "./Step6-Contract";
import Step7 from "./Step7-Publishing";
import StepArchive from "./Step-Archive";
import * as actions from "../store/actions/index";
import History from "../components/Villa/History";
import { connect } from "react-redux";
import { Prompt } from "react-router-dom";

const styles = theme => ({
  root: {
    width: "90%",
    margin: "auto"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

export class FormUserDetails extends Component {
  state = {
    activeStep: 0,
    villaId: 0,
    filePath: null,
    noteType: null,
    noteText: null,
    amount: null,
    villaStatus: null,
    notes: "",
    sendNewOffer: false,
    form: {
      ownerName: "",
      ownerLastName: "",
      ownerContactEmail: "",
      ownerContactPhone: "",
      nickname: "",
      title: "",
      description: "",
      accommodates: "",
      bedrooms: "",
      beds: "",
      bathrooms: "",
      propertyType: "",
      roomType: "",
      dimensions: "",
      amenities: [],
      logs: [],
      includedInPrice: [],
      nearbyAttractions: [],
      layoutAndFeatures: [],
      villaCategories: [],
      attachFinancialBindingModels: [],
      amenitiesNotIncluded: [],
      tags: [],
      pictures: [],
      address: {
        zoneId: 0,
        placeId: "",
        full: "",
        street: "",
        areaId: this.props.selectedArea ? this.props.selectedArea.id : 1,
        latlng: {
          lat: "",
          lng: ""
        }
      },
      onBoardingExtraData: {
        lastViewed: "",
        daysOccupied: "",
        averageDailyRate: "",
        expectedReturn: "",
        ownerDemand: "",
        nextMeeting: null,
        notes: "",
        finalNotes: "",
        indoorFeauturesText: "",
        outdoorFeauturesText: "",
        multimedia: null,
        onBoardingLayoutsAndFeatures: []
      },
      onBoardingMoreExtras: this.props.onBoardingMoreExtras,
      requiresPhotoShooting: false,
      photoShootingDateTime: null
    },
    loading: false,
    villaDetails: null,
    savePressed: false,
    saved: false,
    showSave: false,
    extraLoaded: false
  };

  componentDidMount() {
    this.addOnBoardingMoreExtras();

    let newForm = Object.assign({}, this.state.form);
    this.props.getVillaDetails(this.props.match.params.id).then(res => {
      this.setVilaDetails(newForm, res);
      this.setState({
        villaStatus: res.propertyState,
        villaDetails: res,
        form: newForm,
        loading: true,
        villaId: res.id
      });
    });
  }

  setVilaDetails = (newForm, villaDetails) => {
    newForm.villaId = villaDetails.id;
    newForm.pictures = villaDetails.pictures;
    newForm.onBoardingExtraData = villaDetails.onBoardingExtraData
      ? villaDetails.onBoardingExtraData
      : this.state.form.onBoardingExtraData;
    newForm.ownerName = villaDetails.owner.name;
    newForm.ownerLastName = villaDetails.owner.lastName;
    newForm.ownerContactEmail = villaDetails.owner.contactEmail;
    newForm.ownerContactPhone = villaDetails.owner.contactPhone;
    newForm.nickname = villaDetails.nickname;
    newForm.title = villaDetails.title;
    newForm.description = villaDetails.description;
    newForm.accommodates = villaDetails.accommodates;
    newForm.bedrooms = villaDetails.bedrooms;
    newForm.bathrooms = villaDetails.bathrooms;
    newForm.beds = villaDetails.beds;
    newForm.propertyType = villaDetails.propertyType;
    newForm.roomType = villaDetails.roomType;
    newForm.dimensions = villaDetails.dimensions;
    newForm.address = {
      zoneId: villaDetails.address.zoneId ? villaDetails.address.zoneId : null,
      placeId: villaDetails.address.placeId,
      full: villaDetails.address.full,
      street: villaDetails.address.street,
      areaId: this.props.selectedArea ? this.props.selectedArea.id : 1,
      latlng: villaDetails.address.latlng,
      zone: villaDetails.address.zone ? villaDetails.address.zone : null
    };
    newForm.pictures = villaDetails.pictures;
    newForm.requiresPhotoShooting = villaDetails.requiresPhotoShooting;
    newForm.photoShootingDateTime = villaDetails.photoShootingDateTime;
    newForm.form = villaDetails.form;
    this.props.getAmenities();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.villaStatus !== this.props.villaStatus) {
      this.setState({ villaStatus: this.props.villaStatus });
    }
    if (this.state.form !== prevState.form && prevState.loading) {
      this.setState({ showSave: true });
    }

    if (prevProps.onBoardingMoreExtras !== this.props.onBoardingMoreExtras) {
      this.addOnBoardingMoreExtras();
    }
  }

  pageScrollTop = () => {
    document.body.scrollTop = 0;
  };

  continue = e => {
    e.preventDefault();
  };

  scrollTop = () => {
    document.body.scrollTop = 0;
  };

  getSteps = () => {
    return [
      <span
        onClick={() => this.setState({ activeStep: 0 })}
        style={{ cursor: "pointer" }}
      >
        Fill in Info
      </span>,
      <span
        onClick={() => this.setState({ activeStep: 1 })}
        style={{ cursor: "pointer" }}
      >
        Location
      </span>,
      <span
        onClick={() => this.setState({ activeStep: 2 })}
        style={{ cursor: "pointer" }}
      >
        On boarding
      </span>,
      <span
        onClick={() => this.setState({ activeStep: 3 })}
        style={{ cursor: "pointer" }}
      >
        Multimedia
      </span>,
      <span
        onClick={() =>
          this.props.villaStatus === "prospect" ||
          this.props.villaStatus === "viewed" ||
          this.props.villaStatus === "offerSent" ||
          this.props.villaStatus === "offerAccepted" ||
          this.props.villaStatus === "offerRejected" ||
          this.props.villaStatus === "offerNegotiation"
            ? this.setState({ activeStep: 4 })
            : null
        }
        style={{
          cursor: "pointer"
        }}
      >
        Offering
      </span>,
      <span
        onClick={() =>
          this.props.villaStatus === "offerAccepted" ||
          this.props.villaStatus === "contractSent" ||
          this.props.villaStatus === "contractSigned" ||
          this.props.villaStatus === "contractNegotiation" ||
          this.props.villaStatus === "contractRejected"
            ? this.setState({ activeStep: 5 })
            : null
        }
        style={{ cursor: "pointer" }}
      >
        Contract
      </span>,
      <span
        onClick={() =>
          this.props.villaStatus === "contractSigned" ||
          this.props.villaStatus === "published"
            ? this.setState({ activeStep: 6 })
            : null
        }
        style={{ cursor: "pointer" }}
      >
        Publish
      </span>,
      <span
        onClick={() =>
          this.props.villaStatus === "archive"
            ? this.setState({ activeStep: 7 })
            : null
        }
        style={{ cursor: "pointer" }}
      >
        Archive
      </span>
    ];
  };
  fixnegogiate = () => {
    this.setState({ amount: null, noteType: null });
  };

  archiveVilla = () => {
    this.setState({ activeStep: 7 });
  };

  unarchiveVilla = () => {
    this.setState({ activeStep: 4, sendNewOffer: true });
  };

  addForm = form => {
    this.setState({ form, villaDetails: form });
  };

  addFilePath = (filePath, noteType, noteText) => {
    this.setState({ filePath, noteType, noteText });
  };

  clearFiles = () => {
    this.setState({ filePath: null, noteType: null, noteText: null });
  };

  addNotes = (noteText, noteType) => {
    this.setState({ noteText, noteType });
  };

  addAmountForOffer = amount => {
    this.setState({ amount });
  };

  addamenity = list => {
    this.setState({ list });
  };

  adinclussion = inclusionlist => {
    this.setState({ inclusionlist });
  };

  getStepContent = step => {
    if (this.state.loading) {
      switch (step) {
        case 0:
          return <Step1 form={this.state.form} addForm={this.addForm} />;
        case 1:
          return (
            <React.Fragment>
              <Step2
                form={this.state.form}
                addForm={this.addForm}
                amenities={[]}
                inclusions={[]}
                address={this.props.address}
                selectedArea={this.props.selectedArea}
              />
            </React.Fragment>
            // <React.Fragment>
            //   <Step2
            //     title="Amenities"
            //     categories={this.props.AmenitiesCategories}
            //     data={this.props.villaAmenities}
            //     allAmenities={this.props.allAmenities}
            //     form={this.state.form}
            //     addForm={this.addForm}
            //     addamenity={this.addamenity}
            //     edit
            //   />
            //   <Step2
            //     title="Included In the Price"
            //     categories={["Included in the Price"]}
            //     data={this.props.includes}
            //     inclusions
            //     form={this.state.form}
            //     addForm={this.addForm}
            //     adinclussion={this.adinclussion}
            //   />

            //   <Step2
            //     title="Nearby Attractions"
            //     //categories={["Nearby Attractions"]}
            //   />

            //   <Step2 title="Layout and Features" />
            // </React.Fragment>
          );
        case 2:
          return (
            <Step3 form={this.state.form} addForm={this.addForm} editVilla />
          );
        case 3:
          return (
            <React.Fragment>
              <Step4
                form={this.state.form}
                addForm={this.addForm}
                villaId={this.state.villaId}
              />
            </React.Fragment>
          );
        case 4:
          return (
            <Step5
              form={this.state.form}
              addForm={this.addForm}
              addFilePath={this.addFilePath}
              addAmountForOffer={this.addAmountForOffer}
              addNotes={this.addNotes}
              villaId={this.state.villaId}
              handleNext={this.handleNext}
              clearFiles={this.clearFiles}
              archiveVilla={this.archiveVilla}
              sendNewOffer={this.state.sendNewOffer}
              fixnegogiate={this.fixnegogiate}
            />
          );
        case 5:
          return (
            <Step6
              form={this.state.form}
              addForm={this.addForm}
              addFilePath={this.addFilePath}
              addAmountForOffer={this.addAmountForOffer}
              addNotes={this.addNotes}
              villaId={this.state.villaId}
              handleNext={this.handleNext}
              clearFiles={this.clearFiles}
              archiveVilla={this.archiveVilla}
            />
          );
        case 6:
          return <Step7 />;
        case 7:
          return <StepArchive unarchiveVilla={this.unarchiveVilla} />;
        default:
          return "Unknown step";
      }
    }
  };

  handleOffer = () => {
    const { filePath, noteType, noteText, amount } = this.state;
    if (
      this.props.villaStatus === "viewed" ||
      this.props.villaStatus === "offerNegotiation"
    ) {
      if (amount !== null) {
        if (noteType !== null) {
          this.props
            .updateStatus(
              this.state.villaId,
              "offerSent",
              amount,
              noteType,
              noteText,
              filePath
            )
            .then(() => {
              this.props.getVillaDetails(this.state.villaId);
              this.clearFiles();
            });
        } else {
          this.props
            .updateStatus(this.state.villaId, "offerSent", amount)
            .then(() =>
              this.props
                .getVillaDetails(this.state.villaId)
                .then(res => this.setState({ villaDetails: res }))
            );
        }
        this.setState({ sendNewOffer: false });
      } else {
        this.props.openAlert("", "Please type amount");
      }
    } else if (this.props.villaStatus === "offerRejected") {
      this.props.openAlert(
        "",
        "Please negotiate with owner, or archive the property"
      );
    }
  };

  handleContract = () => {
    const { filePath, noteType, noteText, amount } = this.state;
    if (
      this.props.villaStatus === "offerAccepted" ||
      this.props.villaStatus === "contractNegotiation"
    ) {
      if (noteType !== null) {
        this.props
          .updateStatus(
            this.state.villaId,
            "contractSent",
            amount,
            noteType,
            noteText,
            filePath
          )
          .then(() => {
            this.props
              .getVillaDetails(this.state.villaId)
              .then(res => this.setState({ villaDetails: res }));
            this.clearFiles();
          });
      } else {
        this.props.openAlert("", "Please upload the contract and add Notes");
      }
    } else if (this.props.villaStatus === "contractRejected") {
      this.props.openAlert(
        "",
        "Please negotiate with owner, or archive the property"
      );
    }
  };
  validateFields = () => {
    if (
      this.state.form.ownerName === "" ||
      this.state.form.title === "" ||
      this.state.form.bathrooms === "" ||
      this.state.form.roomType === "" ||
      this.state.form.nickname === "" ||
      this.state.form.isValid === false
    ) {
      this.props.openAlert("", "Please fill in all the required fields");
    } else {
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));
    }
  };

  handleNext = () => {
    // const steps = this.getSteps();
    this.pageScrollTop();
    const { activeStep } = this.state;

    if (activeStep === 3) {
      this.props.editVilla(this.state.form, this.state.villaId).then(res => {
        this.props

          .getVillaDetails(res.id)
          .then(res => this.setState({ villaDetails: res }));
      });

      if (this.state.form.pictures.length > 0) {
        this.props.setPropertyImages(
          this.state.form.pictures,
          this.state.villaId
        );
      }

      if (this.props.villaStatus === "prospect") {
        this.props
          .updateStatus(this.state.villaId, "viewed")
          .then(() => this.props.getVillaDetails(this.state.villaId));
      }
    }

    if (activeStep === 0) {
      this.validateFields();
    } else {
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));
    }
  };
  handleSave = () => {
    this.props
      .editVilla(this.state.form, this.state.villaId)
      .then(res => {
        if (!res.error) {
          this.props.getVillaDetails(res.id);
          setTimeout(() => {
            this.setState({ savePressed: false, saved: true }, () => {
              this.props.history.push("/admin/table");
            });
          }, 1000);
        } else {
          this.props.openAlert("", "Please fill in all the required fields");
          this.setState({ savePressed: false });
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  handleBack = () => {
    this.pageScrollTop();

    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  handleChange = name => event => {
    this.setState({ errors: false, [name]: event.target.value });
  };
  addOnBoardingMoreExtras = () => {
    this.setState(
      state => ({
        ...state,
        form: {
          ...state.form,
          onBoardingMoreExtras: this.props.onBoardingMoreExtras,
          onBoardingExtraData: {
            ...state.form.onBoardingExtraData,
            onBoardingLayoutsAndFeatures: this.props
              .onBoardingMoreExtrasOptimized
          }
        },
        extraLoaded: true
      }),
      () => {
        if (this.state.extraLoaded) {
          this.addForm(this.state.form);
        }
      }
    );
  };

  render() {
    const steps = this.getSteps();
    const { activeStep } = this.state;
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Prompt
          when={
            this.props.villaDetails !== this.state.villaDetails &&
            !this.state.saved
          }
          message="You have unsaved changes, are you sure you want to leave?"
        />
        <div className={classes.root}>
          {this.props.villaStatus ? (
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div>
                <span>Name: </span>
                <span
                  style={{ fontWeight: "bold", textTransform: "capitalize" }}
                >
                  {this.props.villaName}
                </span>
              </div>
              <div>
                <span>Status: </span>
                <span
                  style={{ fontWeight: "bold", textTransform: "capitalize" }}
                >
                  {this.props.villaStatus}
                </span>
              </div>
            </div>
          ) : null}
          <Stepper
            style={{ marginBottom: "1rem" }}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {this.state.activeStep === steps.length &&
            this.state.villaStatus === "offerSent" &&
            activeStep === 4 ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
                <Button onClick={this.handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                {this.state.loading ? this.getStepContent(activeStep) : null}

                <div
                  style={{
                    justifyContent: "flex-end",
                    marginTop: 40,
                    marginBottom: 40,
                    display:
                      this.state.villaStatus === "offerSent" && activeStep === 4
                        ? "none"
                        : "flex"
                  }}
                >
                  <Button
                    variant="contained"
                    // color="green"
                    style={{
                      marginRight: 30,
                      backgroundColor: "green",
                      color: "white"
                    }}
                    onClick={() => {
                      this.setState({ savePressed: true }, () =>
                        this.handleSave()
                      );
                    }}
                  >
                    {this.state.savePressed ? (
                      <CircularProgress style={{ color: "white" }} size={24} />
                    ) : (
                      "Save & Exit"
                    )}
                  </Button>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.backButton}
                    style={{ marginRight: 30 }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={
                      activeStep < 4
                        ? this.handleNext
                        : activeStep === 4
                        ? this.handleOffer
                        : activeStep === 5
                        ? this.handleContract
                        : null
                    }
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            )}
          </div>
          {this.props.villaLogs !== undefined ? (
            this.props.villaLogs.length > 0 ? (
              <History logs={this.props.villaLogs} />
            ) : null
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    villaAmenities: state.villas.villaDetails.amenities,
    allAmenities: state.settings.Amenities,
    AmenitiesCategories: state.settings.AmenitiesCategories,
    includes: state.villas.villaDetails.includedInPriceItems,
    address: state.villas.villaDetails.address,
    villaStatus: state.villas.villaDetails.propertyState,
    villaLogs: state.villas.villaDetails.logs,
    villaName: state.villas.villaDetails.nickname,
    villaDetails: state.villas.villaDetails,
    selectedArea: state.area.selected,

    onBoardingMoreExtras: state.villas.onBoardingMoreExtras,
    onBoardingMoreExtrasOptimized: state.villas.onBoardingMoreExtrasOptimized
  };
};

const mapDispatchToStore = dispatch => {
  return {
    openAlert: (title, text) => dispatch(actions.openAlert(title, text)),
    getAmenities: () => dispatch(actions.getAmenities()),
    AddVilla: form => dispatch(actions.AddVilla(form)),
    getVillas: () => dispatch(actions.getVillas()),
    getVillaDetails: id => dispatch(actions.getVillaDetails(id)),
    editVilla: (form, id) => dispatch(actions.editVilla(form, id)),
    updateStatus: (id, newStatus, amount, noteType, noteText, contentUrl) =>
      dispatch(
        actions.updateVillaStatus(
          id,
          newStatus,
          amount,
          noteType,
          noteText,
          contentUrl
        )
      ),
    setPropertyImages: (images, id) =>
      dispatch(actions.setPropertyImages(images, id))
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToStore)(FormUserDetails)
);
