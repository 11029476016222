import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { getEnvironment } from "../config/config";
import queryString from "query-string";
import { connect } from "react-redux";
import * as actions from "../store/actions/index";

const styles = theme => ({
  container: {
    padding: 30,
    borderRadius: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
});

class ForgotPassword extends Component {
  state = {
    password: "",
    confirmPassword: "",
    token: "",
    userId: ""
  };

  componentDidMount() {
    const token = queryString.parse(this.props.location.search).token;
    const userId = queryString.parse(this.props.location.search).id;
    this.setState({ token, userId });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  resetPassword = () => {
    const { password, confirmPassword, token, userId } = this.state;
    if (password === confirmPassword) {
      axios
        .post(getEnvironment().apiUrl + "/Account/ResetPassword", {
          token,
          userId,
          password,
          confirmPassword
        })
        .then(() => this.props.history.push("/login"))
        .catch(error => this.props.openAlert("", error));
    } else {
      this.props.openAlert("", `Passwords doesn't match!`);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height: "100vh",
            background:
              "url(https://images.unsplash.com/photo-1417632993443-302f4897cf67?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80)"
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              width: 400,
              height: 350
            }}
          >
            <Card style={{ width: "100%", height: "100%" }}>
              <CardContent style={{ height: "100%" }}>
                <div
                  className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <Typography align="center" variant="h5">
                    Set Password
                  </Typography>
                  <TextField
                    type="password"
                    label="Password"
                    value={this.state.password}
                    name="password"
                    onChange={this.handleChange}
                    margin="normal"
                  />
                  <TextField
                    type="password"
                    label="Confirm Password"
                    value={this.state.confirmPassword}
                    name="confirmPassword"
                    onChange={this.handleChange}
                    margin="normal"
                  />
                  <div
                    style={{
                      marginTop: 40
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.resetPassword}
                      style={{ width: "100%" }}
                      disabled={
                        this.state.password !== this.state.confirmPassword
                      }
                    >
                      Set Password
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {};
};

const mapDispatchToStore = dispatch => {
  return {
    openAlert: (title, text) => dispatch(actions.openAlert(title, text))
  };
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToStore)(ForgotPassword)
);
