import React, { Component } from "react";

export default class OwnerDashboard extends Component {
  render() {
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: 1024,
          minHeight: "80vh"
        }}
      >
        <div>
          <h3>Owner Area</h3>
        </div>
      </div>
    );
  }
}
