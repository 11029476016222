import * as actionTypes from "../actions/actionTypes";

const initialState = {
  auth: "",
  loading: false,
  error: false,
  Role: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_LOGIN_START:
      return {
        ...state,
        loading: true
      };

    case actionTypes.AUTH_SET_ROLE:
      return {
        Role: action.Role
      };
    case actionTypes.AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        auth: action.auth,
        loading: false
      };
    case actionTypes.AUTH_LOGIN_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default reducer;
