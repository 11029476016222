import * as actionTypes from "./actionTypes";
import axios from "axios";
import { headers } from "../../config/config";
import { getEnvironment } from "../../config/config";
let users = localStorage.getItem("user");
let user = JSON.parse(users);
export const authLoginStart = () => {
  return {
    type: actionTypes.AUTH_LOGIN_START
  };
};

export const authLoginSuccess = auth => {
  return {
    type: actionTypes.AUTH_LOGIN_SUCCESS,
    auth
  };
};

export const authLoginFail = error => {
  return {
    type: actionTypes.AUTH_LOGIN_FAIL,
    error
  };
};

export const authLogin = (username, password) => {
  return dispatch => {
    dispatch(authLoginStart());
    axios
      .post(
        getEnvironment().authUrl + `/token`,

        {
          email: username,
          password: password
        },
        {
          headers: headers()
        }
      )
      .then(response => {
        localStorage.setItem("id", JSON.stringify(response.data.id));
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("auth", response.data.token);
        dispatch(authLoginSuccess(response.data));
      })
      .catch(error => {
        dispatch(authLoginFail(error.response.data));
      });
  };
};

export const authRefreshToken = () => {
  return dispatch => {
    axios
      .put(
        getEnvironment().authUrl + `/refreshToken`,

        {
          bearerToken: user.token,
          refreshToken: user.refreshToken
        },
        {
          headers: headers()
        }
      )
      .then(response => {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("auth", response.data.token);
        dispatch(authLoginSuccess(response.data));
      })
      .catch(error => {
        dispatch(authLoginFail(error.response));
      });
  };
};

export const setHighestRole = Role => {
  return {
    type: actionTypes.AUTH_SET_ROLE,
    Role
  };
};
