import * as actionTypes from "./actionTypes";

export const getClientsStart = () => {
  return {
    type: actionTypes.GET_CLIENTS_START
  };
};

export const getClientsSuccess = clients => {
  return {
    type: actionTypes.GET_CLIENTS_SUCCESS,
    clients
  };
};

export const getClientsFail = error => {
  return {
    type: actionTypes.GET_CLIENTS_FAIL,
    error
  };
};

export const getClients = () => {
  return dispatch => {
    dispatch(getClientsStart());
    dispatch(getClientsSuccess());
    // dispatch(getClientsFail())
  };
};
