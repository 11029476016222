import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";
import * as actions from "../store/actions/index";
import { connect } from "react-redux";
import update from "immutability-helper";
import HomeIcon from "@material-ui/icons/Home";

class AddUser extends Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    userAdded: false,
    isRoleOpen: false,
    displayInvite: false,
    roles: []
  };

  componentDidMount() {
    this.props.getRoles().then(roles => this.setState({ roles }));
  }

  handleChanges = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  clearState = () => {
    this.setState({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      isPasswordInvalid: false
    });
  };

  addUser = () => {
    const {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      roles
    } = this.state;
    if (password === confirmPassword) {
      this.setState({ isPasswordInvalid: false });
      this.props
        .addUser(email, password, firstName, lastName, roles)
        .then(res => {
          this.props.getUsers();
          this.clearState();
          this.setState(
            res.active === true ? { userAdded: true } : { userAdded: false },
            () =>
              !this.state.userAdded
                ? this.props.openAlert("", "Error in adding user")
                : null
          );
        });
    } else {
      this.setState({ isPasswordInvalid: true });
    }
  };

  handleRoles = (e, index) => {
    const value = e.target.checked;
    const name = e.target.name;
    this.setState(
      update(this.state, {
        roles: {
          [index]: {
            role: {
              $set: name
            },
            on: {
              $set: value
            }
          }
        }
      })
    );
  };

  toggleRoleSelect = () => {
    this.setState({ isRoleOpen: !this.state.isRoleOpen });
  };

  inviteUser = () => {
    const { firstName, lastName, email, roles } = this.state;
    const callBackUrl = window.location.origin + "/reset-password";
    this.props
      .inviteUser(firstName, lastName, email, roles, callBackUrl)
      .then(res =>
        this.setState(
          res.active === true ? { userAdded: true } : { userAdded: false },
          () =>
            !this.state.userAdded
              ? this.props.openAlert("", "Error in adding user")
              : null
        )
      );
  };

  toggleInviteUser = () => {
    this.setState({ displayInvite: !this.state.displayInvite });
  };

  render() {
    return !this.state.userAdded ? (
      <>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.toggleInviteUser}
          >
            {!this.state.displayInvite ? "Invite User" : "Add user"}
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <TextField
            placeholder="Type your name"
            label="First Name"
            onChange={this.handleChanges}
            value={this.state.firstName}
            name={"firstName"}
            margin="normal"
            required
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
          />
          <TextField
            placeholder="Type your last name"
            label="Last Name"
            onChange={this.handleChanges}
            value={this.state.lastName}
            name={"lastName"}
            margin="normal"
            required
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
          />

          <TextField
            placeholder="Type your email"
            label="Email"
            onChange={this.handleChanges}
            value={this.state.email}
            name={"email"}
            margin="normal"
            required
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
          />
          {!this.state.displayInvite ? (
            <>
              <TextField
                placeholder="Type your password"
                label="Password"
                onChange={this.handleChanges}
                value={this.state.password}
                name={"password"}
                margin="normal"
                required
                variant="outlined"
                style={{
                  width: "30%",
                  backgroundColor: "white"
                }}
              />
              <TextField
                placeholder="Confirm your password"
                label="Confirm Password"
                onChange={this.handleChanges}
                value={this.state.confirmPassword}
                name={"confirmPassword"}
                margin="normal"
                required
                variant="outlined"
                style={{
                  width: "30%",
                  backgroundColor: "white"
                }}
                error={this.state.isPasswordInvalid}
              />
            </>
          ) : null}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "29%",
              marginBottom: 25
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>Role: </div>
            <FormControl style={{ minWidth: 120 }}>
              <InputLabel>Roles</InputLabel>
              <Select
                value=""
                open={this.state.isRoleOpen}
                onOpen={this.toggleRoleSelect}
                onClose={this.toggleRoleSelect}
              >
                {this.state.roles.map((user, index) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 10
                      }}
                    >
                      {user.role}
                    </div>
                    <MenuItem key={index} value={user.role}>
                      <Switch
                        checked={user.on}
                        color="primary"
                        name={user.role}
                        onChange={e => this.handleRoles(e, index)}
                      />
                    </MenuItem>
                  </div>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            style={{
              marginTop: 50
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={
                this.state.displayInvite ? this.inviteUser : this.addUser
              }
            >
              Add User
            </Button>
          </div>
        </div>
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 300,
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <CheckIcon color="primary" />
          <div style={{ marginLeft: 10, marginBottom: 20 }}>
            User added successfully
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={e => {
              e.preventDefault();
              this.props.history.push("/");
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div>Go back </div>
              <div
                style={{
                  paddingTop: 2,
                  marginLeft: 5
                }}
              >
                <HomeIcon />
              </div>
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToStore = dispatch => {
  return {
    openAlert: (title, text) => dispatch(actions.openAlert(title, text)),

    addUser: (email, password, firstName, lastName, roles) =>
      dispatch(actions.addUser(email, password, firstName, lastName, roles)),
    inviteUser: (firstName, lastName, email, roles, callBackUrl) =>
      dispatch(
        actions.inviteUser(firstName, lastName, email, roles, callBackUrl)
      ),
    getRoles: () => dispatch(actions.getRoles()),
    getUsers: () => dispatch(actions.getUsers())
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(AddUser);
