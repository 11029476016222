import React, { Component, Fragment } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import geocoding from "reverse-geocoding";
import mapStyles from "../components/Map/MapStyles";
import GoogleMapReact from "google-map-react";
import LatLng from "google-map-react/lib/utils/lib_geo/lat_lng";
import ZonesDropDown from "../components/FilterBar/ZonesDropDown";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import update from "immutability-helper";
import * as actions from "../store/actions/index";
import { connect } from "react-redux";

let markers = [];
const googleApi = {
  key: "AIzaSyCTyrsUuye8zfoBd9Z6qpfnFJmQFC1-U6Q"
};

class Step4 extends Component {
  state = {
    center: this.props.selectedArea.area
      ? this.props.selectedArea.area.centerCoords
        ? this.props.selectedArea.area.centerCoords
        : {
            lat: 37.45529,
            lng: 25.3672
          }
      : {
          lat: 37.45529,
          lng: 25.3672
        },
    zoom: 13,
    name: "",
    address: this.props.form.address ? this.props.form.address.full : null,
    placeid: "",
    lat: 37.45529,
    lng: 25.3672,
    loading: false,
    isDisabled: true,
    mapLoaded: false,
    pickCoordinatesFromGeocoder: [],
    pickCoordinates: [],
    pickActive: false,
    form: {},
    map: null
  };

  handleAddressChange = e => {
    this.setState({
      address: e,
      isDisabled: false
    });
  };
  componentDidMount() {
    this.setState({ form: this.props.form });
  }

  componentDidUpdate(prevProps) {
    let areaCoords = this.props.selectedArea.area
      ? this.props.selectedArea.area.centerCoords
      : "";
    if (!areaCoords) {
      areaCoords = {
        lat: 37.45529,
        lng: 25.3672
      };
    }
    this.props.selectedArea !== prevProps.selectedArea &&
      this.setState({ center: areaCoords }, () => {
        this.newmap.setCenter(areaCoords);
      });
  }

  getPlaceStart = () => {
    if (this.props.address) {
      let config = {
        latitude: this.props.address.latlng.lat,
        longitude: this.props.address.latlng.lng,
        key: googleApi.key
      };
      geocoding(config, (error, data) => {
        if (error) {
        } else {
          let latlng = new LatLng(
            this.props.address.latlng.lat !== 0
              ? this.props.address.latlng.lat
              : this.state.lat,
            this.props.address.latlng.lng !== 0
              ? this.props.address.latlng.lng
              : this.state.lng
          );
          let addressName = this.props.address.full;
          if (data.status === "OK") {
            this.removeByKey(markers, "pick");
            if (data.results[0]) {
              this.addMarker(latlng, addressName, "pick");
              if (markers) {
                for (let i in markers) {
                  markers[i].setMap(this.newmap);
                }
              }
            }
          }
        }
      });
    }
  };

  addMarker = (location, address) => {
    let pinImage = new this.newmaps.MarkerImage(
      "https://www.google.com/maps/vt/icon/name=assets/icons/poi/tactile/pinlet_shadow-2-medium.png,assets/icons/poi/tactile/pinlet_outline_v2-2-medium.png,assets/icons/poi/tactile/pinlet-2-medium.png,assets/icons/poi/quantum/pinlet/dot_pinlet-2-medium.png&highlight=ff000000,ffffff,ea4335,ffffff&color=ff000000?scale=1"
    );
    let markerType = "pick";

    let infowindow = new this.newmaps.InfoWindow({
      content: address
    });
    let marker = new this.newmaps.Marker({
      draggable: true,
      clickable: true,
      position: location,
      map: this.newmap,
      icon: pinImage,
      markerType: markerType
    });
    this.newmap.panTo(marker.getPosition());
    if (marker != null) {
      infowindow.open(marker.get(this.newmaps), marker);
    }
    marker.addListener("click", () => {
      this.newmap.panTo(marker.getPosition());
      infowindow.open(marker.get(this.newmaps), marker);
    });
    marker.addListener("dragend", () => {
      this.setState(
        {
          pickCoordinates: [
            marker.getPosition().lat(),
            marker.getPosition().lng()
          ]
        },
        () => {
          this.getPlaceDragging("pick");
        }
      );
    });
    marker.addListener("mousedown", () => {
      marker.setDraggable(true);
    });
    markers.push(marker);
  };

  getPlaceDragging = () => {
    const { pickCoordinates } = this.state;
    let config = {
      latitude: pickCoordinates[0],
      longitude: pickCoordinates[1],
      key: googleApi.key
    };
    geocoding(config, (error, data) => {
      if (error) {
        this.props.openAlert("", error);
      } else {
        let latlng = new LatLng(pickCoordinates[0], pickCoordinates[1]);
        if (data.status === "OK") {
          this.removeByKey(markers, "pick");
          if (data.results[0]) {
            this.addMarker(latlng, data.results[0].formatted_address, "pick");
            let newaddress = this.state.form.address;
            newaddress.full = data.results[0].formatted_address;
            newaddress.latlng = {
              lat: pickCoordinates[0],
              lng: pickCoordinates[1]
            };
            newaddress.placeId = data.results[0].place_id;
            newaddress.street = data.results[0].formatted_address;
            newaddress.areaId = this.props.selectedArea.id;

            this.setState(
              prevState => ({
                form: {
                  ...prevState.form,
                  address: newaddress
                }
              }),
              () => {
                this.props.addForm(this.state.form);
              }
            );
            this.setState(
              {
                address: data.results[0].formatted_address,
                placeid: data.results[0].place_id,
                lat: pickCoordinates[0],
                lng: pickCoordinates[1]
              },
              () => {
                this.forceUpdate();
              }
            );
            //  this.newmap.setZoom(17);
            if (markers) {
              for (let i in markers) {
                markers[i].setMap(this.newmap);
              }
            }
          }
        }
      }
    });
  };

  removeByKey = (array, params) => {
    for (let i = array.length - 1; i >= 0; --i) {
      if (array[i].markerType === params) {
        let marker = array[i];
        marker.setMap(null);
        array.splice(i, 1);
      }
    }
    return array;
  };

  onMapClick = () => {
    this.forceUpdate();
    const { pickActive } = this.state;
    this.removeByKey(markers, "drop");
    this.newmaps.event.addListenerOnce(this.newmap, "click", event => {
      this.setState(
        {
          pickCoordinatesFromGeocoder: [event.latLng.lat(), event.latLng.lng()],
          pickActive: !pickActive
        },
        () => this.getPlace()
      );
    });
  };

  getPlace = () => {
    const { pickCoordinatesFromGeocoder } = this.state;
    let config = {
      latitude: pickCoordinatesFromGeocoder[0],
      longitude: pickCoordinatesFromGeocoder[1],
      key: googleApi.key
    };
    geocoding(config, (error, data) => {
      if (error) {
        this.props.openAlert(
          "",
          `<p>Could not fetch address or coordinates!</p><p>Please click pin and select again the location on the map.</p><p>Error Type: ${error}</p>`
        );
      } else {
        this.setState({ address: data.results[0].formatted_address });
        let latlng = new LatLng(
          pickCoordinatesFromGeocoder[0],
          pickCoordinatesFromGeocoder[1]
        );
        if (data.status === "OK") {
          this.removeByKey(markers, "pick");
          if (data.results[0]) {
            this.addMarker(latlng, data.results[0].formatted_address, "pick");
            let newaddress = this.state.form.address;
            newaddress.full = data.results[0].formatted_address;
            newaddress.latlng = {
              lat: pickCoordinatesFromGeocoder[0],
              lng: pickCoordinatesFromGeocoder[1]
            };
            newaddress.placeId = data.results[0].place_id;
            newaddress.street = data.results[0].formatted_address;
            newaddress.areaId = this.props.selectedArea.id;
            this.setState(
              prevState => ({
                form: {
                  ...prevState.form,
                  address: newaddress
                }
              }),
              () => {
                this.props.addForm(this.state.form);
              }
            );
            this.setState(
              {
                address: data.results[0].formatted_address,
                placeid: data.results[0].place_id,
                lat: pickCoordinatesFromGeocoder[0],
                lng: pickCoordinatesFromGeocoder[1]
              },
              () => {
                this.forceUpdate();
              }
            );
            if (markers) {
              for (let i in markers) {
                markers[i].setMap(this.newmap);
              }
            }
          }
        }
      }
    });
  };

  handleClosePickUpClick = () => {
    this.removeByKey(markers, "pick");
    this.setState({
      address: "",
      lat: 0,
      lng: 0
    });
  };

  createMapOptions = () => {
    return {
      styles: mapStyles,
      gestureHandling: "greedy"
    };
  };

  apiIsLoaded = (map, maps) => {
    const CustomSatelliteButton = (controlDiv, map) => {
      let isSAtelite = false;

      var controlUI = document.createElement("div");
      controlUI.classList.add("satellite-map");
      controlUI.classList.add("fa");
      controlUI.classList.add("fa-map-o");
      controlDiv.appendChild(controlUI);
      // Setup the click event listeners: simply set the map to Satellite.
      controlUI.addEventListener("click", function() {
        if (!isSAtelite) {
          controlUI.classList.add("fa-globe");
          controlUI.classList.remove("fa-map-o");
          map.setMapTypeId("hybrid");
          isSAtelite = true;
        } else {
          controlUI.classList.add("fa-map-o");
          controlUI.classList.remove("fa-globe");
          isSAtelite = false;
          map.setMapTypeId("roadmap");
        }
      });
    };
    if (map) {
      let SatelliteButtonDiv = document.createElement("div");
      CustomSatelliteButton(SatelliteButtonDiv, map);

      SatelliteButtonDiv.index = 1;
      map.controls[maps.ControlPosition.TOP_LEFT].push(SatelliteButtonDiv);
      this.newmap = map;
      this.newmaps = maps;
      this.getPlaceStart();
    }
  };

  handleSelectPickUp = address => {
    geocodeByAddress(address)
      .then(results => {
        let newaddress = this.state.form.address;
        newaddress.full = results[0].formatted_address;
        newaddress.latlng = {
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        };
        newaddress.placeId = results[0].place_id;
        newaddress.street = results[0].formatted_address;
        newaddress.areaId = this.props.selectedArea.id;
        this.setState(
          prevState => ({
            form: {
              ...prevState.form,
              address: newaddress
            }
          }),
          () => {
            this.props.addForm(this.state.form);
          }
        );
        this.setState({
          address: results[0].formatted_address,
          placeid: results[0].place_id,
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng()
        });
        let latLng = new LatLng(
          results[0].geometry.location.lat(),
          results[0].geometry.location.lng()
        );
        this.addMarker(latLng, results[0].formatted_address);
        this.newmap.setCenter(latLng);
        // this.newmap.setZoom(17);
        this.setState({
          pickCoordinatesFromGeocoder: [
            results[0].geometry.location.lat(),
            results[0].geometry.location.lng()
          ],
          address: results[0].formatted_address,
          placeid: results[0].place_id
        });
        return getLatLng(results[0]);
      })
      .catch(error => console.error("Error", error));
  };

  handleZone = zone => {
    if (this.state.form.address) {
      this.setState(
        update(this.state, {
          form: {
            address: {
              zoneId: {
                $set: zone.id === 999 ? "" : zone.id
              },
              zone: {
                $set: zone.id === 999 ? null : zone
              }
            }
          }
        }),
        () => this.props.addForm(this.state.form)
      );
    }
  };

  handleScrollIn = e => {
    let el = document.querySelector(".makeStyles-mainPanel-2");
    if (el) {
      el.style.maxHeight = "initial";
    }
  };
  handleScrollOut = e => {
    let el = document.querySelector(".makeStyles-mainPanel-2");
    if (el) {
      el.style.maxHeight = "100";
    }
  };

  render() {
    return (
      <Fragment>
        <div style={{ marginTop: 30 }}>
          <Grid className={"map-options"} container>
            <Grid item>
              {this.state.form.address !== undefined ? (
                <ZonesDropDown
                  selected={this.state.form}
                  zone={this.handleZone}
                />
              ) : null}
            </Grid>
            <Grid item>
              {this.state.mapLoaded ? (
                <PlacesAutocomplete
                  value={this.state.address}
                  onChange={this.handleAddressChange}
                  onSelect={this.handleSelectPickUp}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className={"autocomplete-search-bar-container"}>
                      <div className={"autocomplete-search-input-container"}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <input
                            type="text"
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "location-search-input"
                            })}
                            // onChange={() => {}}
                          />

                          {this.state.address && (
                            <div>
                              <div
                                name={"close"}
                                className={"button-close"}
                                onClick={this.handleClosePickUpClick.bind(this)}
                              />
                            </div>
                          )}
                        </div>
                        <div className="autocomplete-dropdown-container">
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? "autocomplete-suggestion-item--active"
                              : "autocomplete-suggestion-item";
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              ) : null}
            </Grid>
            <Grid item>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    this.setState(
                      {
                        pickActive: true
                      },
                      () => this.onMapClick("pick")
                    )
                  }
                  style={{ margin: "auto", cursor: "pointer" }}
                >
                  Add Marker
                </Button>
              </div>
            </Grid>
          </Grid>
          <div
            style={{
              height: 450,
              marginTop: 30,
              marginBottom: 30,
              display: this.state.map ? "block" : "none"
            }}
            className={"col-12"}
            // onMouseEnter={e => this.handleScrollIn(e)}
            // onMouseLeave={e => this.handleScrollOut(e)}
          >
            <GoogleMapReact
              ref={ref => (this.map = ref)}
              bootstrapURLKeys={{
                key: googleApi.key,
                libraries: "places,visualization,drawing,geometry"
              }}
              defaultCenter={this.state.center}
              defaultZoom={this.state.zoom}
              options={this.createMapOptions}
              resetBoundsOnResize={true}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => {
                this.setState(
                  {
                    map: map,
                    maps: maps,
                    mapLoaded: true
                  },
                  () => {
                    this.apiIsLoaded(map, maps);
                  }
                );
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToStore = dispatch => {
  return {
    openAlert: (title, text) => dispatch(actions.openAlert(title, text))
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(Step4);
