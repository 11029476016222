import React from "react";
import * as actions from "../../store/actions/index";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";

const AlertDialog = props => {
  const { isOpen, text, title, closeAlert } = props;
  const handleClose = () => {
    closeAlert();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isOpen: state.alert.open,
    title: state.alert.title,
    text: state.alert.text
  };
};

const mapDispatchToStore = dispatch => {
  return {
    closeAlert: () => dispatch(actions.closeAlert())
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(AlertDialog);
