import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Step1 from "../Step1-Info";
import Step2 from "../Step2-Location";
import Step3 from "../Step3-Boarding";
import Step4 from "../Step4-Multimeda";
import { connect } from "react-redux";

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center"
  },
  active: {
    color: "#784af4"
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor"
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18
  }
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
    }
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
    }
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1
  }
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center"
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)"
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)"
  }
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <GroupAddIcon />,
    2: <VideoLabelIcon />,
    3: <SettingsIcon />
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};
const styles = theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});
class OwnerAdd extends React.Component {
  getSteps() {
    return ["Your Information", "Your Property", "Last Details"];
  }

  getStepContent(step) {
    switch (step) {
      case 0:
        return <Step1 form={this.state.form} addForm={this.addForm} />;
      case 1:
        return (
          <Step2
            form={this.state.form}
            addForm={this.addForm}
            selectedArea={this.props.selectedArea}
          />
        );
      case 2:
        return (
          <Step3
            form={this.state.form}
            addForm={this.addForm}
            villaId={this.state.villaId}
          />
        );

      case 3:
        return (
          <Step4
            form={this.state.form}
            addForm={this.addForm}
            villaId={this.state.villaId}
            addVilla
          />
        );
      default:
        return "Unknown step";
    }
  }
  state = {
    activeStep: 0,
    form: {
      isValid: true,
      ownerName: "",
      nickname: "",
      title: "",
      description: "",
      accommodates: "",
      bedrooms: "",
      beds: "",
      bathrooms: "",
      propertyType: "",
      roomType: "",
      dimensions: "",
      amenities: [],
      includedInPrice: [],
      nearbyAttractions: [],
      layoutAndFeatures: [],
      villaCategories: [],
      attachFinancialBindingModels: [],
      amenitiesNotIncluded: [],
      tags: [],
      pictures: [],
      onBoardingExtraData: {
        lastViewed: "",
        daysOccupied: "",
        averageDailyRate: "",
        expectedReturn: "",
        ownerDemand: "",
        nextMeeting: null,
        notes: "",
        finalNotes: "",
        indoorFeauturesText: "",
        outdoorFeauturesText: "",
        multimedia: null,
        onBoardingLayoutsAndFeatures: []
      },
      onBoardingMoreExtras: this.props.onBoardingMoreExtras,
      address: {
        placeId: "",
        full: "",
        street: "",
        areaId: this.props.selectedArea ? this.props.selectedArea.id : 1,
        latlng: {
          lat: "",
          lng: ""
        },
        zoneId: "",
        zone: ""
      }
    }
  };

  componentDidUpdate(pevprops) {
    if (pevprops.selectedArea !== this.props.selectedArea) {
      let adress = { ...this.state.adress };
      adress.areaId = this.props.selectedArea.id;
      this.setState(state => ({
        ...state,
        form: {
          ...state.form,
          address: {
            ...state.form.address,
            areaId: this.props.selectedArea.id,
            zone: {}
          }
        }
      }));
    }

    // if (pevprops.onBoardingMoreExtras !== this.props.onBoardingMoreExtras) {
    //   this.addOnBoardingMoreExtras();
    // }
  }
  addForm = form => {
    this.setState({ form });
  };

  setActiveStep = step => {
    this.setState({ activeStep: step });
  };
  handleNext = () => {
    const steps = this.getSteps();
    if (this.state.activeStep === steps.length - 1) {
      console.log(this.state.form);
    }
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setActiveStep(0);
  };

  render() {
    const { classes } = this.props;
    const steps = this.getSteps();

    return (
      <div className={classes.root}>
        <Stepper
          alternativeLabel
          activeStep={this.state.activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {this.state.activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={this.handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>
                {this.getStepContent(this.state.activeStep)}
              </Typography>
              <div>
                <Button
                  disabled={this.state.activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.button}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                  className={classes.button}
                >
                  {this.state.activeStep === steps.length - 1
                    ? "Finish"
                    : "Next"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedArea: state.area.selected,
    onBoardingMoreExtras: state.villas.onBoardingMoreExtras,
    onBoardingMoreExtrasOptimized: state.villas.onBoardingMoreExtrasOptimized
  };
};

const mapDispatchToStore = dispatch => {
  return {};
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToStore)(OwnerAdd)
);
