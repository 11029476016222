import * as actionTypes from "../actions/actionTypes";

const initialState = {
  open: false,
  title: "",
  text: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ALERT_OPEN:
      return {
        ...state,
        open: true,
        title: action.payload.title,
        text: action.payload.text
      };
    case actionTypes.ALERT_CLOSE:
      return {
        ...state,
        open: false,
        title: "",
        text: ""
      };
    default:
      return {
        ...state
      };
  }
};

export default reducer;
