import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import marker from "../../assets/img/marker.png";
import IconButton from "@material-ui/core/IconButton";
import { DATA } from "../../config/constants";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Checkbox from "@material-ui/core/Checkbox";
import LocalBarIcon from "@material-ui/icons/LocalBar";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import StoreIcon from "@material-ui/icons/Store";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import HomeIcon from "@material-ui/icons/Home";
import PlaceIcon from "@material-ui/icons/Place";
const Marker = props => {
  const [open, setOpen] = useState(false);
  let IconName;
  if (props.icon === "beach_club") {
    IconName = BeachAccessIcon;
  } else if (props.icon === "restaurant") {
    IconName = RestaurantIcon;
  } else if (props.icon === "beach") {
    IconName = WbSunnyIcon;
  } else if (props.icon === "club") {
    IconName = LocalBarIcon;
  } else if (props.icon === "store") {
    IconName = StoreIcon;
  } else if (props.icon === "market") {
    IconName = LocalGroceryStoreIcon;
  } else if (props.icon === "place") {
    IconName = PlaceIcon;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "80px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {open ? <h6 style={{ fontWeight: "bold" }}>{props.text}</h6> : null}

      <IconName
        // src={marker}
        style={{
          borderRadius: "50%",
          position: "absolute",
          top: 0,
          left: 0,
          transform: "translate(-50%, -50%)"
        }}
        onClick={() => setOpen(prevState => !prevState)}
      />
    </div>
  );
};

const VillaMarker = props => {
  const [open, setOpen] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "80px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {open ? <p style={{ fontWeight: "bold" }}>{props.name}</p> : null}
      <img
        src={marker}
        alt="marker"
        style={{
          borderRadius: "50%",
          position: "absolute",
          top: 0,
          left: 0,
          transform: "translate(-50%, -50%)"
        }}
        onClick={() => setOpen(prevState => !prevState)}
      />
    </div>
    // <div>
    //   <img
    //     style={{
    //       borderRadius: "50%",
    //       position: "absolute",
    //       top: "50%",
    //       left: "50%",
    //       transform: "translate(-50%, -50%)"
    //     }}
    //     src={marker}
    //     alt="imgmarker"
    //   />
    // </div>
  );
};
let defaultProps = {
  center: {
    lat: 37.4339063,
    lng: 25.3183747
  },
  zoom: 13
};
const createMapOptions = () => {
  return {
    gestureHandling: "greedy"
  };
};

const PoiMap = props => {
  const { villas, centerCoords } = props;

  const [thisMap, setMap] = useState({
    map: "",
    maps: "",
    mapLoaded: false
  });

  const handleApiloaded = (map, maps) => {
    const CustomSatelliteButton = (controlDiv, map) => {
      let isSAtelite = false;

      var controlUI = document.createElement("div");
      controlUI.classList.add("satellite-map");
      controlUI.classList.add("fa");
      controlUI.classList.add("fa-map-o");
      controlDiv.appendChild(controlUI);
      // Setup the click event listeners: simply set the map to Satellite.
      controlUI.addEventListener("click", function() {
        if (!isSAtelite) {
          controlUI.classList.add("fa-globe");
          controlUI.classList.remove("fa-map-o");
          map.setMapTypeId("hybrid");
          isSAtelite = true;
        } else {
          controlUI.classList.add("fa-map-o");
          controlUI.classList.remove("fa-globe");
          isSAtelite = false;
          map.setMapTypeId("roadmap");
        }
      });
    };
    setMap({
      map: map,
      maps: maps,
      mapLoaded: true
    });
    if (map) {
      let SatelliteButtonDiv = document.createElement("div");
      CustomSatelliteButton(SatelliteButtonDiv, map);

      SatelliteButtonDiv.index = 1;
      map.controls[maps.ControlPosition.TOP_LEFT].push(SatelliteButtonDiv);
    }
  };

  useEffect(() => {
    // updates only if centerCoords changes
    if (thisMap.mapLoaded) {
      thisMap.map.setCenter(centerCoords);
    }
  }, [centerCoords]);

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "600px", width: "100%" }}>
      <GoogleMapReact
        onGoogleApiLoaded={({ map, maps }) => {
          handleApiloaded(map, maps);
        }}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: "AIzaSyCTyrsUuye8zfoBd9Z6qpfnFJmQFC1-U6Q" }}
        defaultCenter={centerCoords ? centerCoords : defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {villas.map(villa => {
          if (villa.address)
            return (
              <VillaMarker
                lat={villa.address.latlng.lat}
                lng={villa.address.latlng.lng}
                name={villa.name}
                key={villa.name}
              />
            );
        })}
      </GoogleMapReact>
    </div>
  );
};

export default PoiMap;
