import React from "react";
import * as actions from "../../store/actions/index";
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const AreasSelect = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [name, setArea] = React.useState(1);
  const handleChange = event => {
    const name = event.currentTarget.attributes.name.value;
    let area = props.areas.filter(area => area.id === event.target.value);
    dispatch(actions.getCoupons(area[0].id));
    dispatch(actions.getPois(area[0].id));
    props.setArea(event.target.value, name, area);
    setArea(event.target.value);
  };

  const { loading, areas } = props;
  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        {!loading ? (
          <Select
            labelid="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={name}
            onChange={handleChange}
          >
            {areas.map(area => (
              <MenuItem key={area.id} value={area.id} name={area.city}>
                {area.city}
              </MenuItem>
            ))}
          </Select>
        ) : null}
      </FormControl>
    </>
  );
};

const mapStateToProps = state => {
  return {
    SelectedArea: state.settings.Amenities,
    areasLoaded: state.area.loading,
    areas: state.area.areas,
    villasInAreas: state.area.villasInAreas
  };
};

const mapDispatchToStore = dispatch => {
  return {
    setArea: (id, name, area) => dispatch(actions.setArea(id, name, area)),
    getCoupons: id => dispatch(actions.getCoupons(id)),
    getPois: id => dispatch(actions.getPois(id))
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(AreasSelect);
