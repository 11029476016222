export const GET_VILLAS_START = "GET_VILLAS_START";
export const GET_VILLAS_SUCCESS = "GET_VILLAS_SUCCESS";
export const GET_VILLAS_FAIL = "GET_VILLAS_FAIL";

export const GET_VILLAS_DETAILS_START = "GET_VILLAS_DETAILS_START";
export const GET_VILLAS_DETAILS_SUCCESS = "GET_VILLAS_DETAILS_SUCCESS";
export const GET_VILLAS_DETAILS_FAIL = "GET_VILLAS_DETAILS_FAIL";

export const GET_CLIENTS_START = "GET_CLIENTS_START";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL";

export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";
export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS";
export const AUTH_LOGIN_START = "AUTH_LOGIN_START";
export const AUTH_SET_ROLE = "AUTH_SET_ROLE";

export const GET_AMENITIES_SUCCESS = "GET_AMENITIES_SUCCESS";
export const GET_AMENITIES_START = "GET_AMENITIES_START";
export const GET_AMENITIES_FAIL = "GET_AMENITIES_FAIL";

export const UPDATE_AMENITIES_SUCCESS = "UPDATE_AMENITIES_SUCCESS";
export const UPDATE_AMENITIES_FAIL = "UPDATE_AMENITIES_FAIL";

export const DELETE_AMENITIES_SUCCESS = "DELETE_AMENITIES_SUCCESS";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const ADD_AMENITY_CATEGORY_SUCCESS = "ADD_AMENITY_CATEGORY_SUCCESS";

export const GET_INCLUDEDINPRICE_SUCCESS = "GET_INCLUDEDINPRICE_SUCCESS";
export const GET_INCLUCEDINPRICE_FAIL = "GET_INCLUCEDINPRICE_FAIL";

export const ADD_VILLAS_SUCCESS = "ADD_VILLAS_SUCCESS";
export const ADD_VILLAS_FAIL = "ADD_VILLAS_FAIL";

export const EDIT_VILLA_START = "EDIT_VILLA_START";
export const EDIT_VILLA_SUCCESS = "EDIT_VILLA_SUCCESS";
export const EDIT_VILLA_FAIL = "EDIT_VILLA_FAIL";

export const SET_AREA = "SET_AREA";

export const GET_AREAS_START = "GET_AREAS_START";
export const GET_AREAS_SUCCESS = "GET_AREAS_SUCCESS";
export const GET_AREAS_FAIL = "GET_AREAS_FAIL";
export const ADD_VILLAS_IN_AREA = "ADD_VILLAS_IN_AREA";

export const UPDATE_VILLA_STATUS_START = "UPDATE_VILLA_STATUS_START";
export const UPDATE_VILLA_STATUS_SUCCESS = "UPDATE_VILLA_STATUS_SUCCESS";
export const UPDATE_VILLA_STATUS_FAIL = "UPDATE_VILLA_STATUS_FAIL";

export const SET_VILAS_IN_PAGE = "SET_VILAS_IN_PAGE";

export const GET_STATUSES_START = "GET_STATUSES_START";
export const GET_STATUSES_SUCCESS = "GET_STATUSES_SUCCESS";
export const GET_STATUSES_FAIL = "GET_STATUSES_FAIL";

export const GET_ZONES_START = "GET_ZONES_START";
export const GET_ZONES_SUCCESS = "GET_ZONES_SUCCESS";
export const GET_ZONES_FAIL = "GET_ZONES_FAIL";

export const ADD_USER_START = "ADD_USER_START";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";

export const INVITE_USER_START = "INVITE_USER_START";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAIL = "INVITE_USER_FAIL";

export const GET_USERS_START = "GET_USERS_START";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const GET_USER_START = "GET_USER_START";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const GET_ROLES_START = "GET_ROLES_START";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";

export const SET_PROPERTY_IMAGES_START = "SET_PROPERTY_IMAGES_START";
export const SET_PROPERTY_IMAGES_SUCCESS = "SET_PROPERTY_IMAGES_SUCCESS";
export const SET_PROPERTY_IMAGES_FAIL = "SET_PROPERTY_IMAGES_FAIL";

export const UPDATE_SITE_ID_START = "UPDATE_SITE_ID_START";
export const UPDATE_SITE_ID_SUCCESS = "UPDATE_SITE_ID_SUCCESS";
export const UPDATE_SITE_ID_FAIL = "UPDATE_SITE_ID_FAIL";

export const ALERT_OPEN = "ALERT_OPEN";
export const ALERT_CLOSE = "ALERT_CLOSE";

export const GET_EVENTS_START = "GET_EVENTS_START";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

export const GET_INQUIRYFORMS_START = "GET_INQUIRYFORMS_START";
export const GET_INQUIRYFORMS_SUCCESS = "GET_INQUIRYFORMS_SUCCESS";
export const GET_INQUIRYFORMS_FAIL = "GET_INQUIRYFORMS_FAIL";

export const GET_LOGS_START = "GET_LOGS_START";
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS";
export const GET_LOGS_FAIL = "GET_LOGS_FAIL";

export const GET_PRICELOGS_START = "GET_PRICELOGS_START";
export const GET_PRICELOGS_SUCCESS = "GET_PRICELOGS_SUCCESS";
export const GET_PRICELOGS_FAIL = "GET_PRICELOGS_FAIL";

export const GET_COUPONS_START = "GET_COUPONS_START";
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS";
export const GET_COUPONS_FAIL = "GET_COUPONS_FAIL";

export const GET_POIS_START = "GET_POIS_START";
export const GET_POIS_SUCCESS = "GET_POIS_SUCCESS";
export const GET_POIS_FAIL = "GET_POIS_FAIL";

export const GET_POISTYPES_SUCCESS = "GET_POISTYPES_SUCCESS";
export const GET_POISTYPES_FAIL = "GET_POISTYPES_FAIL";

export const GET_ONBOARDINGMORE_EXTRAS_START =
  "GET_ONBOARDINGMORE_EXTRAS_START";
export const GET_ONBOARDINGMORE_EXTRAS_SUCCESS =
  "GET_ONBOARDINGMORE_EXTRAS_SUCCESS";
export const GET_ONBOARDINGMORE_EXTRAS_FAIL = "GET_ONBOARDINGMORE_EXTRAS_FAIL";
