import * as actionTypes from "./actionTypes";
import axios from "axios";
import { headers } from "../../config/config";
import { getEnvironment } from "../../config/config";

export const getCouponsStart = () => {
  return {
    type: actionTypes.GET_COUPONS_START
  };
};

export const getCouponsSuccess = Coupons => {
  return {
    type: actionTypes.GET_COUPONS_SUCCESS,
    Coupons
  };
};

export const getCouponsFail = error => {
  return {
    type: actionTypes.GET_COUPONS_FAIL,
    error
  };
};

export const getCoupons = areaId => {
  return dispatch => {
    dispatch(getCouponsStart());
    axios
      .get(getEnvironment().apiUrl + `/Settings/GetCoupons?areaId=${areaId}`, {
        headers: headers()
      })
      .then(response => {
        dispatch(getCouponsSuccess(response.data));
      })
      .catch(error => {
        dispatch(getCouponsFail(error.response));
      });
  };
};

export const addCoupon = data => {
  return async dispatch => {
    try {
      const res = await axios.put(
        getEnvironment().apiUrl + `/Settings/AddUpdateCoupons`,
        data,
        {
          headers: headers()
        }
      );
      return res.data;
    } catch (error) {
      dispatch(getCouponsFail(error.response));
      return error;
    }
  };
};
