import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import * as actions from "../../store/actions";

const useStyles = makeStyles({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    },
    cursor: "pointer"
  },
  filterbar: {
    position: "absolute",
    right: 0
  },
  CardHeader: {
    display: "flex",
    alignItems: "center"
  }
});

const Zones = props => {
  const classes = useStyles();

  const [state] = React.useState({
    columns: [
      { title: "Name", field: "name" },
      { title: "Area", field: "areaid", editable: "onUpdate" }
    ],
    data: props.zones
  });
  let selectedZones = actions.getZonesByArea(props.selectedArea, props.zones);
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader className={classes.CardHeader} color="primary">
            <h4 className={classes.cardTitleWhite}>Zones</h4>
          </CardHeader>
          <CardBody>
            <MaterialTable
              title={`Zones in ${props.selectedArea.name}`}
              columns={state.columns}
              data={selectedZones}
              editable={{
                onRowAdd: newData =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      // setState(prevState => {
                      //   const data = [...prevState.data];
                      //   data.push(newData);
                      //   return { ...prevState, data };
                      // });
                      newData = {
                        id: null,
                        areaid: props.selectedArea.id,
                        name: newData.name
                      };

                      props.addZones(newData);
                    }, 600);
                  }),
                onRowDelete: oldData =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      props.deleteZones(oldData.id);
                    }, 600);
                  })
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

const mapStateToProps = state => {
  return {
    zones: state.area.zones,
    selectedArea: state.area.selected
  };
};

const mapDispatchToStore = dispatch => {
  return {
    addZones: data => dispatch(actions.addZones(data)),
    deleteZones: data => dispatch(actions.deleteZones(data))
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(Zones);
