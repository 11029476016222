import React from "react";
import * as actions from "../../store/actions/index";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { connect } from "react-redux";
import { ReactComponent as Map } from "../../assets/img/map.svg";
import ListIcon from "@material-ui/icons/List";
import "./FilterBar.css";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  search: {
    position: "relative",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%"
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: 0
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    borderBottom: "1px solid",
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  map: {
    width: 35,
    height: 35,
    cursor: "pointer"
  }
}));

const FilterBar = props => {
  //  const isButtonActive = { props };
  const classes = useStyles();
  const [state, setState] = React.useState(0);
  const [zone, setZone] = React.useState(0);
  const [inputValue, setInputValue] = React.useState("");
  const [map, togglemap] = React.useState(false);

  const handleChangeZone = e => {
    setZone(e.target.value);
  };
  const handleOnChangeSearch = e => {
    setInputValue(e.target.value);
  };
  const handleToggleMap = () => {
    let btn = "list";
    togglemap(!map);
    if (!map) {
      btn = "map";
    }
    props.togglemap(btn);
  };

  React.useEffect(() => {
    props.aplyFiltering(inputValue, state, zone);
  });

  let selectedZones = actions.getZonesByArea(props.selectedArea, props.zones);
  const handleOnChangePropertyState = e => {
    setState(e.target.value);
    props.aplyFiltering(inputValue, state);
  };

  const DisplayButton = () => {
    if (props.isButtonActive === "calendar" || !map) {
      return <Map className={classes.map} />;
    }
    if (map || props.isButtonActive === "calendar") {
      return <ListIcon className={classes.map} />;
    } else {
      return <ListIcon className={classes.map} />;
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item>
          <div onClick={handleToggleMap}>
            <DisplayButton />
          </div>
        </Grid>
        <Grid item style={{ display: "flex", alignItems: "center" }}>
          <Select
            labelid="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={state}
            onChange={e => handleOnChangePropertyState(e)}
          >
            <MenuItem value={0}>Property State</MenuItem>
            {props.statuses.map(area => (
              <MenuItem key={area} value={area}>
                {area}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {props.zones.length > 0 ? (
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Select
              labelid="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={zone}
              onChange={e => handleChangeZone(e)}
            >
              <MenuItem value={0}>Select zone</MenuItem>
              {selectedZones.map(area => (
                <MenuItem key={area.id} value={area.id} name={area.name}>
                  {area.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        ) : (
          ""
        )}
        <Grid item>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              onChange={handleOnChangeSearch}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ "aria-label": "search" }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    statuses: state.villas.statuses,
    zones: state.area.zones,
    selectedArea: state.area.selected
  };
};

export default connect(mapStateToProps, null)(FilterBar);
