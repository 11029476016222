import * as actionTypes from "../actions/actionTypes";

const initialState = {
    selected: { id: 1, name: "Mykonos" }
    ,
    areas: [],
    zones: [],
    loading: true,
    filtered: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_AREAS_SUCCESS:
            return {
                ...state,
                areas: action.areas,
                loading: false,
            };
        case actionTypes.GET_ZONES_SUCCESS:
            return {
                ...state,
                zones: action.zones,
                // loading: false,
            };
        case actionTypes.SET_AREA:
            return {
                ...state, selected: action.areaSelected,
            };
        default:
            return {
                ...state
            };
    }
};

export default reducer;
