import * as actionTypes from "./actionTypes";

export const openAlert = (title = "", text = "") => {
  let payload = {
    open: true,
    title,
    text
  };

  return {
    type: actionTypes.ALERT_OPEN,
    payload
  };
};

export const closeAlert = user => {
  let payload = {
    open: false,
    title: "",
    text: ""
  };
  return {
    type: actionTypes.ALERT_CLOSE,
    payload
  };
};
