import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import * as actions from "../store/actions";
import { connect } from "react-redux";
import axios from "axios";
import { getEnvironment, headers } from "../config/config";

class Profile extends Component {
  state = {
    userId: null,
    firstName: "",
    lastName: "",
    email: "",
    roles: [],
    active: true,
    loading: true
  };

  componentDidMount() {
    const userId = JSON.parse(localStorage.getItem("id"));
    this.setState({ userId }, () =>
      this.props.getUser(this.state.userId).then(res =>
        this.setState({
          firstName: res.firstName,
          lastName: res.lastName,
          email: res.email,
          roles: res.roles,
          active: res.active,
          loading: false
        })
      )
    );
  }

  handleChanges = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  updateUser = () => {
    this.setState({ loading: true });
    const { userId, firstName, lastName, roles, active } = this.state;
    axios
      .put(
        getEnvironment().apiUrl + "/Account/Update",
        {
          id: userId,
          firstName,
          lastName,
          roles,
          active
        },
        {
          headers: headers()
        }
      )
      .then(() => {
        this.setState({ loading: false });
      })
      .catch(error => console.log(error));
  };

  render() {
    return this.state.loading ? (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress disableShrink />
        </div>
      </>
    ) : (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <TextField
            placeholder="Type your name"
            label="First Name"
            onChange={this.handleChanges}
            value={this.state.firstName}
            name={"firstName"}
            margin="normal"
            required
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
          />
          <TextField
            placeholder="Type your name"
            label="Last Name"
            onChange={this.handleChanges}
            value={this.state.lastName}
            name={"lastName"}
            margin="normal"
            required
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
          />
          <TextField
            placeholder="Type your email"
            label="Email"
            value={this.state.email}
            name={"email"}
            margin="normal"
            required
            variant="outlined"
            style={{ width: "30%", backgroundColor: "white" }}
            disabled
          />
          <Button color="primary" variant="contained" onClick={this.updateUser}>
            Submit
          </Button>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUser: userId => dispatch(actions.getUser(userId))
  };
};

export default connect(null, mapDispatchToProps)(Profile);
