import * as actionTypes from "../actions/actionTypes";

const initialState = {
  users: [],
  user: [],
  loading: true
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.users
      };
    case actionTypes.GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case actionTypes.GET_USER_START:
      return {
        ...state,
        loading: true
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.user,
        loading: false
      };
    case actionTypes.GET_USER_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default reducer;
