import React from "react";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import Zones from "../../components/Zones/Zones";
import CircularProgress from "@material-ui/core/CircularProgress";
import Inquryform from "../../components/Inquryform/Inquryform";

class Settings extends React.Component {
  state = {
    data: [],
    loading: true
  };
  success = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.props.getAmenities();
      this.setState({ loading: false });
    }, 1000);
  };

  asyncSucceess = async () => {
    await this.props.getAmenities();
  };

  componentDidUpdate(prevprops) {
    if (prevprops.Amenities !== this.props.Amenities) {
    }
  }
  componentDidMount() {
    this.setState({ loading: false });
  }
  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    if (this.props.Role === "Admin") {
      return (
        <React.Fragment>
          <div style={{ width: "90%" }}>
            {/* <AppSettings
              title="Amenities"
              categories={this.props.AmenitiesCategories}
              data={this.props.Amenities}
              edit
              success={this.success}
              forceUpdates={this.forceUpdates}
              asyncSucceess={this.asyncSucceess}
            />
  
            <AppSettings
              title="Included In the Price"
              inclusions={includes}
              edit
            /> */}
            <Zones />
            <Inquryform />
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <h2>Your Role has no Access for this Page!</h2>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    AmenitiesCategories: state.settings.AmenitiesCategories,
    Amenities: state.settings.Amenities,
    Included: state.settings.IncludedinPrice,
    Role: state.auth.Role
  };
};

const mapDispatchToStore = dispatch => {
  return {
    getAmenities: () => dispatch(actions.getAmenities())
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(Settings);
