import React from "react";
import * as actions from "../../store/actions";
import { withStyles } from "@material-ui/core/styles";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import { connect } from "react-redux";
import MaterialTable from "material-table";
import FilterBar from "../../components/FilterBar/FliterBar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import EventIcon from "@material-ui/icons/Event";
import AddLocationOutlinedIcon from "@material-ui/icons/AddLocationOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "@material-ui/core/Modal";
import Chip from "@material-ui/core/Chip";
import VillasMap from "../../components/Map/VillasMap";
import PearlsIcon from "../../assets/img/thepearls_aa.png";
import moment from "moment";
import axios from "axios";
import { getEnvironment, headers } from "../../config/config";
import update from "immutability-helper";
import ListIcon from "@material-ui/icons/List";

import Scheduler from "../../components/UI/Scheduler/";
let plusOneMonth = moment().add(1, "years");
let minusOneMonth = moment().subtract(1, "years");

plusOneMonth = moment(plusOneMonth).format("YYYY-MM-D");
minusOneMonth = moment(minusOneMonth).format("YYYY-MM-D");

const styles = theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    // color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    },
    cursor: "pointer"
  },
  filterbar: {
    display: "flex",
    position: "absolute",
    right: 0
  },
  CardHeader: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#00acc1"
  },
  map: {
    width: 35,
    height: 35,
    cursor: "pointer"
  }
});

let villaFilter = [];
let villasInSelectedArea = [];
let properties = [];

class TableList extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    columns: [
      { title: "Name", field: "nickname", defaultSort: "asc" },
      {
        title: "Owner",
        field: "owner",
        render: rowData => (
          <span>
            {rowData.owner.name} {rowData.owner.lastName}
          </span>
        )
      },
      { title: "Status", field: "propertyState" },
      { title: "Site Id", field: "siteId" },
      { title: "Accommodates", field: "accommodates", type: "numeric" },
      { title: "Bathrooms", field: "bathrooms", type: "numeric" },
      { title: "Bedrooms", field: "bedrooms", type: "numeric" },
      { title: "Beds", field: "beds", type: "numeric" }
    ],
    isCalendarOpen: false,
    loading: true,
    open: false,
    firstLoad: true,
    villasInSelectedArea: [],
    map: false,
    popOpen: false,
    popSiteIdOpen: false,
    siteId: "",
    villaIdToDelete: null,
    villaName: null,
    villaId: null,
    villaSiteId: null,
    villaStatus: null,
    center: {
      lat: 37.45529,
      lng: 25.3672
    },
    modalOpen: false,
    filterSelectOpen: false,
    activeFilter: null,
    firstTimeLoad: true,
    removeFilterPressed: false,
    filters: [
      {
        id: 0,
        name: "Pms Listed",
        active: false,
        included: false,
        previousValue: null
      }
    ],
    isButtonActive: "list"
  };

  componentDidMount() {
    const { villasInAreas } = this.props;
    if (villasInAreas) {
      this.setState({
        firstLoad: true
      });
    }
    this.setState({ loading: false });
    this.setCoords();
    this.getFiltersFromLocalStorage();
  }

  getFiltersFromLocalStorage = () => {
    if (window.localStorage.getItem("property-fliters")) {
      this.setState({
        filters: JSON.parse(window.localStorage.getItem("property-fliters"))
      });
    }
  };

  setFiltersToLocalStrorage = () => {
    let storageFilters = [...this.state.filters];
    for (let i = 0; i < storageFilters.length; i++) {
      storageFilters[i].previousValue = null;
    }
    window.localStorage.setItem(
      "property-fliters",
      JSON.stringify(storageFilters)
    );
  };

  componentWillUnmount() {
    this.setFiltersToLocalStrorage();
  }

  componentDidUpdate(prevProps, prevState) {
    const { filters } = this.state;
    if (prevState.filters !== filters) {
      this.setFiltersToLocalStrorage();
      // this.getVillasFromPage();
    }

    const { selectedArea, villasInAreas } = this.props;
    if (villasInAreas) {
      let selectedVilas = actions.setVillasInSelectedArea(
        villasInAreas,
        selectedArea
      );
      // Loads villas if comes directly from url (ex.someone paste it)
      if (prevState.firstLoad) {
        this.setState({
          //villasInSelectedArea: selectedVilas[0][selectedArea.name],
          loading: false,
          firstLoad: false
        });
      } else {
        if (prevProps.selectedArea !== selectedArea) {
          this.setState({
            villasInSelectedArea: selectedVilas[0][selectedArea.name],
            loading: false
          });
          for (let i = 0; i < this.state.filters.length; i++) {
            this.setState(
              update(this.state, {
                filters: {
                  [i]: {
                    previousValue: {
                      $set: null
                    }
                  }
                }
              })
            );
          }
        }
        if (prevProps.villasInAreas !== this.props.villasInAreas) {
          this.setState({
            villasInSelectedArea: selectedVilas[0][selectedArea.name],
            loading: false
          });
        }
        if (this.props.selectedArea !== prevProps.selectedArea) {
          this.setCoords();
        }
      }
    }
  }

  setCoords = () => {
    let areaCoords = this.props.selectedArea.area
      ? this.props.selectedArea.area.centerCoords
      : null;
    this.setState({ center: areaCoords });
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleMap = button => {
    this.setState({ isButtonActive: button });
  };

  setOpen = status => {
    this.setState({ open: status });
  };

  handleClickOpen = () => {
    this.setOpen(true);
  };

  handleClose = () => {
    this.setOpen(false);
  };

  // filter from search input
  applyfiltering = (search, propertyState, zone) => {
    properties = [];
    let { selectedArea, villasInAreas } = this.props;
    let selectedVilas = actions.setVillasInSelectedArea(
      villasInAreas,
      selectedArea
    );
    villasInSelectedArea = selectedVilas[0];
    if (this.state.firstTimeLoad) {
      this.setState({
        initialProperties: selectedVilas[0][selectedArea.name],
        firstTimeLoad: false
      });
    }
    this.state.filters.map((filter, index) => {
      if (filter.included) {
        villaFilter = villasInSelectedArea[selectedArea.name].filter(
          property => {
            if (
              filter.name === "Pms Listed" &&
              filter.active !== filter.previousValue
            ) {
              if (
                property.isListed === filter.active &&
                property.isPmsActive === filter.active &&
                property.isPmsListed === filter.active
              ) {
                properties.push(property);
              }
              this.setState(
                update(this.state, {
                  filters: {
                    0: {
                      previousValue: {
                        $set: filter.active
                      }
                    }
                  }
                }),
                () => (!this.state.modalOpen ? null : this.toggleFiltersModal())
              );
            }
            if (
              filter.name === "Site Properties" &&
              filter.active !== filter.previousValue
            ) {
              if (property.isPublished === filter.active) {
                properties.push(property);
              } else {
                properties = [];
              }
              this.setState(
                update(this.state, {
                  filters: {
                    1: {
                      previousValue: {
                        $set: filter.active
                      }
                    }
                  }
                })
              );
            }
            return properties;
          }
        );
      }
    });
    let villas = [];
    if (this.state.firstTimeLoad && properties.length === 0) {
      villas = villasInSelectedArea[selectedArea.name];
    } else if (properties.length === 0 && this.state.removeFilterPressed) {
      villas = villasInSelectedArea[selectedArea.name];
    } else if (properties.length === 0) {
      villas = villasInSelectedArea[selectedArea.name];
    } else {
      villas = properties;
    }
    let filteredVillas = villas;
    let res = villas.filter(obj => {
      const creteria = {
        owner: obj.owner.name,
        description: obj.description,
        nickname: obj.nickname
      };
      return Object.values(creteria).some(val => {
        if (search && search !== "") {
          if (val) {
            val = val.toLowerCase();
            search = search.toLowerCase();
            return val.includes(search);
          }
        } else {
          return filteredVillas;
        }
      });
    });
    if (propertyState !== 0) {
      res = res.filter(villa => villa.propertyState === propertyState);
    }
    if (zone !== 0) {
      res = res.filter(villa => {
        if (villa.address.zone) {
          return villa.address.zone.id === zone;
        }
      });
    }
    this.setState(
      {
        villasInSelectedArea: res
      },
      () =>
        this.state.isButtonActive === "calendar"
          ? this.getVillasFromPage()
          : null
    );
  };

  togglePopOver = () => {
    this.setState({ popOpen: !this.state.popOpen });
  };

  deleteVilla = () => {
    axios
      .delete(
        getEnvironment().apiUrl +
          `/Property/Delete/${this.state.villaIdToDelete}`,
        {
          headers: headers()
        }
      )
      .then(() => {
        this.togglePopOver();
        this.props.getVillas();
      })
      .catch(error => console.log(error));
  };

  toggleSiteIdPop = () => {
    this.setState({ popSiteIdOpen: !this.state.popSiteIdOpen });
  };

  updateSiteId = () => {
    let property = [];
    property = this.props.siteIds.filter(
      property => property.siteId === this.state.siteId
    );
    if (property.length === 0) {
      this.props
        .updateSiteId(this.state.villaId, this.state.siteId)
        .then(() => {
          this.toggleSiteIdPop();
          this.props.getVillas();
        });
    } else {
      this.props.openAlert("", "Site id is already taken");
    }
  };

  toggleCalendar = type => {
    this.setState({ isButtonActive: type }, () => {
      this.getVillasFromPage();
    });
  };

  getVillasFromPage = () => {
    if (this.myRef.current) {
      this.props.setVilasInPage(this.myRef.current.dataManager.pagedData);
      this.props.getVillasEvents(
        this.props.selectedArea.id,
        minusOneMonth,
        plusOneMonth,
        "",
        this.myRef.current.dataManager.pagedData
      );
    }
  };

  toggleFiltersModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  setActiveFilterName = filter => {
    this.setState({ activeFilter: filter });
  };

  activateFilter = e => {
    let value = e.target.checked;
    this.setState(
      update(this.state, {
        activeFilter: {
          active: {
            $set: value
          }
        }
      })
    );
  };

  addFilter = () => {
    this.state.filters.map((filter, index) =>
      filter.id === this.state.activeFilter.id
        ? this.setState(
            update(this.state, {
              filters: {
                [index]: {
                  $set: {
                    ...this.state.activeFilter,
                    included: true
                  }
                }
              },
              activeFilter: {
                $set: null
              }
            }),
            () => {
              this.toggleFiltersModal();
              this.applyfiltering("", 0, 0);
            }
          )
        : null
    );
  };

  removeFilter = () => {
    this.state.filters.map((filter, index) =>
      filter.id === this.state.activeFilter.id
        ? this.setState(
            update(this.state, {
              filters: {
                [index]: {
                  $set: {
                    ...this.state.activeFilter,
                    included: false
                  }
                }
              },
              activeFilter: {
                $set: null
              }
            }),
            () => {
              this.toggleFiltersModal();
              this.applyfiltering("", 0, 0);
            }
          )
        : null
    );
  };

  render() {
    const { classes } = this.props;
    const { columns, villasInSelectedArea } = this.state;
    if (this.state.loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 150
          }}
        >
          <CircularProgress disableShrink />
        </div>
      );
    }
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader className={classes.CardHeader}>
                <div style={{ display: "flex" }}>
                  <div>
                    <h4 className={classes.cardTitleWhite}>Your Properties</h4>
                  </div>
                </div>
                <div className={classes.filterbar}>
                  <FilterBar
                    isButtonActive={this.state.isButtonActive}
                    togglemap={this.toggleMap}
                    aplyFiltering={this.applyfiltering}
                  />
                </div>
              </CardHeader>
              <CardBody>
                {this.state.isButtonActive === "map" ? (
                  <VillasMap
                    centerCoords={this.state.center}
                    villas={villasInSelectedArea}
                  />
                ) : null}

                <div
                  className={
                    this.state.isButtonActive === "calendar"
                      ? "with-pager"
                      : null
                  }
                >
                  {this.state.isButtonActive === "calendar" ? (
                    <Scheduler
                      data={this.props.villasInPage}
                      events={
                        this.props.calendarEvents.length > 0
                          ? this.props.calendarEvent
                          : []
                      }
                    />
                  ) : null}
                  {this.state.isButtonActive === "list" ||
                  this.state.isButtonActive === "calendar" ? (
                    <MaterialTable
                      tableRef={this.myRef}
                      id="notPublished"
                      options={{
                        padding: "default",
                        pageSize: 5,
                        search: false,
                        grouping: true,
                        exportButton: true
                      }}
                      title={"Not Published"}
                      columns={columns}
                      data={villasInSelectedArea}
                      components={{
                        Toolbar: () => (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            <div
                              style={{
                                fontSize: 19,
                                fontWeight: "bold",
                                margin: "20px 0px 0px 20px"
                              }}
                            >
                              Property List
                            </div>
                            <div
                              style={{
                                margin: "10px 20px 0px 0px",
                                display: "flex",
                                flexDirection: "row",
                                cursor: "pointer"
                              }}
                              onClick={() =>
                                this.props.history.push("/owner/AddVilla")
                              }
                            >
                              <p style={{ marginRight: 20 }}>Add</p>
                              <AddLocationOutlinedIcon fontSize="large" />
                            </div>
                          </div>
                        )
                      }}
                      actions={[
                        {
                          icon: "edit",
                          tooltip: "Edit Villa",
                          onClick: (event, rowData) =>
                            this.props.history.push(
                              `/owner/EditVilla/${rowData.id}`,
                              {
                                villa: rowData
                              }
                            )
                        },
                        {
                          icon: "delete",
                          tooltip: "Delete Villa",
                          onClick: (event, rowData) => {
                            this.togglePopOver();
                            this.setState({ villaIdToDelete: rowData.id });
                          }
                        },
                        {
                          icon: () => (
                            <img
                              src={PearlsIcon}
                              alt="pearlsicon"
                              style={{ width: 24, height: 24 }}
                            />
                          ),
                          tooltip: "Add Site Id",
                          onClick: (event, rowData) => {
                            this.setState(
                              {
                                villaName: rowData.nickname,
                                villaId: rowData.id,
                                villaSiteId: rowData.siteId,
                                villaStatus: rowData.propertyState,
                                siteId: rowData.siteId
                              },
                              () => this.toggleSiteIdPop()
                            );
                          }
                        }
                      ]}
                      onChangePage={(e, data) => {
                        this.props.setVilasInPage(
                          this.myRef.current.dataManager.pagedData
                        );
                        this.props.getVillasEvents(
                          this.props.selectedArea.id,
                          minusOneMonth,
                          plusOneMonth,
                          " ",
                          this.myRef.current.dataManager.pagedData
                        );
                        // console.log(
                        //   "PAGE DATA PAGER",
                        //   this.myRef.current.dataManager.pagedData
                        // );
                      }}
                    />
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <Popover
          open={this.state.popSiteIdOpen}
          onClose={this.toggleSiteIdPop}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div
            style={{
              width: 230,
              height: 160,
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "column"
            }}
          >
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              Please enter Site ID
            </div>
            <div>
              <TextField
                label="Enter Site Id"
                margin="none"
                onChange={this.handleChange}
                name="siteId"
                fullWidth
                value={this.state.siteId}
              />
            </div>
            <div style={{ textAlign: "center" }}>
              <Button
                color="primary"
                variant="contained"
                onClick={this.updateSiteId}
              >
                Submit
              </Button>
            </div>
          </div>
        </Popover>
        <Popover
          open={this.state.popOpen}
          onClose={this.togglePopOver}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div
            style={{
              width: 300,
              height: 150,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              alignItems: "center"
            }}
          >
            <div>Please confirm delete of villa</div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%"
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={this.deleteVilla}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.togglePopOver}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Popover>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    villacount: state.villas.count,
    publishcount: state.villas.countpublished,
    villasInAreas: state.villas.villasInAreas,
    selectedArea: state.area.selected,
    filtered: state.area.filtered,
    siteIds: state.villas.siteIds,
    villasInPage: state.villas.vilasInPage,
    calendarEvents: state.villas.calendarEvents
  };
};

const mapDispatchToStore = dispatch => {
  return {
    openAlert: (title, text) => dispatch(actions.openAlert(title, text)),
    getVillas: () => dispatch(actions.getVillas()),
    updateSiteId: (villaId, siteId) =>
      dispatch(actions.updateSiteId(villaId, siteId)),
    setVilasInPage: vilas => dispatch(actions.setVilasInPage(vilas)),
    getVillasEvents: (area, from, to, event, villas) =>
      dispatch(actions.getVillasEvents(area, from, to, event, villas))
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToStore)(TableList)
);
