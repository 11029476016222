import React from "react";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import moment from "moment";
class PromoCodes extends React.Component {
  state = {
    data: [],
    loading: true
  };

  componentDidUpdate(prevprops) {}
  componentDidMount() {
    this.setState({ loading: false });
  }
  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    if (this.props.Role === "Admin") {
      return (
        <React.Fragment>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => this.props.history.push("/admin/addCoupon")}
          >
            <AddIcon color="primary" style={{ cursor: "pointer" }} />
            <div className="p">Add A Coupon</div>
          </div>
          <div
            style={{
              padding: 30
            }}
          >
            <MaterialTable
              columns={[
                {
                  title: "prefix",
                  field: "prefix"
                },
                { title: "Code", field: "serialNumber" },
                {
                  title: "discount",
                  field: "discountPercentage",
                  render: rowData =>
                    rowData.discountPercentage
                      ? rowData.discountPercentage + "%"
                      : rowData.discountFlat + "€"
                },

                { title: "TimesUsed", field: "usages" },
                { title: "maxUsages", field: "maxUsages" },

                { title: "timesApplied", field: "timesApplied" },
                { title: "owner", field: "owner" },

                { title: "partner", field: "partner" },
                { title: "revenue", field: "revenue" },

                {
                  title: "active",
                  field: "active",
                  render: rowData => (rowData.active ? "Active" : "Inactive")
                },
                {
                  title: "expiration",
                  field: "expireAt",
                  render: rowData =>
                    rowData.expireAt
                      ? moment(rowData.expireAt).format("YYYY-MM")
                      : "Never"
                }
              ]}
              data={this.props.coupons}
              options={{
                padding: "default",
                pageSize: 10,
                search: true,
                exportButton: true,
                rowStyle: {
                  backgroundColor: "#EEE"
                },
                headerStyle: {
                  backgroundColor: "#01579b",
                  color: "#FFF"
                }
              }}
              title="Promos"
              actions={[
                {
                  icon: "edit",
                  tooltip: "Edit Coupon",
                  onClick: (event, rowData) =>
                    this.props.history.push(
                      `/admin/EditCoupon/${rowData.prefix}`,
                      {
                        Coupon: rowData
                      }
                    )
                }
              ]}
            />
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <h2>Your Role has no Access for this Page!</h2>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    Role: state.auth.Role,
    coupons: state.coupons.Coupons,
    loading: state.coupons.loading
  };
};

const mapDispatchToStore = dispatch => {
  return {
    getAmenities: () => dispatch(actions.getAmenities())
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(PromoCodes);
