import React, { useState } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CardContent from "@material-ui/core/CardContent";
import HistoryCard from "./HistoryCard";

import moment from "moment";

const useStyles = makeStyles({
  stepper: {
    flexWrap: "wrap"
  },
  step: {
    marginBottom: 10,
    textTransform: "uppercase",
    position: "relative",
    display: "flex"
  },
  stepicon: {
    background: "#3f51b5",
    fontSize: 9,
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    padding: 2,
    color: "#fff"
  },
  steplabel: {
    fontSize: 11
  },
  tooltip: {
    position: "absolute",
    bottom: 80,
    zIndex: 2,
    left: "calc(50% - 80px)",
    width: 150
  },
  labelContainer: { position: "relative" }
});

const History = props => {
  const [tooltip, setOpen] = useState({ open: false, id: null });
  const [setOpenLast] = useState(false);
  const handleTolltip = id => {
    setOpen({ ...tooltip, open: !tooltip.open, id: id });
  };

  const handleClickAway = () => {
    setOpenLast(false);
    setOpen({ ...tooltip, open: false, id: null });
  };

  const ToolTip = props => {
    const classes = useStyles();
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes.tooltip}>
          <Card style={{ height: props.log.contentUrl ? "90px" : "80px" }}>
            <CardContent style={{ padding: 0 }}>
              <HistoryCard {...props} />
            </CardContent>
          </Card>
        </div>
      </ClickAwayListener>
    );
  };

  let logs = props.logs;
  logs = logs.sort((a, b) => b.id - a.id);

  const classes = useStyles();

  return (
    <React.Fragment>
      <Stepper id="history" className={classes.stepper} alternativeLabel>
        {logs.map((log, i) => {
          const StepIcon = () => (
            <div
              onClick={() => handleTolltip(log.newPropertyState + i)}
              className={classes.stepicon}
            >
              {moment(log.created).format("D-MMM-YYYY")}
            </div>
          );
          return (
            <Step className={classes.step} key={log.newPropertyState + i}>
              <StepLabel StepIconComponent={StepIcon}>
                <div className={classes.labelContainer}>
                  <div className={classes.steplabel}>
                    {log.newPropertyState}
                  </div>
                  {tooltip.open && tooltip.id === log.newPropertyState + i ? (
                    <ClickAwayListener onClickAway={() => handleClickAway()}>
                      <div className={"toll"}>
                        <ToolTip log={log} index={i} />
                      </div>
                    </ClickAwayListener>
                  ) : null}
                </div>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </React.Fragment>
  );
};

export default History;
