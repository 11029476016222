import React, { Component } from "react";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import moment from "moment";
import axios from "axios";
import { getEnvironment, headers } from "../../config/config";

class HistoryCard extends Component {
  state = {
    ref: React.createRef(),
    fileExtension: null
  };

  findFileType = path => {
    let fp = path.split("");
    let fp2 = [];
    for (let i = 36; i < fp.length; i++) {
      fp2.push(fp[i]);
    }
    let fileType = fp2.join("");
    this.setState({ fileExtension: fileType });
  };

  downloadFile = path => {
    this.findFileType(path);
    axios
      .get(getEnvironment().apiUrl + `/Web/DownloadFile/${path}`, {
        headers: headers(),
        responseType: "arraybuffer"
      })
      .then(response => {
        let blob = new Blob([response.data], {
          "content-type": "application/pdf"
        });
        let ref = this.state.ref;
        ref.current.href = URL.createObjectURL(blob);
        ref.current.download = "file" + this.state.fileExtension;
        ref.current.click();
      })
      .catch(error => console.log(error));
  };
  render() {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            marginTop: 8
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "darkslategray",
              fontWeight: "bold",
              fontSize: 26,
              height: 36
            }}
          >
            {moment(this.props.log.created).format("DD")}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              fontSize: 12,
              textTransform: "capitalize",
              color: "lightslategray"
            }}
          >
            <div>{moment(this.props.log.created).format("dddd")}</div>
            <div>{moment(this.props.log.created).format("MMMM YYYY")}</div>
            {!this.props.log.contentUrl ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: 10,
                  textTransform: "capitalize"
                }}
              >
                <span>
                  {this.props.log.user.firstName} {this.props.log.user.lastName}
                </span>
              </div>
            ) : null}
            <div
              style={{
                fontWeight: "bold",
                color: "darkred",
                fontSize: 16,
                textTransform: "capitalize"
              }}
            >
              <span>{this.props.log.newPropertyState}</span>
            </div>
          </div>
        </div>
        {this.props.log.noteType !== "text" ? (
          <div
            style={{
              textAlign: "left",
              fontSize: 9,
              display: "flex",
              justifyContent: "space-evenly",
              textTransform: "capitalize"
            }}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              {this.props.log.user.firstName} {this.props.log.user.lastName}
            </span>
            <a
              onClick={() => console.log("anchor clicked")}
              style={{ display: "none" }}
              href="empty"
              ref={this.state.ref}
            >
              ref
            </a>
            <CloudDownloadOutlinedIcon
              color="primary"
              onClick={() =>
                this.props.log.isPublic
                  ? window.open(this.props.log.contentUrl, "_blank")
                  : this.downloadFile(this.props.log.contentUrl)
              }
              style={{ cursor: "pointer" }}
            />
          </div>
        ) : null}
      </>
    );
  }
}

export default HistoryCard;
