import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import EnvRadios from "../components/UI/EnvRadios";
import axios from "axios";
import { getEnvironment } from "../config/config";

const styles = theme => ({
  container: {
    padding: 30,
    borderRadius: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  }
});

class ForgotPassword extends Component {
  state = {
    email: "",
    envSelected: false,
    resetPressed: false
  };

  selectEnv = () => {
    this.setState({ envSelected: true });
  };

  resetPassword = () => {
    axios
      .post(getEnvironment().apiUrl + "/Account/ForgotPassword", {
        email: this.state.email,
        callBackUrl: window.location.origin + "/reset-password"
      })
      .then(() => this.setState({ resetPressed: true }))
      .catch(() => console.log(""));
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            height: "100vh",
            background:
              "url(https://images.unsplash.com/photo-1417632993443-302f4897cf67?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80)"
          }}
        >
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              width: 400,
              height: 400
            }}
          >
            <Card style={{ width: "100%", height: "100%" }}>
              <CardContent style={{ height: "100%" }}>
                <div
                  className={classes.container}
                  noValidate
                  autoComplete="off"
                >
                  <Typography align="center" variant="h5">
                    Reset Password
                  </Typography>
                  {this.state.resetPressed ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 120
                      }}
                    >
                      Please check your email!
                    </div>
                  ) : (
                    <>
                      <TextField
                        label="Email"
                        value={this.state.email}
                        name="email"
                        onChange={this.handleChange}
                        margin="normal"
                      />
                      <div style={{ marginTop: 30 }}>
                        <EnvRadios selectEnv={this.selectEnv} />
                      </div>
                      <div
                        style={{
                          marginTop: 40
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.resetPassword}
                          style={{ width: "100%" }}
                          disabled={!this.state.envSelected}
                        >
                          Reset Password
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(ForgotPassword);
