import React from "react";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialTable from "material-table";
import AddIcon from "@material-ui/icons/Add";
import PoiMap from "../../components/Map/PoiMap";
import MapIcon from "@material-ui/icons/Map";
import ListIcon from "@material-ui/icons/List";
class PromoCodes extends React.Component {
  state = {
    data: [],
    loading: true,
    center: {
      lat: 37.45529,
      lng: 25.3672
    },
    map: false,
    isButtonActive: "map"
  };

  componentDidUpdate(prevprops) {}
  componentDidMount() {
    this.setState({ loading: false });
  }
  handleToggle = () => {
    this.setState({ map: !this.state.map });
  };
  render() {
    if (this.state.loading || this.props.loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    if (this.props.Role === "Admin") {
      return (
        <React.Fragment>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                maxWidth: 200
              }}
              onClick={() => this.props.history.push("/admin/addPoi")}
            >
              <AddIcon color="primary" style={{ cursor: "pointer" }} />
              <div className="p">Add A POI</div>
            </div>
            {!this.state.map ? (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  maxWidth: 200
                }}
                onClick={this.handleToggle}
              >
                Switch to Mapview
                <MapIcon />
              </div>
            ) : (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  maxWidth: 200
                }}
                onClick={this.handleToggle}
              >
                Switch to List
                <ListIcon />
              </div>
            )}
          </div>

          <div
            style={{
              padding: 30
            }}
          >
            {this.state.map ? (
              <PoiMap
                centerCoords={this.state.center}
                villas={this.props.pois}
              />
            ) : (
              <MaterialTable
                columns={[
                  {
                    title: "name",
                    field: "name"
                  },
                  {
                    title: "type",
                    field: "type",
                    render: rowData => rowData.attractionType.type
                  },
                  {
                    title: "category",
                    field: "category",
                    render: rowData => rowData.attractionType.category
                  }
                ]}
                data={this.props.pois}
                options={{
                  padding: "default",
                  pageSize: 10,
                  search: true,
                  exportButton: true,
                  rowStyle: {
                    backgroundColor: "#EEE"
                  },
                  headerStyle: {
                    backgroundColor: "#01579b",
                    color: "#FFF"
                  }
                }}
                title="POIS"
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Edit Poi",
                    onClick: (event, rowData) =>
                      this.props.history.push(`/admin/EditPoi/${rowData.id}`, {
                        Poi: rowData
                      })
                  }
                ]}
              />
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <h2>Your Role has no Access for this Page!</h2>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    Role: state.auth.Role,
    pois: state.poi.pois,
    loading: state.poi.loading
  };
};

const mapDispatchToStore = dispatch => {
  return {
    getAmenities: () => dispatch(actions.getAmenities())
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(PromoCodes);
