import Dashboard from "@material-ui/icons/Dashboard";
import AddCircle from "@material-ui/icons/AddCircle";
//
import OwnerDashboard from "./OwnerDashboard";
import AddVilla from "../AddVilla";
import TableList from "../../views/TableList/TableOwner";
import EditVilla from "../EditVilla";
const OwnerRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: Dashboard,
    component: OwnerDashboard,
    layout: "/owner"
  },
  {
    path: "/AddVilla",
    name: "Add Property",
    icon: AddCircle,
    component: AddVilla,
    layout: "/owner"
    // hidden: true,
  },
  {
    path: "/table",
    name: "Property List",
    icon: "content_paste",
    component: TableList,
    layout: "/owner"
  },
  {
    path: "/EditVilla/:id",
    name: "Edit Villa",
    icon: "edit_villa",
    component: EditVilla,
    layout: "/owner",
    hidden: true
  }
];

export default OwnerRoutes;
