import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
// import Step2 fro./Step - AmenitiesFeaturesep2";
import Step1 from "./Step1-Info";
import Step2 from "./Step2-Location";
import Step3 from "./Step3-Boarding";
import Step4 from "./Step4-Multimeda";
import * as actions from "../store/actions/index";
import { connect } from "react-redux";

const styles = theme => ({
  root: {
    width: "90%",
    margin: "auto"
  },
  button: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
});

export class FormUserDetails extends Component {
  state = {
    activeStep: 0,
    villaId: null,
    savePressed: false,
    form: {
      isValid: true,
      ownerName: "",
      nickname: "",
      title: "",
      description: "",
      accommodates: "",
      bedrooms: "",
      beds: "",
      bathrooms: "",
      propertyType: "",
      roomType: "",
      dimensions: "",
      amenities: [],
      includedInPrice: [],
      nearbyAttractions: [],
      layoutAndFeatures: [],
      villaCategories: [],
      attachFinancialBindingModels: [],
      amenitiesNotIncluded: [],
      tags: [],
      pictures: [],
      onBoardingExtraData: {
        lastViewed: "",
        daysOccupied: "",
        averageDailyRate: "",
        expectedReturn: "",
        ownerDemand: "",
        nextMeeting: null,
        notes: "",
        finalNotes: "",
        indoorFeauturesText: "",
        outdoorFeauturesText: "",
        multimedia: null,
        onBoardingLayoutsAndFeatures: []
      },
      onBoardingMoreExtras: this.props.onBoardingMoreExtras,
      address: {
        placeId: "",
        full: "",
        street: "",
        areaId: this.props.selectedArea ? this.props.selectedArea.id : 1,
        latlng: {
          lat: "",
          lng: ""
        },
        zoneId: "",
        zone: ""
      },
      extraLoaded: false
    }
  };
  continue = e => {
    e.preventDefault();
    // this.props.nextStep();
  };

  getSteps = () => {
    return [
      <span
        onClick={() => this.setState({ activeStep: 0 })}
        style={{ cursor: "pointer" }}
      >
        Information
      </span>,
      <span
        // onClick={() => this.setState({ activeStep: 1 })}
        style={{ cursor: "pointer" }}
      >
        Location
      </span>,
      <span
        // onClick={() => this.setState({ activeStep: 2 })}
        style={{ cursor: "pointer" }}
      >
        On Boarding
      </span>,
      <span
        // onClick={() => this.setState({ activeStep: 3 })}
        style={{ cursor: "pointer" }}
      >
        Multimedia
      </span>
    ];
  };

  componentDidMount() {
    this.addOnBoardingMoreExtras();
  }

  componentDidUpdate(pevprops) {
    if (pevprops.selectedArea !== this.props.selectedArea) {
      let adress = { ...this.state.adress };
      adress.areaId = this.props.selectedArea.id;
      this.setState(state => ({
        ...state,
        form: {
          ...state.form,
          address: {
            ...state.form.address,
            areaId: this.props.selectedArea.id,
            zone: {}
          }
        }
      }));
    }

    if (pevprops.onBoardingMoreExtras !== this.props.onBoardingMoreExtras) {
      this.addOnBoardingMoreExtras();
    }
  }
  pageScrollTop = () => {
    document.body.scrollTop = 0;
  };
  addOnBoardingMoreExtras = () => {
    this.setState(
      state => ({
        ...state,
        form: {
          ...state.form,
          onBoardingMoreExtras: this.props.onBoardingMoreExtras,
          onBoardingExtraData: {
            ...state.form.onBoardingExtraData,
            onBoardingLayoutsAndFeatures: this.props
              .onBoardingMoreExtrasOptimized
          }
        },
        extraLoaded: true
      }),
      () => {
        if (this.state.extraLoaded) {
          this.addForm(this.state.form);
        }
      }
    );
  };

  addForm = form => {
    this.setState({ form });
  };

  addamenity = list => {
    this.setState({ list });
  };

  adinclussion = inclusionlist => {
    this.setState({ inclusionlist });
  };

  getStepContent = step => {
    switch (step) {
      case 0:
        //console.log("1step", this.state.form);
        return <Step1 form={this.state.form} addForm={this.addForm} />;
      case 1:
        return (
          <React.Fragment>
            <Step2
              form={this.state.form}
              addForm={this.addForm}
              amenities={this.state.list}
              inclusions={this.state.inclusionlist}
              selectedArea={this.props.selectedArea}
            />
          </React.Fragment>
          // <React.Fragment>
          //   <Step2
          //     title="Amenities"
          //     categories={this.props.AmenitiesCategories}
          //     data={this.props.Amenities}
          //     form={this.state.form}
          //     addForm={this.addForm}
          //     addamenity={this.addamenity}
          //   />
          //   <Step2
          //     title="Included In the Price"
          //     categories={["Included in the Price"]}
          //     data={this.props.includes}
          //     inclusions
          //     form={this.state.form}
          //     addForm={this.addForm}
          //     adinclussion={this.adinclussion}
          //   />

          //   <Step2
          //     title="Nearby Attractions"
          //     //categories={["Nearby Attractions"]}
          //   />

          //   <Step2 title="Layout and Features" />
          // </React.Fragment>
        );
      case 2:
        return (
          <Step3
            form={this.state.form}
            addForm={this.addForm}
            villaId={this.state.villaId}
          />
        );
      case 3:
        return (
          <React.Fragment>
            <Step4
              form={this.state.form}
              addForm={this.addForm}
              villaId={this.state.villaId}
              addVilla
            />
          </React.Fragment>
        );
      default:
        return "Unknown step";
    }
  };

  validateFields = () => {
    if (
      this.state.form.ownerName === "" ||
      this.state.form.title === "" ||
      this.state.form.bathrooms === "" ||
      this.state.form.roomType === "" ||
      this.state.form.nickname === "" ||
      this.state.form.isValid === false
    ) {
      this.props.openAlert("", "Please fill in all the required fields");
    } else {
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));
    }
  };

  handleNext = () => {
    const { activeStep } = this.state;
    this.pageScrollTop();

    if (activeStep === 0) {
      this.validateFields();
    }
    if (activeStep === 1) {
      if (
        this.state.form.address.latlng.lat === "" ||
        this.state.form.address.latlng.lng === ""
      ) {
        this.props.openAlert("", "Please select position on map");
        this.setState({ savePressed: false });
      } else {
        if (this.state.savePressed) {
          this.props.AddVilla(this.state.form).then(res => {
            if (!res.propertyState) {
              this.props.openAlert("", "Cannot add property");
            } else {
              this.props.updateStatus(res.id, "prospect");
              this.props.getVillaDetails(res.id);
              this.props.getVillas();
              setTimeout(() => {
                this.setState({ savePressed: false }, () => {
                  this.props.history.push("/admin/table");
                });
              }, 1000);
            }
          });
        } else {
          this.setState({ activeStep: this.state.activeStep + 1 });
        }
      }

      // else {
      //   this.props.AddVilla(this.state.form).then(res => {
      //     if (!res.propertyState) {
      //       this.props.openAlert("", "Cannot add property");
      //     } else {
      //       this.setState(state => ({
      //         activeStep: state.activeStep + 1,
      //         villaId: res ? res.id : null
      //       }));
      //       this.props.updateStatus(res.id, "prospect");
      //       this.props.getVillaDetails(res.id);
      //       setTimeout(() => {
      //         this.props.getVillas();
      //       }, 2000);
      //     }
      //   });
      // }
    }
    if (activeStep === 2) {
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));
    }
    if (activeStep === 3) {
      this.props.AddVilla(this.state.form).then(res => {
        if (!res.propertyState) {
          this.props.openAlert("", "Cannot add property");
        } else {
          this.props.updateStatus(res.id, "viewed");
          this.props.getVillaDetails(res.id);
          this.props.getVillas();
          setTimeout(() => {
            this.setState({ savePressed: false }, () => {
              this.props.history.push("/admin/table");
            });
          }, 1000);
        }
      });
    }
  };

  handleBack = () => {
    this.pageScrollTop();

    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleChange = name => event => {
    this.setState({ errors: false, [name]: event.target.value });
  };

  render() {
    const steps = this.getSteps();
    const { activeStep } = this.state;
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {this.state.activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>
                  All steps completed
                </Typography>
              </div>
            ) : (
              <div>
                {this.state.extraLoaded
                  ? this.getStepContent(activeStep)
                  : null}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 40,
                    marginBottom: 40
                  }}
                >
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    className={classes.backButton}
                    style={{ marginRight: 30 }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                  {activeStep === 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: 30 }}
                      onClick={() => {
                        this.setState({ savePressed: true }, () =>
                          this.handleNext()
                        );
                      }}
                    >
                      {this.state.savePressed ? (
                        <CircularProgress style={{ color: "#fff" }} size={24} />
                      ) : (
                        "Save & Exit"
                      )}
                    </Button>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    Amenities: state.settings.Amenities,
    AmenitiesCategories: state.settings.AmenitiesCategories,
    includes: state.settings.IncludedinPrice,
    selectedArea: state.area.selected,
    onBoardingMoreExtras: state.villas.onBoardingMoreExtras,
    onBoardingMoreExtrasOptimized: state.villas.onBoardingMoreExtrasOptimized,
    Role: state.auth.Role
  };
};

const mapDispatchToStore = dispatch => {
  return {
    openAlert: (title, text) => dispatch(actions.openAlert(title, text)),
    getAmenities: () => dispatch(actions.getAmenities()),
    AddVilla: form => dispatch(actions.AddVilla(form)),
    getVillas: () => dispatch(actions.getVillas()),
    editVilla: (form, id) => dispatch(actions.editVilla(form, id)),
    updateStatus: (id, newStatus, amount, noteType, noteText, contentUrl) =>
      dispatch(
        actions.updateVillaStatus(
          id,
          newStatus,
          amount,
          noteType,
          noteText,
          contentUrl
        )
      ),
    getVillaDetails: id => dispatch(actions.getVillaDetails(id))
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToStore)(FormUserDetails)
);
