import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import HomeIcon from "@material-ui/icons/Home";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import POIMAP from "../../layouts/POIMAP";

class EditPromo extends Component {
  state = {
    id: "",
    areaId: this.props.areaId ? this.props.areaId : 1,
    poi: "",
    lat: "",
    lng: "",
    type: "",
    promoAdded: false,
    name: "",
    form: {
      address: {
        placeId: "",
        full: "",
        street: "",
        areaId: this.props.selectedArea ? this.props.selectedArea.id : 1,
        latlng: {
          lat: "",
          lng: ""
        },
        zoneId: "",
        zone: ""
      },
      extraLoaded: false
    }
  };
  componentDidMount() {
    if (this.props.history.location.state.Poi) {
      const Poi = this.props.history.location.state.Poi;
      this.setState(prevState => ({
        form: {
          ...prevState.form,
          address: Poi.address
        },
        id: Poi.id,
        type: Poi.attractionType.type,
        selectedtype: Poi.attractionType.id,
        lat: Poi.address.latlng.lat,
        lng: Poi.address.latlng.lng,
        poi: Poi.name,
        loading: false
      }));
    }
  }

  handleChanges = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  checkValidation = () => {
    this.setState({ areaId: this.props.areaId });
    this.props
      .addPoi(
        this.state.poi,
        this.state.selectedtype,
        this.state.areaId,
        this.state.form.address.latlng.lat,
        this.state.form.address.latlng.lng,
        this.state.id
      )
      .then(res => {
        this.props.getPois(this.props.areaId);
        this.setState(
          res.id ? { promoAdded: true } : { promoAdded: false },
          () =>
            !this.state.promoAdded
              ? this.props.openAlert("", "Error in adding POI")
              : null
        );
      });
  };
  handleOnChangePropertyState = e => {
    this.setState({ selectedtype: e.target.value });
  };
  addForm = form => {
    this.setState({ form });
  };

  render() {
    if (this.state.loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 150
          }}
        >
          <CircularProgress disableShrink />
        </div>
      );
    }
    return !this.state.promoAdded ? (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          }}
        >
          <>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                flex: 1,
                justifyContent: "space-between"
              }}
            >
              <TextField
                placeholder="Name of POI"
                label="name of poi"
                onChange={this.handleChanges}
                value={this.state.poi}
                name={"poi"}
                margin="normal"
                required
                variant="outlined"
                style={{ width: "50%", backgroundColor: "white" }}
              />
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "30px",
                  width: 300
                }}
              >
                Type
                <Select
                  labelid="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={this.state.selectedtype}
                  onChange={this.handleOnChangePropertyState}
                  style={{ marginLeft: 15 }}
                >
                  {this.props.poitypes.map(poi => (
                    <MenuItem key={poi.id} value={poi.id}>
                      {poi.type}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          </>
          <POIMAP
            form={this.state.form}
            addForm={this.addForm}
            address={this.state.form.address}
          />

          <div
            style={{
              marginTop: 50
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={this.checkValidation}
            >
              Save POI
            </Button>
          </div>
        </div>
      </>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 300,
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <CheckIcon color="primary" />
          <div style={{ marginLeft: 10, marginBottom: 20 }}>
            poi saved successfully
          </div>
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={e => {
              e.preventDefault();
              this.props.history.push("/admin/pois");
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div>Go back </div>
              <div
                style={{
                  paddingTop: 2,
                  marginLeft: 5
                }}
              >
                <HomeIcon />
              </div>
            </div>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    areaId: state.area.selected.id,
    poitypes: state.poi.types
  };
};

const mapDispatchToStore = dispatch => {
  return {
    getRoles: () => dispatch(actions.getRoles()),
    addPoi: (name, type, id, lat, lng, poiId) =>
      dispatch(actions.addPoi(name, type, id, lat, lng, poiId)),
    getPois: id => dispatch(actions.getPois(id)),
    openAlert: (title, text) => dispatch(actions.openAlert(title, text))
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(EditPromo);
