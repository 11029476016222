import React, { Component } from "react";
import MaterialTable from "material-table";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import moment from "moment";
import * as actions from "../../store/actions";
import DateFnsUtils from "@date-io/date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
class PriceLogs extends Component {
  state = {
    open: false,
    open2: false,
    open3: false,
    duration: 7,
    center: {
      lat: 37.45529,
      lng: 25.3672
    },
    bot: false,
    isButtonActive: "map",
    modalOpen: false,
    season: "SUMMER",
    destination: "Mykonos",
    columns: [
      {
        title: "period",
        field: "checkInckeckOut"
      },
      {
        title: "Area",
        field: "area",
        defaultGroupOrder: 1
      },
      {
        title: "Destination",
        field: "destination"
      },

      {
        title: "Pearls/Rest",
        field: "count",
        render: rowData => (
          <p>
            {rowData.countPearls + "/"}
            {rowData.count}
          </p>
        )
      },

      {
        title: "bedrooms",
        field: "bedrooms"
      },
      {
        title: "avg €",
        field: "avgPrice",
        render: rowData => <p>{rowData.avgPrice.toFixed(0)}</p>
      },
      {
        title: "avg Pearls €",
        field: "avgPearlsPrice",
        render: rowData => <p>{rowData.avgPearlsPrice.toFixed(0)}</p>
      },
      {
        title: "max €",
        field: "maxPrice",
        render: rowData => <p>{rowData.maxPrice.toFixed(0)}</p>
      },
      {
        title: "max Pearls €",
        field: "maxPearlsPrice",
        render: rowData => <p>{rowData.maxPearlsPrice.toFixed(0)}</p>
      },
      {
        title: "min €",
        field: "minPrice",
        render: rowData => <p>{rowData.minPrice.toFixed(0)}</p>
      },
      {
        title: "min Pearls €",
        field: "minPearlsPrice",
        render: rowData => <p>{rowData.minPearlsPrice.toFixed(0)}</p>
      }
    ]
  };

  handleDateChangeFrom = date => {
    this.setState({ selectedDateFrom: date });
  };
  handleDateChangeTo = date => {
    this.setState({ selectedDateTo: date });
  };

  handletoggleBot = () => {
    this.setState({ bot: !this.state.bot });
  };
  handleGetData = () => {
    let newSeason = this.state.season === "All" ? "" : this.state.season;
    let durationSelect =
      this.state.duration === "All" ? "" : this.state.duration;

    this.props.getPriceLogs(
      moment(this.state.selectedDateFrom).format("YYYY-MM-D"),
      moment(this.state.selectedDateTo).format("YYYY-MM-D"),
      "",
      this.state.destination,
      newSeason,
      durationSelect
    );
  };

  handletoggle = () => {
    this.setState({ open: !this.state.open });
  };
  handlechangeDestination = e => {
    this.setState({ destination: e.target.value });
  };
  handletoggle2 = () => {
    this.setState({ open2: !this.state.open2 });
  };
  handlechangeSeason = e => {
    this.setState({ season: e.target.value });
  };
  handletoggle3 = () => {
    this.setState({ open3: !this.state.open3 });
  };
  handlechangeDuration = e => {
    this.setState({ duration: e.target.value });
  };
  handlechangeDestinationBot = e => {
    this.setState({ destinationBot: e.target.value });
  };
  componentDidMount() {
    //this.setState({ loading: false });
  }

  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  render() {
    if (this.props.loading) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1
          }}
        >
          <CircularProgress style={{ color: "hotpink", marginTop: 200 }} />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center"
          }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk={true}
              margin="normal"
              label="Start date"
              format="MM/dd/yyyy"
              value={this.state.selectedDateFrom}
              onChange={this.handleDateChangeFrom}
            />
            <DatePicker
              autoOk={true}
              margin="normal"
              label="End date"
              format="MM/dd/yyyy"
              value={this.state.selectedDateTo}
              onChange={this.handleDateChangeTo}
            />

            <FormControl style={{ marginTop: 10 }}>
              <InputLabel id="demo-controlled-open-select-label">
                Destination
              </InputLabel>
              <Select
                id="demo-controlled-open-select"
                open={this.state.open}
                onClose={this.handletoggle}
                onOpen={this.handletoggle}
                value={this.state.destination}
                onChange={this.handlechangeDestination}
              >
                <MenuItem value={"Mykonos"}>Mykonos</MenuItem>
                <MenuItem value={"St.Tropez"}>St.Tropez</MenuItem>
                <MenuItem value={"Ibiza"}>Ibiza</MenuItem>
                <MenuItem value={"Marbella"}>Marbella</MenuItem>
                <MenuItem value={"Bali"}>Bali</MenuItem>
                <MenuItem value={"Tulum"}>Tulum</MenuItem>
                <MenuItem value={"SaintBarthelemy"}>SaintBarthelemy</MenuItem>
                <MenuItem value={"Megeve"}>Megeve</MenuItem>
                <MenuItem value={"Courchevel"}>Courchevel</MenuItem>
                <MenuItem value={"Gstaad"}>Gstaad</MenuItem>
                <MenuItem value={"StMoritz"}>StMoritz</MenuItem>
                <MenuItem value={"ValDisere"}>ValDisere</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ marginTop: 10 }}>
              <InputLabel id="demo-controlled-open-select-label">
                Season
              </InputLabel>
              <Select
                id="demo-controlled-open-select"
                open={this.state.open2}
                onClose={this.handletoggle2}
                onOpen={this.handletoggle2}
                value={this.state.season}
                onChange={this.handlechangeSeason}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"WINTER"}>WINTER</MenuItem>
                <MenuItem value={"SPRING"}>SPRING</MenuItem>
                <MenuItem value={"SUMMER"}>SUMMER</MenuItem>
                <MenuItem value={"AUTUMN"}>AUTUMN</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ marginTop: 10 }}>
              <InputLabel id="demo-controlled-open-select-label">
                Duration
              </InputLabel>
              <Select
                id="demo-controlled-open-select"
                open={this.state.open3}
                onClose={this.handletoggle3}
                onOpen={this.handletoggle3}
                value={this.state.duration}
                onChange={this.handlechangeDuration}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={3}>3 days</MenuItem>
                <MenuItem value={5}>5 days</MenuItem>
                <MenuItem value={7}>7 days</MenuItem>
              </Select>
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              href="#contained-buttons"
              onClick={this.handleGetData}
              style={{ width: 80, height: 35, marginTop: 22 }}
            >
              Submit
            </Button>
          </MuiPickersUtilsProvider>
        </div>
        <MaterialTable
          title={"Select Filters and Click Submit"}
          data={this.props.logs}
          //   actions={[
          //     rowData => ({
          //       icon: "list",
          //       tooltip: "Show List",
          //       iconProps: { color: "secondary" },
          //       onClick: (event, rowData) => console.log(rowData)
          //     })
          //   ]}
          columns={this.state.columns}
          //  parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
          options={{
            pageSize: 20,
            grouping: true,
            pageSizeOptions: [5, 10, 20, 40],
            exportButton: true,
            rowStyle: {
              backgroundColor: "#EEE"
            }

            // exportCsv: (columns, data) => {
            //   // console.log(columns, "cols");
            //   console.log(data, "data");
            // }
          }}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => {
  // console.log(state.settings);
  return {
    logs: state.settings.priceLogs,
    loading: state.settings.loading
  };
};

const mapDispatchToStore = dispatch => {
  return {
    getPriceLogs: (from, to, area, destination, season, duration) =>
      dispatch(
        actions.getPriceLogs(from, to, area, destination, season, duration)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToStore)(PriceLogs);
